import { Box, Button, IconButton } from "@mui/material";
import { useState } from "react";
import LawModal from "../../../ui/LawModal";
import LawInput from "../../../ui/LawInput";
import BoxCenter from "../../../ui/BoxCenter";
import { Add } from "@mui/icons-material";
import { wgfIsNotNull } from "../../../functions/lawFunctions";
import { lawFetchPost } from "../../../functions/lawFetch";
import LawSelectUsersOrRoles from "../share/LawSelectUsersOrRoles";
import { appObjects } from "../../../../App";
import LawIconButton from "../../../ui/LawIconButton";

const TicketCreate = ({ callback }) => {
    const [open, setOpen] = useState(false);
    const [ticket, setTicket] = useState({});
    const [selectedUsers, setSelectedUsers] = useState([]);
    const create = () => {
        if(selectedUsers && selectedUsers.length>0){
            ticket['targetUsers']=selectedUsers.map(item=>item.id).join(',');
            if (wgfIsNotNull([ticket['name'],ticket['targetUsers']], true) === true) {
                lawFetchPost('ticket/create', ticket, null, null, null, () => {
                    setSelectedUsers([]);
                    setTicket({});
                    setOpen(false);
                    if (callback) {
                        callback();
                    }
                }, null, true, null)
            }
        }
        else{
            appObjects.snackbar('Selecteer gebruiker','info');
        }
    }
    const handleUserUnSelect = (id) => {
        setSelectedUsers(selectedUsers.filter(f => f.id !== id));
    }
    return (
        <>
            <LawIconButton onClick={() => setOpen(true)}><Add /></LawIconButton>
            {
                (open) && (
                    <LawModal state={open} setState={setOpen}>
                        <LawInput label="Title*" defaultValue={ticket['name']} propstate={[ticket, setTicket, 'name']} />
                        <LawInput label="Comment" defaultValue={ticket['comment']} propstate={[ticket, setTicket, 'comment']} />
                        {
                        (selectedUsers && selectedUsers.length > 0) && (
                            <Box className="law-message-selected-users">
                                {
                                    selectedUsers.map((item, index) => {
                                        return <Button key={index} onClick={() => handleUserUnSelect(item.id)} variant="outlined" color="error">{item.name}</Button>
                                    })
                                }
                            </Box>
                        )
                    }
                        <LawSelectUsersOrRoles isAddUser={true} selectedIdList={selectedUsers} setResponse={setSelectedUsers} />
                        <BoxCenter>
                            <div>
                                <Button onClick={create} variant="contained" sx={{ width: '100%', mb: 2, mt: 5 }}>Create</Button>
                            </div>
                        </BoxCenter>
                    </LawModal>
                )
            }
        </>
    )
}
export default TicketCreate;