import { AdminPanelSettings, Calculate, Gavel, Headset, HeadsetMic, Person, SupportAgent } from "@mui/icons-material";
export const lawRoles = {
    admin: 'Admin',
    customer: 'Customer',
    superAdmin: 'SuperAdmin',
    lawyer: 'Lawyer',
    lawyerAssistant: 'LawyerAssistant',
    accountant: 'Accountant',
    accountantAssistant: 'AccountantAssistant'
}
export const WgControlsStyle = {
    marginBottom: '10px',
    width: '100%'
}

export const lawLanguages = [{ value: 'nl', text: 'Dutch' }, { value: 'en', text: 'English' }];
export const lawStatusActivePassive = [{ value: 1, text: 'Actief' }, { value: 0, text: 'Passief' }];

export const lawGenders = [{ value: 1, text: 'Man' }, { value: 2, text: 'Vrouw' }];
export const wgGenders = [
    { value: 1, text: 'Man' },
    { value: 2, text: 'Woman' }
]
export const lawTableNames = {
    bank: 'TblBanks',
    country: 'TblCountries',
    currencyCode: 'TblCurrencyCodes',
    period: 'TblPeriods',
    relationShipGroups: 'TblRelationShipGroups',
    accountStatus: 'TblAccountStatuses',
    reportingTaxonomy: 'TblReportingTaxonomy',
    sidebarItems: 'TblSidebarItems'
}

//Create olan StepType sadece backend kısmından dinamik eklenecek
export const lawTicketStepTypes = [{ value: 2, text: 'comment' }, { value: 100, text: 'end' }];
export const lawCountries = [{ value: 'nl', text: 'Netherlands' }, { value: 'be', text: 'Belgium' }];

export const wgYesNo = [
    { value: 1, text: 'Ja' },
    { value: 0, text: 'Nee' }
]

export const wgWorkTypes = [
    { value: 1, text: 'Full time' },
    { value: 2, text: 'Part time' }
]

export const wgVatTypes = [
    { value: 21, text: '21%' },
    { value: 9, text: '9%' },
    { value: 0, text: '0%' },
]
/**
 * Debit = Sell / Satis
 * Credit = Buy / Satinalma
 */
export const wgInvoiceTypes = [
    { value: 1, text: 'Debit' },
    { value: 2, text: 'Credit' }
]

/*İndirim orani turleri */
export const wgfDiscountTypes = [
    { value: 1, text: '%' },
    { value: 2, text: '€' }
]
export const wgPortalNames = {
    admin: 'Admin',
    accountant: 'Accountant',
    lawyer: 'Lawyer',
    customer: 'Customer',
    agent:'Agent',
    backOffice:'Backoffice'
}

export const wgPortalTypes = [
    {
        icon: <AdminPanelSettings />,
        text: 'Admin Portal',
        value: wgPortalNames.admin,
        showChangePortal: true,
        forRoles:[lawRoles.admin,lawRoles.superAdmin]
    },
    {
        icon: <Calculate />,
        text: 'Accountant',
        value: wgPortalNames.accountant,
        showChangePortal: true,
        forRoles:[lawRoles.admin,lawRoles.superAdmin,lawRoles.accountant]
    },
    {
        icon: <Gavel />,
        text: 'Lawyer',
        value: wgPortalNames.lawyer,
        showChangePortal: true,
        forRoles:[lawRoles.admin,lawRoles.superAdmin]
    },
    {
        icon: <SupportAgent />,
        text: 'Agent',
        value: wgPortalNames.agent,
        showChangePortal: true,
        forRoles:[lawRoles.admin,lawRoles.superAdmin,lawRoles.agent]
    },
    {
        icon: <Headset />,
        text: 'Backoffice',
        value: wgPortalNames.backOffice,
        showChangePortal: true,
        forRoles:[lawRoles.admin,lawRoles.superAdmin,lawRoles.backOffice]
    },
    {
        icon: <Person />,
        text: 'Customer',
        value: wgPortalNames.customer,
        showChangePortal: false,
        forRoles:[lawRoles.customer]
    }
]

export const wgSnackbarTypes = {
    default: 'default',
    error: 'error',
    success: 'success',
    warning: 'warning',
    info: 'info'
}
export const wgStaticInputTypes = {
    text: 'text',
    number: 'number',
    tel: 'tel',
    search: 'search',
    email: 'email',
    url: 'url',
    date: 'date',
    datetime: 'datetime'
}


export const lawRolesList = () => {
    let _roles = [];
    for (var prop in lawRoles) {
        if (!lawRoles.hasOwnProperty(prop)) continue;
        _roles.push({ value: lawRoles[prop], text: lawRoles[prop], selected: false, show: true });
    }
    return _roles;
}
export const lawRolesListWithLines = () => {
    let _roles = [];
    for (var prop in lawRoles) {
        if (!lawRoles.hasOwnProperty(prop)) continue;
        _roles.push({ value: `-${lawRoles[prop]}-`, text: lawRoles[prop], selected: false, show: true });
    }
    return _roles;
}



