import { useState } from "react"
import Cropper from "react-easy-crop"
import { wgfChangeState, wgfFileUrlToSrc } from "../functions/lawFunctions"
import { IconButton, Slider, Stack } from "@mui/material"
import { Check, RotateRight, ZoomIn } from "@mui/icons-material"
import LawIconButton from "./LawIconButton"

const LawImageCrop2 = ({ state, setState, fieldName, aspect, isCircle,croppedCallback }) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [rotate, setRotate] = useState(0)
    const [croppedImage, setCroppedImage] = useState(null);

    const saveCroppedImage = () => {
        if (croppedImage) {
            wgfChangeState(croppedImage, fieldName, state, setState);
        }
        if(croppedCallback){
            croppedCallback();
        }
    }
    const handleZoomToogle = () => {
        setZoom(zoom == 1 ? 10 : 1);
    }
    const handleRotateToogle = () => {
        setRotate((rotate + 90) < 360 ? (rotate + 90) : 0);
    }
    const createImage = (url) => {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.onload = () => resolve(image);
            image.onerror = (error) => reject(error);
            image.src = url;
        });
    };

    const getCroppedImage = async (croppedArea, croppedAreaPixels) => {
        let imageSrc = wgfFileUrlToSrc(state ? (fieldName ? state[fieldName] : state) : '')
        if (!croppedAreaPixels || !imageSrc) return;

        const image = await createImage(imageSrc);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = croppedAreaPixels.width;
        canvas.height = croppedAreaPixels.height;
        ctx.drawImage(
            image,
            croppedAreaPixels.x,
            croppedAreaPixels.y,
            croppedAreaPixels.width,
            croppedAreaPixels.height,
            0,
            0,
            croppedAreaPixels.width,
            croppedAreaPixels.height
        );

        setCroppedImage(canvas.toDataURL("image/jpeg"));
    };


    return (

        <div style={{ width: '100%', background: '#999', minHeight: '50vh', maxHeight: '100vh', overflow: 'auto' }}>
            <div className="d-flex" style={{ width: 'fit-content', zIndex: '9999', background: 'rgba(0,0,0,0.5)', borderRadius: '50px', padding: '1px 20px', position: 'absolute', left: 0, right: 0, margin: 'auto', bottom: '20px' }}>
                <Stack spacing={2} direction="row" sx={{ alignItems: 'center', mr: 2 }}>
                    <LawIconButton onClick={handleZoomToogle} className="bg-transparent">
                        <ZoomIn sx={{ color: '#fff' }} />
                    </LawIconButton>
                    <Slider
                        value={zoom}
                        min={1}
                        sx={{ width: '50px', color: '#fff' }}
                        size="small"
                        max={10}
                        step={1}
                        onChange={(e, zoom) => setZoom(zoom)}
                    />
                </Stack>
                <Stack spacing={2} direction="row" sx={{ alignItems: 'center', mr: 2 }}>
                    <LawIconButton onClick={handleRotateToogle} className="bg-transparent">
                        <RotateRight sx={{ color: '#fff' }} />
                    </LawIconButton>
                    <Slider
                        value={rotate}
                        min={0}
                        sx={{ width: '50px', color: '#fff' }}
                        size="small"
                        max={360}
                        step={1}
                        onChange={(e, zoom) => setRotate(zoom)}
                    />
                </Stack>
                <LawIconButton onClick={saveCroppedImage} className="bg-transparent">
                    <Check sx={{ color: '#fff' }} />
                </LawIconButton>
            </div>
            <div style={{ width: '100%', minHeight: '50vh', margin: 'auto', position: 'relative' }}>
                <Cropper
                    image={wgfFileUrlToSrc(state ? (fieldName ? state[fieldName] : state) : '')}
                    crop={crop}
                    zoom={zoom}
                    minZoom={1}
                    maxZoom={10}
                    zoomWithScroll={true}
                    cropShape={isCircle ? 'round' : 'rect'}
                    rotation={rotate}
                    aspect={(aspect ?? 4 / 3)}
                    onCropChange={setCrop}
                    onCropComplete={getCroppedImage}
                    onZoomChange={setZoom}
                />
                <img src={croppedImage} alt="Cropped" style={{ maxWidth: "100%", zIndex: '9998', position: 'absolute', right: '10px', bottom: '20px', borderRadius: '5px', width: '70px', height: 'auto' }} />
            </div>

        </div>
    )
}
export default LawImageCrop2;