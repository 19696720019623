import { useEffect, useState } from "react";
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';
import { wgfChangeState, wgfDateTimeFormat } from "../functions/lawFunctions";
import { AccessTime } from "@mui/icons-material";
import { Button } from "@mui/material";
import LawModal from "./LawModal";
const _compValues = {
    time: '',
    default: wgfDateTimeFormat(new Date(), 'HH:mm')
}
const LawTimePicker = ({ time, setTime, fieldName, label }) => {
    const [show, setShow] = useState(false);
    const [picketTime, setPickerTime] = useState((fieldName ? time[fieldName] : time) ?? wgfDateTimeFormat(new Date(), 'HH:mm'));
    const changeOk = (e) => {
        setShow(false);
        let selectTime = wgfDateTimeFormat(new Date(e), 'HH:mm');
        if (setTime) {
            if (fieldName) {
                wgfChangeState(selectTime, fieldName, time, setTime);
            }
            else {
                setTime(selectTime);
            }
        }
    }
    useEffect(() => {
        if (time) {
            setPickerTime((fieldName ? time[fieldName] : time));
        }
    }, [time])
    _compValues.default = (wgfDateTimeFormat(new Date(), 'yyyy-dd-mm')) + ' ' + picketTime;
    return (
        <>
            <div className="mb-3">
                {
                    (label) ? (
                        <div className="d-flex align-items-center mb-3"><span>{label}:</span>
                            <Button variant="outlined" size="small" onClick={() => setShow(!show)}>
                                <AccessTime size="small" /> <span className="ms-1">{picketTime}</span>
                            </Button>
                        </div>
                    ) : (
                        <Button variant="outlined" size="small" onClick={() => setShow(!show)}>
                            <AccessTime size="small" /> <span className="ms-1">{picketTime}</span>
                        </Button>
                    )
                }
            </div>
            {
                (show) && (
                    <LawModal header={'Selecteer een tijd'} state={show} setState={setShow} style={{ maxWidth: '400px',width:'96%' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            {/* TIME ZONES >>>   https://timezonedb.com/time-zones */}
                            <StaticTimePicker autoFocus={true} onAccept={changeOk} ampm={false} defaultValue={dayjs(_compValues.default)} />
                        </LocalizationProvider>
                    </LawModal>
                )
            }
        </>
    )
}
export default LawTimePicker;