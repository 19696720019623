import { useEffect, useState } from "react";
import { lawFetchGet } from "../../../functions/lawFetch";
import { ChevronRight } from "@mui/icons-material";
import { wdfDynamicImportUrlParameter, wgfDateTimeFormat } from "../../../functions/lawFunctions";
import LawDataTable from "../../../ui/LawDataTable";
import LawStatus from "../../../ui/LawStatus";
import LawIconButton from "../../../ui/LawIconButton";
import OpenInvoiceShow from "./OpenInvoiceShow";

const OpenInvoicesTable = ({ customerId, companyId }) => {
    const [invoices, setInvoices] = useState([]);
    const getAll = () => {
        let url = wdfDynamicImportUrlParameter(
            'CustomerPostInvoices/all',
            [
                { default: customerId, key: 'customerId' },
                { default: companyId, key: 'companyId' }
            ]);
        lawFetchGet(url, null, setInvoices, [], null, null, false, null)
    };
    useEffect(getAll, [])
    let columns = [
        {
            name: 'Id',
            selector: (row) => row.id,
            sortable: true,
        },
        {
            name: 'Priority',
            selector: (row) => row.priority,
            sortable: true,
        },
        {
            name: 'Customer Name',
            selector: (row) => row.customerName,
            sortable: true,
        },
        {
            name: 'Company Name',
            selector: (row) => row.companyName,
            sortable: true,
        },
        {
            name: 'Create',
            selector: (row) => wgfDateTimeFormat(row.createDate, 'dd.MM.yyyy HH:mm'),
            sortable: true,
        },
        {
            name: 'Status',
            cell: (row) => (
                <div>
                    <LawStatus status={row.status} statusGroup={"CustomerPostInvoiceStatus"} />
                    <small className="d-block w-100 text-muted ps-3">{row.checkedByUserName}</small>
                </div>
            ),
            sortable: true,
        },
        {
            name: 'Action',
            width: '100px',
            cell: (row) => (
                <div className="law-dttb-actions">
                    {
                        (row.status == 100) ? (
                            <LawIconButton onClick={() => { alert('open invoice') }}>
                                <ChevronRight />
                            </LawIconButton>
                        ) : (
                          <OpenInvoiceShow createCallback={getAll} id={row.id}/>
                        )
                    }
                </div>
            )
        }
    ];
    return (
        <>
            <LawDataTable isAddCheckbox={true} dataState={invoices} setDataState={setInvoices} filterController={'CustomerPostInvoices'} columns={columns} searchFields={['id', 'customerName', 'companyName']} />
        </>
    )
}
export default OpenInvoicesTable;