import { IconButton } from "@mui/material"
import { useEffect, useState } from "react";
import { lawFetchGet, lawFetchPost } from "../../functions/lawFetch";
import LawInput from "../../ui/LawInput";
import { lawStatusActivePassive } from "../../lawConstants";
import LawSelect from "../../ui/LawSelect";
import LawCitiesSelect from "../../ui/LawCitiesSelect";
import UserBaseElements from "../admin/users/UserBaseElements";
import LawUploadImage from "../../ui/LawUploadImage";
import LawFormGrid from "../../ui/LawFormGrid";
import { Save } from "@mui/icons-material";
import LawIconButton from "../../ui/LawIconButton";

const CustomerEdit = ({ id, callback }) => {
    const [customer, setCustomer] = useState({ id: id });
    useEffect(() => {
        lawFetchGet('customer/get?customerId=' + id, null, setCustomer, {}, null, null, false, null);
    }, [])
    const updateCustomer = () => {
        lawFetchPost('customer/update', customer, null, null, null, () => {
            if (callback) {
                callback();
            }
        }, null, true, null);
    }
    return (
        <>
            <LawIconButton onClick={updateCustomer} title={'Opslaan'}>
                <Save />
            </LawIconButton>
            <div className="mb-3">
                <LawUploadImage state={customer} setState={setCustomer} fieldName={'profileImage'} />
            </div>
            <UserBaseElements user={customer} setUser={setCustomer} />
            <LawFormGrid>
                <LawInput label={"Postcode"} propstate={[customer, setCustomer, 'postcode']} />
                <LawInput label={"BuildingNumber"} propstate={[customer, setCustomer, 'buildingNumber']} />
                <LawInput label={"BuildingSuffix"} propstate={[customer, setCustomer, 'buildingSuffix']} />
                <LawCitiesSelect state={customer} setState={setCustomer} fieldName={'cityId'} />
                <LawInput label={"Address"} propstate={[customer, setCustomer, 'address']} />
                <LawSelect label={"Status"} items={lawStatusActivePassive} state={customer} setState={setCustomer} fieldName={'status'} />
                <LawSelect itemsUrl={"app/get-jobs-for-select"} state={customer} setState={setCustomer} fieldName={'jobId'} />
            </LawFormGrid>
        </>
    )
}
export default CustomerEdit;