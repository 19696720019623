import { ChevronRight } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { wdfDynamicImportUrlParameter, wgfDateTimeFormat, wgfValueToPriceText } from "../../../functions/lawFunctions";
import { lawFetchGet } from "../../../functions/lawFetch";
import LawModal from "../../../ui/LawModal";
import InvoiceTemplate from "../../accountant/InvoiceTemplate";
import LawDataTable from "../../../ui/LawDataTable";
import LawStatus from "../../../ui/LawStatus";
import LawLogTimeline from "../../../ui/LawLogTimeline";
import LawIconButton from "../../../ui/LawIconButton";

const InvoicesTable = ({ customerId, companyId, filterController }) => {
    const [invoiceModalShow, setInvoiceModalShow] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState(null)
    const [invoices, setInvoices] = useState([]);
    const getAll = () => {
        let url = wdfDynamicImportUrlParameter('invoice/all', [
            { default: customerId, key: 'customerId' },
            { default: companyId, key: 'companyId' }
        ]);
        lawFetchGet(url, null, setInvoices, [], null, null, null, null);
    }
    useEffect(() => {
        getAll();
    }, [])
    const handleSelectInvoice = (id) => {
        setSelectedInvoice(id);
        setInvoiceModalShow(true);
    }
    const deleteCallback=()=>{
        getAll();
        setInvoiceModalShow(false);
        setSelectedInvoice(null);
        
    }

    let columns = [
        {
            name: 'Id',
            selector: (row) => row.id,
            sortable: true,
        },
        {
            name: 'Company Name',
            selector: (row) => row.companyName,
            sortable: true,
        },
        {
            name: 'SellerName',
            selector: (row) => row.sellerName,
            sortable: true,
        },

        {
            name: 'Status',
            cell: (row) => (
                <div className="d-flex align-items-center">
                    <LawLogTimeline tableName={'TblInvoices'} registerId={row.id} isIconButton={true} />
                    <LawStatus status={row.status} statusGroup={'InvoiceStatus'} />
                </div>
            )
        },
        {
            name: 'Create',
            selector: (row) => wgfDateTimeFormat(row.date, 'dd.MM.yyyy HH:mm'),
            sortable: true,
        },
        {
            name: 'Total',
            cell: (row, index) => (
                <strong className={row.invoiceType === 1 ? ' text-warning ' : ' text-danger '}>{wgfValueToPriceText(row.totalWithVat)}</strong>
            ),
        },
        {
            name: 'Action',
            width: '90px',
            cell: (row) => (
                <div className="law-dttb-actions">
                    <LawIconButton onClick={() => { handleSelectInvoice(row.id) }}>
                        <ChevronRight />
                    </LawIconButton>
                </div>
            ),
        }
    ];

    return (
        <>
            <LawDataTable dataState={invoices} filterController={filterController} setDataState={setInvoices} columns={columns} searchFields={['id', 'companyName', 'sellerName']} />
            {
                (invoiceModalShow && selectedInvoice) && (
                    <LawModal style={{ maxWidth: 'calc(100% - 10px)', width: 'calc(100% - 10px)', maxHeight: '100vh' }} state={invoiceModalShow} setState={setInvoiceModalShow}>
                        {
                            /**deleteEvent={() => handleDeleteInvoice(selectedInvoice)} */
                        }
                        <InvoiceTemplate editCallback={getAll} invoiceId={selectedInvoice} deleteCallback={deleteCallback} />
                    </LawModal>
                )
            }
        </>
    )
}
export default InvoicesTable;
