import { useState } from "react";
import { wgfIsNotNull, wgfOnChangeEvent, wgfValidation } from "../functions/lawFunctions";
import { appObjects } from "../../App";
import { WgControlsStyle, wgStaticInputTypes } from "../lawConstants";
import { FormControl, IconButton, Input, InputAdornment, InputLabel, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import LawIconButton from "./LawIconButton";

const _style = {
    valid: { backgroundColor: '#fff' },
    invalid: { backgroundColor: '#ff1400', color: '#fff' }
}
/*
inputAdornments = Input'a [€ ₺ $] eklenecek olan karakterler icin
*/
const LawInput = ({ style, inputAdornments, type, onKeyup, step, min, max, validType, propstate, placeholder, label }) => {
    const [currentStyle, setCurrentStyle] = useState(_style.valid);
    const [passwordShow, setPasswordShow] = useState(false);
    const onchangeEvent = (value) => {
        let _setStyle = _style.valid;
        let _value = value;
        if (wgfIsNotNull([_value]) && validType) {
            //aynı text birden fazlasi icnde kullanılabilecek
            let _types = validType.split(',');
            if (_types && _types.includes('phone')) {
                if (_value === '0') {
                    //ilk karakter 0 ise sil.
                    _value = '';
                }
            }
            let _isValid = false;
            _types.forEach(item => {
                if (_isValid === false) {
                    _isValid = wgfValidation[item](_value);
                }
            })
            _setStyle = (_isValid === true ? _style.valid : _style.invalid);
        }
        setCurrentStyle(_setStyle);
        let _props = { propstate: propstate };
        if (type === 'number' && _value) {
            _value = Number(_value);
        }
        wgfOnChangeEvent(_props, null, _value, null, null)
    }
    const onblur = (value) => {
        let _value = value;
        if (currentStyle.backgroundColor === '#ff1400' && wgfIsNotNull([_value]) && validType) {
            _value = null;
            let _props = { propstate: propstate };
            wgfOnChangeEvent(_props, null, _value, null, null);
            appObjects.snackbar("Hatalı bilgileriniz silindi.Lütfen yeniden yazınız.", "info")
        }
    }
    /*
    NOT: Input nesnesi NULL olunca React hata mesajı vermekte. Bu yüzden [??''] ekledim
    https://bobbyhadz.com/blog/react-value-prop-on-input-should-not-be-null
    */
    const getInput = () => {
        let _inputProps = {};
        _inputProps['onBlur'] = (e) => onblur(e.target.value);
        _inputProps['onChange'] = (e) => onchangeEvent(e.target.value);
        if (onKeyup) {
            _inputProps['onKeyUp'] = (e) => onKeyup(e);
        }
        _inputProps['placeholder'] = placeholder ?? '';
        if (inputAdornments) {
            _inputProps['slotProps'] = {
                input: {
                    startAdornment: <InputAdornment position="start">{inputAdornments}</InputAdornment>,
                },
            }
        }
        /*STYLE*****************************************/
        if (type !== 'password') {
            _inputProps['style'] = { ...WgControlsStyle, width: '100%' };
        }
        /*TYPE*****************************************/
        let _inputType = (type ? (wgStaticInputTypes[type] ?? wgStaticInputTypes.text) : wgStaticInputTypes.text); //text ortak default type
        if (step) {
            _inputProps['step'] = step;
        }
        if (type === 'password') {
            if (passwordShow === true) {
                _inputType = wgStaticInputTypes.text;
            }
            else {
                _inputType = type;
            }
        }
        _inputProps['type'] = _inputType;
        if (label) {
            _inputProps['label'] = label;
        }
        /*VALUE****************************************/
        if (propstate) {
            if (propstate[2]) {
                _inputProps['value'] = propstate[0][propstate[2]] ?? '';
            } else {
                _inputProps['value'] = propstate[0] ?? '';
            }
        }
        /*****************************************/
        return _inputProps;
    }
    const handleClickShowPassword = () => setPasswordShow((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleMouseUpPassword = (event) => {
        event.preventDefault();
    };

    return (
        <div style={{ borderBottom: 'none' }}>
            {
                (type === "password") ? (
                    <>
                        <FormControl sx={{ width: '100%' }} size="small" variant="outlined">
                            <InputLabel size="small" htmlFor="standard-adornment-password">{label}</InputLabel>
                            <Input
                                size="small"
                                id="standard-adornment-password"
                                type={passwordShow ? 'text' : 'password'}
                                {...getInput()}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <LawIconButton
                                            aria-label={
                                                passwordShow ? 'hide the password' : 'display the password'
                                            }
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            onMouseUp={handleMouseUpPassword}
                                        >
                                            {passwordShow ? <VisibilityOff /> : <Visibility />}
                                        </LawIconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </>
                ) : (
                    <TextField size="small" variant="outlined" {...getInput()} />)
            }
        </div>
    )
}
export default LawInput;