import { Grid2 } from "@mui/material";
import { useState } from "react";
import { LineChart, PieChart } from "@mui/x-charts";
import { getDayArray } from "../../../functions/lawFunctions";
import LawDatePickerFromAndTo from "../../../ui/LawDatePickerFromAndTo";

const ReportDaily = () => {
    const [fromDate, setFromDate] = useState(new Date((new Date()).setMonth(-1)));
    const [toDate, setToDate] = useState(new Date());
    return (
        <Grid2 container={true} spacing={2} sx={{ mb: '30px' }}>
            <Grid2 size={12}>
                <div className="d-flex justify-content-end">
                    <LawDatePickerFromAndTo fromState={fromDate} setFromState={setFromDate} toState={toDate} setToState={setToDate} />
                </div>
            </Grid2>
            {
                (fromDate && toDate) && (
                    <>
                        <Grid2 size={{ sx: 12, md: 12, lg: 6, xl: 4 }}>
                            <PieChart
                                series={[
                                    {
                                        data: [
                                            { id: 0, value: 20, label: 'Gelir Toplamı', color: 'orange' },
                                            { id: 1, value: 15, label: 'Gider Toplamı', color: 'red' },
                                        ],
                                    },
                                ]}
                                margin={{ top: 0, bottom: 50, left: 20, right: 20 }}
                                width={400}
                                height={300}
                                slotProps={{
                                    legend: {
                                        direction: 'row',
                                        position: { vertical: 'bottom', horizontal: 'middle' },
                                        padding: 0,
                                    },
                                }}
                            />
                        </Grid2>
                        <Grid2 size={{ sx: 12, md: 12, lg: 6, xl: 8 }}>
                            <LineChart
                                xAxis={[{ data: getDayArray(fromDate.getFullYear(), fromDate.getMonth()).map(item => item.value) }]}
                                series={[
                                    {
                                        data: [2, 5.5, 2, 8.5, 1.5, 5],
                                        valueFormatter: (value) => (value === null ? 'NaN' : value.toString()),
                                        label: 'Gelir',
                                        color: 'orange'
                                    },
                                    {
                                        data: [7, 8, 5, 4, null, null, 2, 5.5, 1],
                                        valueFormatter: (value) => (value === null ? '?' : value.toString()),
                                        label: 'Gider',
                                        color: 'red'
                                    },
                                ]}
                                height={400}
                                margin={{ top: 10, bottom: 20 }}
                            />
                        </Grid2>
                    </>
                )
            }
        </Grid2>
    )
}
export default ReportDaily;