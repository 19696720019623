import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Avatar, Badge, Button } from '@mui/material';
import { wgfChangeState, wgfFileUrlToSrc } from '../functions/lawFunctions';
import { useState } from 'react';
import LawModal from './LawModal';
import LawImageCrop2 from './LawCropImage2';
import VisuallyHiddenInput from './VisuallyHiddenInput';
import { wgfImageResize } from '../functions/imageResize';

const LawUploadImage = ({ state, setState, fieldName, maxWidth, maxHeight, isCircle, aspect }) => {
    const [cropModalShow, setCropModalShow] = useState(false);
    const handleSelectFile = async (e) => {
        let file = e.target.files ? e.target.files[0] : null;
        if (file) {
            if (file.name) {
                let fileName = file.name.toLowerCase();
                if (fileName.includes('.png') || fileName.includes('.jpg') || fileName.includes('.jpeg') || fileName.includes('.bmp')) {
                    const image = await wgfImageResize(file, maxWidth, maxHeight);
                    wgfChangeState(image, fieldName, state, setState);
                    setCropModalShow(true);
                }
            }
        }
    }
    const croppedCallback = () => {
        setCropModalShow(false);
    }
    return (
        <>
            <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={
                    <CloudUploadIcon sx={{ color: '#999' }} />
                }
            >
                <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    sx={{ background: 'transparent', width: '99px', height: '90px', borderRadius: '99px', p: 0, boxShadow: 'none' }}
                >
                    <VisuallyHiddenInput
                        type="file"
                        multiple={false}
                        onChange={handleSelectFile}
                    />
                    <Avatar alt="" src={wgfFileUrlToSrc(state ? (fieldName ? state[fieldName] : state) : '')} sx={{ width: '99px', height: '99px' }} />
                </Button>
            </Badge>
            {
                (cropModalShow) && (
                    <LawModal state={cropModalShow} setState={setCropModalShow} style={{ maxWidth: '800px', padding: '0px' }}>
                        <LawImageCrop2 state={state} setState={setState} fieldName={fieldName} isCircle={isCircle ?? false} aspect={aspect ?? (4 / 4)} croppedCallback={croppedCallback} />
                    </LawModal>
                )
            }


        </>
    )
}
export default LawUploadImage;