import { useEffect, useState } from "react";
import { lawFetchGet, lawFetchPost } from "../../../functions/lawFetch";
import { wgfIsNotNull } from "../../../functions/lawFunctions";
import LawModal from "../../../ui/LawModal";
import FaqFormBase from "./FaqFormBase";
import { Button } from "@mui/material";
const FaqEdit = ({ id, setId, successCallback }) => {
    const [open, setOpen] = useState(true);
    const [faq, setFaq] = useState({ Id: id ?? '' });
    useEffect(() => {
        if (id) {
            lawFetchGet("faqs/get?id=" + id, null, setFaq, {}, null, null, false, (resp) => {
                if (resp) {
                    setFaq(resp.data);
                    setOpen(true);
                }
            });
        }
    }, [id])
    useEffect(() => {
        if (open === false) {
            setFaq({ Id: id ?? '' });
            setId(null);
        }
    }, [open])

    const edit = () => {
        if (wgfIsNotNull([faq['question']], true) === true) {
            lawFetchPost('faqs/edit', faq, null, null, null, () => {
                setFaq({ Id: id ?? '' });
                setOpen(false);
                setId(null);
                if (successCallback) {
                    successCallback();
                }
            }, null, true, null);
        }
    }
    return (
        <>
            <LawModal state={open} setState={setOpen}>
                <FaqFormBase faq={faq} setFaq={setFaq}/>
                <div className="jf-center mt-3 mb-5">
                    <Button onClick={edit}>Opslaan</Button>
                </div>
            </LawModal>
        </>
    )
}
export default FaqEdit;