import { useState } from "react";
import LawIconButton from "../../../ui/LawIconButton";
import { Check, ChevronRight, Close, Email, Headset, PersonOutline, Phone } from "@mui/icons-material";
import LawModal from "../../../ui/LawModal";
import { Box, Grid2, Step, StepConnector, stepConnectorClasses, StepLabel, Stepper, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import LawToolbar from "../../../ui/LawToolbar";
import InvoicesTable from "../../accountant_customer/invoice/InvoicesTable";
import LawUploadFile from '../../../ui/LawUploadFile'
import styled from "styled-components";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#784af4',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#784af4',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1
    },
}));
const QontoStepIconRoot = styled('div')(({ theme }) => ({
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    '& .QontoStepIcon-completedIcon': {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
    variants: [
        {
            props: ({ ownerState }) => ownerState.active,
            style: {
                color: '#784af4',
            },
        },
    ],
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}
const Sale = ({ id }) => {
    const [show, setShow] = useState(false);
    const [tabValue, setTabValue] = useState('1');
    const handleShow = () => {
        setShow(true);
    }
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    return (
        <>
            <LawIconButton onClick={handleShow}>
                <ChevronRight />
            </LawIconButton>
            {
                show && (
                    <LawModal header={'Sale Infoes'} state={show} setState={setShow} style={{ maxWidth: '1300px', height: '100vh', width: '100%' }}>
                        <Stepper alternativeLabel activeStep={1} connector={<QontoConnector />}>
                            <Step>
                                <StepLabel StepIconComponent={QontoStepIcon}>Status 1</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel StepIconComponent={QontoStepIcon}>Status 2</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel StepIconComponent={QontoStepIcon}>Status 3</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel StepIconComponent={QontoStepIcon}>Status 4</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel StepIconComponent={QontoStepIcon}>Status 5</StepLabel>
                            </Step>
                        </Stepper>
                        <LawToolbar>
                            <LawIconButton icon={<Phone />} />
                            <LawIconButton icon={<Email />} />
                            <LawIconButton icon={<Check />} />
                            <LawIconButton icon={<Close />} />
                        </LawToolbar>
                        <Grid2 container spacing={0}>
                            <Grid2 size={{ lg: 6 }}>
                                <PersonOutline />
                                <span className="d-block"><b>Name:</b>...</span>
                                <span className="d-block"><b>Surname:</b>...</span>
                                <span className="d-block"><b>Email:</b>...</span>
                                <span className="d-block"><b>Phone:</b>...</span>
                                <h5>Sale Infoes</h5>
                                <span className="d-block"><b>Sale ID:</b>34534535</span>
                                <span className="d-block"><b>Sale Date:</b>01.01.2025 10:00</span>
                                <span className="d-block"><b>Portal Name:</b>Salesdock</span>
                                <span className="d-block"><b>Country:</b>Netherlands</span>
                                <span className="d-block"><b>Address:</b>.dg dfg dfsgdf hdf hfd hf h</span>
                                <h5><Headset /></h5>
                                <span className="d-block"><b>Name:</b>...</span>
                                <span className="d-block"><b>Surname:</b>...</span>
                            </Grid2>
                            <Grid2 size={{ lg: 6 }}>
                                <div style={{ background: '#ddd' }}>
                                    <TabContext value={tabValue}>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                                                <Tab label="Details" value="1" />
                                                <Tab label="Documents" value="2" />
                                                <Tab label="Logs" value="3" />
                                            </TabList>
                                        </Box>
                                        <TabPanel value="1">Deta</TabPanel>
                                        <TabPanel value="2">
                                            <LawUploadFile />
                                        </TabPanel>
                                        <TabPanel value="3">
                                            <InvoicesTable />
                                        </TabPanel>
                                    </TabContext>
                                </div>
                            </Grid2>
                        </Grid2>


                    </LawModal>
                )
            }

        </>
    )
}
export default Sale;