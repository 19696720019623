import { Add, Save } from "@mui/icons-material";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import { useState } from "react";
import BoxCenter from "../../../ui/BoxCenter";
import LawModal from "../../../ui/LawModal";
import LawInput from "../../../ui/LawInput";
import { lawFetchPost } from "../../../functions/lawFetch";
import { wgfIsNotNull, wgfObjectClone } from "../../../functions/lawFunctions";
import LawIconButton from "../../../ui/LawIconButton";
import Card from "../../../ui/Card";
import LawToolbar from "../../../ui/LawToolbar";
import LawTextarea from "../../../ui/LawTextarea";

const TicketAddStep = ({ ticketId, callback }) => {
    const [open, setOpen] = useState(false);
    const [step, setStep] = useState({});
    const create = () => {
        let _stp = wgfObjectClone(step);
        if (wgfIsNotNull([_stp['header'], ticketId], true)) {
            _stp['TicketId'] = ticketId;
            lawFetchPost('ticket/add-step', _stp, null, null, null, () => {
                setOpen(false);
                setStep({});
                if (callback) {
                    callback();
                }
            }, null, true, null);
        }
    }
    return (
        <>
            <LawIconButton title="Status toevoegen" onClick={() => setOpen(true)}><Add /></LawIconButton>
            {
                (open) && (
                    <LawModal header={'Ticketstap'} state={open} setState={setOpen} style={{maxWidth: '360px',width:'96%' }}>
                        <Card>
                            <LawToolbar>
                                <LawIconButton onClick={create} icon={<Save />} />
                            </LawToolbar>
                            <LawInput label={"Header*"} propstate={[step, setStep, 'header']} />
                            <LawTextarea propstate={[step, setStep, 'comment']} label={'Opmerking'}/>
                        </Card>
                    </LawModal>
                )
            }
        </>
    )
}
export default TicketAddStep;