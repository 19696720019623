import { useEffect, useState } from "react";
import { lawFetchGet, lawFetchPost } from "../../../functions/lawFetch";
import { wgfIsNotNull } from "../../../functions/lawFunctions";
import LawCreateDataModal from "../../../ui/LawCreateDataModal";
import FaqFormBase from "./FaqFormBase";
const FaqCreate = ({ id, setId, successCallback }) => {
    const [open, setOpen] = useState(false);
    const [faq, setFaq] = useState({ Id: id ?? '' });
    useEffect(() => {
        if (id) {
            lawFetchGet("faqs/get?id=" + id, null, setFaq, {}, null, null, false, (resp) => {
                if (resp) {
                    setFaq(resp.data);
                    setOpen(true);
                }
            });
        }
    }, [id])
    
    useEffect(() => {
        if (open === false) {
            setFaq({ Id: id ?? '' });
            setId(null);
        }
    }, [open])

    const edit = () => {
        if (wgfIsNotNull([faq['question']], true) === true) {
            lawFetchPost('faqs/edit', faq, null, null, null, () => {
                setFaq({ Id: id ?? '' });
                setOpen(false);
                setId(null);
                if (successCallback) {
                    successCallback();
                }
            }, null, true, null);
        }
    }

    return (
        <>
            <LawCreateDataModal createEvent={edit}>
               <FaqFormBase faq={faq} setFaq={setFaq}/>
            </LawCreateDataModal>
           {
            /*
             <Button onClick={() => setOpen(true)}><Add /> Create Faq</Button>
            {
                (open) && (
                    <LawModal state={open} setState={setOpen} style={{ maxWidth: '800px' }}>

                        <Button variant="contained" onClick={edit}>Opslaan</Button>
                    </LawModal>
                )
            }
            */
           }
        </>
    )
}
export default FaqCreate;