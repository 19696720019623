import { useEffect, useState } from "react";
import { Check, Delete } from "@mui/icons-material";
import TicketAddStep from "./TicketAddStep";
import BoxCenter from "../../../ui/BoxCenter";
import { lawFetchGet } from "../../../functions/lawFetch";
import { wgfDateTimeFormat } from "../../../functions/lawFunctions";
import LawDelete from "../../../ui/LawDelete";
import LawIconButton from "../../../ui/LawIconButton";

const Ticket = ({ id, removeCallback, completedCallback }) => {
    const [ticket, setTicket] = useState(null);
    const [isRemoved, setIsRemoved] = useState(false);
    const getTicket = () => {
        lawFetchGet('ticket/get?id=' + id, null, setTicket, null, null, null, false, null);
    }
    //useEffect(getTicket, []);
    useEffect(() => {
        getTicket();
    }, [id]);
    const addStepCallback = () => {
        getTicket();
    }
    const deleteCallback=()=>{
        if (removeCallback) {
            removeCallback();
        }
        setIsRemoved(true);
    }

    const createStepItem = (step, index, className) => {
        return (
            <div key={index} className={("time_line-item item_active item_show " + className)}>
                <div className="time_line-date_wrap">
                    <div className="seofy_hexagon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 177.4 197.4">
                            <path d="M0,58.4v79.9c0,6.5,3.5,12.6,9.2,15.8l70.5,40.2c5.6,3.2,12.4,3.2,18,0l70.5-40.2c5.7-3.2,9.2-9.3,9.2-15.8V58.4 c0-6.5-3.5-12.6-9.2-15.8L97.7,2.4c-5.6-3.2-12.4-3.2-18,0L9.2,42.5C3.5,45.8,0,51.8,0,58.4z">
                            </path>
                        </svg>
                    </div>
                    <div className="seofy_hexagon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 177.4 197.4">
                            <path d="M0,58.4v79.9c0,6.5,3.5,12.6,9.2,15.8l70.5,40.2c5.6,3.2,12.4,3.2,18,0l70.5-40.2c5.7-3.2,9.2-9.3,9.2-15.8V58.4 c0-6.5-3.5-12.6-9.2-15.8L97.7,2.4c-5.6-3.2-12.4-3.2-18,0L9.2,42.5C3.5,45.8,0,51.8,0,58.4z">
                            </path>
                        </svg>
                    </div>
                    <h5 className="time_line-date">{wgfDateTimeFormat(step.date, 'dd.MM.yyyy HH:mm')}</h5>
                </div>
                <div className="time_line-content">
                    <h5 className="time_line-title">{step.header}</h5>
                    <div className="time_line-descr">{step.comment}</div>
                    <small className="d-block text-muted">{step.createdByUserName} | {wgfDateTimeFormat(step.date, 'dd.MM.yyyy HH:mm')}</small>
                </div>
            </div>
        )
    }
    const handleTicketCompleted = () => {
        lawFetchGet('ticket/completed?id=' + ticket.id, null, null, null, ()=>{
            if(completedCallback){
                completedCallback();
            }
            getTicket();
        }, null, true, null);
    }

    return (
        (ticket) && (
            <div style={{ position: 'relative' }}>
                {
                    (isRemoved) && (
                        <div className="law-bg-blur" style={{ position: 'absolute', top: 0, height: '100%', width: '100%', left: 0, zIndex: 9999, background: 'rgba(0,0,0,0.7)' }}>
                            <div style={{ width: 'fit-content', margin: 'auto', marginTop: '100px' }}>
                                <BoxCenter>
                                    <Delete sx={{ fontSize: '100px', color: '#fff' }} />
                                </BoxCenter>
                                <h5 className="text-center w-100 d-block text-light">Dit ticket is verwijderd!</h5>
                            </div>
                        </div>
                    )
                }
                <div style={{ padding: '30px 15px', background: '#eee'}}>
                    <h3>{ticket.name}</h3>
                    <h6>{ticket.comment}</h6>
                    <small className="d-block text-muted">{(ticket.createdByUserName + ' | ')}{wgfDateTimeFormat(ticket.date, 'dd.MM.yyyy HH:mm')}</small>
                    <small className="d-block text-muted mb-3">{ticket.targetUsers}</small>
                    {
                        (ticket.status !== 100) && (
                            <div style={{ display: 'flex', gap: '7px' }}>
                                <LawDelete deleteCallback={deleteCallback} deleteData={ticket} tableName={'TblTickets'}/>
                                <div style={{ width: 'fit-content' }}>
                                    <TicketAddStep ticketId={ticket.id} callback={addStepCallback} />
                                </div>
                                <LawIconButton title="Ticket voltooid" color="success" onClick={handleTicketCompleted}><Check /></LawIconButton>
                            </div>
                        )
                    }
                </div>
                <div className="finbyz-timeline px-4" >
                    <div className={"wgl-row-animation"}>
                        <div data-vc-full-width="true" data-vc-full-width-init="true" className="vc_row wpb_row vc_row-fluid vc_row-has-fill wgl-row-animation">
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                <div className="vc_column-inner ">
                                    <div className="wpb_wrapper">
                                        <div className="seofy_module_spacing">
                                            <div className="spacing_size spacing_size-initial" style={{ height: '30px' }}></div>
                                        </div>
                                        <div className="seofy_module_time_line_vertical appear_anim">
                                            {
                                                /*class names :warning|danger|info   */
                                            }
                                            {
                                                (ticket.steps) && (
                                                    ticket.steps.map((item, index) => {
                                                        if (index === (ticket.steps.length - 1)) {
                                                            return createStepItem(item, index, 'info');
                                                        }
                                                        else if (index === 0) {
                                                            return createStepItem(item, index, 'warning');
                                                        }
                                                        else {
                                                            return createStepItem(item, index, 'primary');
                                                        }

                                                    })
                                                )
                                            }
                                        </div>
                                        <div className="seofy_module_spacing responsive_active">
                                            <div className="spacing_size spacing_size-initial" style={{ height: '76px' }}></div>
                                            <div className="spacing_size spacing_size-tablet" style={{ height: '65px' }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    )
}
export default Ticket;