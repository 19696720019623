import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { wgfFileUrlToSrc, wgfGetQueryStringValue, wgfTextCropAndDots } from "../functions/lawFunctions";
import { lawFetchGet } from "../functions/lawFetch";
import { Avatar, MenuItem } from "@mui/material";
import { appObjects } from "../../App";
import LawIconButton from "./LawIconButton";
import { ChevronRight } from "@mui/icons-material";

const SidebarCustomerCompany = () => {
    const [searchParams] = useSearchParams();
    const [company, setCompany] = useState(null);
    useEffect(() => {
        let companyId = wgfGetQueryStringValue('companyId');
        if (companyId) {
            if (!company || company.id != companyId) {
                lawFetchGet('customerCompany/get-info?id=' + companyId, null, setCompany, null, null, null, false, null);
            }
        } else {
            setCompany(null);
        }
    }, [searchParams])
    const handleGoToCompany = () => {
        appObjects.goToWithViewIdentity('CompanyView', '?companyId=' + company.id);
    }
    return (
        company && (
            <>
                <div style={{ display: 'grid', gridTemplateColumns:'auto 1fr auto', alignContent:'center', width: '100%', marginBottom: '20px', background: '#ddd', padding: '10px' }}>
                    <Avatar src={wgfFileUrlToSrc(company.logo, true)} alt={company.className} />
                    <div style={{ paddingLeft: '10px' }}>
                        <span className="d-block" style={{ fontSize: '14px' }}>{wgfTextCropAndDots(company.name, 20)}</span>
                        <small className="d-block text-muted" style={{ fontSize: '13px' }}>{company.customer}</small>
                    </div>
                    <div>
                        <LawIconButton onClick={handleGoToCompany} icon={<ChevronRight />} />
                    </div>
                </div>
            </>
        )
    )
}
export default SidebarCustomerCompany;