import { IconButton, Tooltip } from "@mui/material";

const LawIconButton = ({ title, children, onClick, sx, style, color, size ,className, icon}) => {
    return (
        <Tooltip title={title} arrow>
            <IconButton onClick={onClick} sx={sx} style={style} color={color} size={size ?? 'small'} className={className}>
            {icon ?? children}
            </IconButton>
        </Tooltip>
    )
}
export default LawIconButton;