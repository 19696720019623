import { useEffect, useState } from "react";
import { lawFetchGet } from "../../../functions/lawFetch";
import FaqCreate from "./FaqCreate";
import { IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { wgfDateTimeFormat } from "../../../functions/lawFunctions";
import LawDataTable from "../../../ui/LawDataTable";
import LawStatus from "../../../ui/LawStatus";
import FaqEdit from "./FaqEdit";
import LawDelete from "../../../ui/LawDelete";
import LawIconButton from "../../../ui/LawIconButton";
const TableFaqs = () => {
    const [faqs, setFaqs] = useState([]);
    const [selectedId, setSelectedId] = useState(null);
    const getAll = () => {
        lawFetchGet('faqs/all', null, setFaqs, [], null, null, false, null);
    }
    useEffect(() => {
        getAll();
    }, [])
    const handleSelect = (id) => {
        setSelectedId(id);
    }

    let columns = [
        {
            name: 'Id',
            selector: (row) => row.id,
            sortable: true,
        },
        {
            name: 'Question',
            selector: (row) => row.question,
            sortable: true,
        },
        {
            name: 'Status',
            cell: (row) => (
                <LawStatus status={row.status} statusGroup={'ActivePassiveDeleted'} />
            )
        },
        {
            name: 'Create',
            selector: (row) => wgfDateTimeFormat(row.createDate, 'dd.MM.yyyy HH:mm'),
            sortable: true,
        },
        {
            name: 'Update',
            selector: (row) => wgfDateTimeFormat(row.updateDate, 'dd.MM.yyyy HH:mm'),
            sortable: true,
        },
        {
            name: 'Action',
            width: '120px',
            cell: (row) => (
                <div className="law-dttb-actions">
                    <LawDelete deleteCallback={getAll} deleteData={row} tableName={'TblFaqs'} />
                    <LawIconButton onClick={() => { handleSelect(row.id) }}>
                        <Edit />
                    </LawIconButton>


                </div>
            ),
        }
    ];

    return (
        <>
            <LawDataTable
                toolbarItems={
                    <>
                        <FaqCreate id={selectedId} successCallback={getAll} setId={setSelectedId} />
                    </>
                }
                dataState={faqs} setDataState={setFaqs} columns={columns} searchFields={['id', 'question']} />
            {
                (selectedId) && (
                    <FaqEdit id={selectedId} setId={setSelectedId} successCallback={getAll} />
                )
            }
        </>
    )
}
export default TableFaqs;