import LawHtmlEditor from "../../../ui/LawHtmlEditor";
import LawInput from "../../../ui/LawInput";

const FaqFormBase = ({faq, setFaq}) => {
    return (
        <>
            <LawInput label={"Question"} propstate={[faq, setFaq, 'question']} />
            <div className="mt-3 mb-5">
                <LawHtmlEditor style={{ height: '60vh' }} state={faq} setState={setFaq} fieldName={'content'} />
            </div>
        </>
    )
}
export default FaqFormBase;