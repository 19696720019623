import { useEffect, useState } from "react";
import { lawFetchGet, lawFetchPost } from "../../../functions/lawFetch";
import EmployeeFormBase from "./EmployeeFormBase";
import { IconButton } from "@mui/material";
import { wgfIsNotNull } from "../../../functions/lawFunctions";
import LawToolbar from "../../../ui/LawToolbar";
import { Save } from "@mui/icons-material";
import LawIconButton from "../../../ui/LawIconButton";

const EmployeeEdit = ({ employeeId, callback }) => {
    const [employee, setEmployee] = useState(null);
    useEffect(() => {
        if (employeeId) {
            lawFetchGet('CompanyEmployees/get?id=' + employeeId, null, setEmployee, null, null, null, false, null);
        }
    }, [])
    const handleSave = () => {
        if (wgfIsNotNull([employee['name'], employee['sureName']], true) === true) {
            lawFetchPost('CompanyEmployees/edit', employee, null, null, null, () => {
                if (callback) {
                    callback();
                }
            }, null, true, null);
        }
    }
    return (
        (employee) && (
            <>
                <LawToolbar>
                    <LawIconButton onClick={handleSave}><Save /></LawIconButton>
                </LawToolbar>
                <EmployeeFormBase employeeState={employee} setEmployeeState={setEmployee} />
            </>
        )
    )
}
export default EmployeeEdit;