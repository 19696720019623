import './App.css';

import { BrowserRouter as Router } from 'react-router-dom';
import { createContext, useEffect, useState } from 'react';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { lawFetchGet } from './components/functions/lawFetch';
import { wgSnackbarTypes } from './components/lawConstants';
import { wgfLoginControl, wgfVersionControl } from './components/functions/lawFunctions';
import LawRoutes from './pages/PortalRoutes';
import LawDialog from './components/ui/LawDialog';
export const ContxAppData = createContext();
export const appObjects = {
  goTo: () => { },
  goToWithViewIdentity: (viewIdentity, search) => { },
  getAppDatas: () => { },
  snackbar: (message, variant) => { },
  disconnect: () => { },
  values: {
    loginInfoInitial: {
      loginToken: '',
      expiration: '',
      isLogin: false,
      name: '',
      surname: '',
      id: '',
      roleName: ''
    },
    loginInfo: null,
    roleGroup: {
      lawyer: false,
      lawyerAssistant: false,
      accountant: false,
      accountantAssistant: false,
      accountantLawyer: false
    },
    appDatas: null,
    createInvoiceBreakWidth: 1400
  },
  drawerWidth: 290,
  messagePage: {
    getContacts: () => { },
    signalRMessage: () => { },
    getContactInfo: (contactId) => { },
    getNewMessageCount: () => { }
  },
  notifications: {
    getNotificationCount: () => { }
  },
  stickyNote: {
    getCount: () => { },
    showDrawer: (isShow) => { }
  },
  dialog: {
    yesNo: (title, description, yesCallback, noCallback)=>{},
    delete: (yesCallback, noCallback) =>{},
    yesNoWithDescription:(title, description, yesCallback, noCallback, descriptionState,setDescriptionState,descriptionFieldName) =>{}
  }
}

function App() {
  const [appDatas, setAppDatas] = useState(null);
  const [dialog, setDialog] = useState(null);
  const [showDisconnect, setShowDisconnect] = useState(false);
  const [portal, setPortal] = useState(null);
  const [showDrawer, setShowDrawer] = useState(false);
  wgfLoginControl();
  useEffect(() => {
    if (appObjects.values.loginInfo && appObjects.values.loginInfo.isLogin) {
      //lawFetchGet('app/get-sidebar-items-for-routes', null, setSidebarItems, [], null, null, false, null);
      appObjects.getAppDatas();
    }
    wgfVersionControl();
  }, []);

  const { enqueueSnackbar } = useSnackbar();

  appObjects.getAppDatas = () => {
    if (!appDatas) {
      lawFetchGet('app/app-datas', null, setAppDatas, null, null, null, false, (resp) => {
        if (resp) {
          appObjects.values.appDatas = resp.data;
          setAppDatas(resp.data);
        }
      });
    }
  }
  appObjects.snackbar = (message, variant) => {
    enqueueSnackbar(message, {
      variant: variant ?? wgSnackbarTypes.warning,
      anchorOrigin: {
        horizontal: 'right',
        vertical: 'bottom'
      }
    });
  }
  appObjects.disconnect = () => {
    setShowDisconnect(true);
  }
  return (
    <>
      <ContxAppData.Provider value={{
        appDatas, setAppDatas,
        showDrawer, setShowDrawer,
        portal, setPortal
      }}>
        <Router>
          <LawRoutes />
        </Router>
      </ContxAppData.Provider>
      <LawDialog dialogState={dialog} setDialogState={setDialog} />

      {
        /* Internet baglanti kontrolu icin, bunu sonra tekrar duzenle
          (showDisconnect) && (
            <ServerDisconnect />
          )
        */
      }
    </>
  );
}
//export default App;
export default function IntegrationNotistack() {
  return (
    <SnackbarProvider maxSnack={5}>
      <App />
    </SnackbarProvider>
  );
}