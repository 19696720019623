import { Button, IconButton, Tooltip } from "@mui/material";
import { useState } from "react";
import { lawFetchPost } from "../../../functions/lawFetch";
import LawInputPassword from "../../../ui/LawInputPassword";
import LawModal from "../../../ui/LawModal";
import { Key } from "@mui/icons-material";
import LawIconButton from "../../../ui/LawIconButton";
const ChangePassword = ({ userId, isMyPassword }) => {
    const [modalShow, setModalShow] = useState(false);
    const [password, setPassword] = useState({ currentPassword: '', userId: userId });
    const changePassword = () => {
        if (isMyPassword) {
            lawFetchPost('/account/change-password', password, null, null, null, passwordsEmpty, null, true, null);
        }
        else {
            lawFetchPost('/account/reset-password', password, null, null, null, passwordsEmpty, null, true, null);
        }
    }
    const passwordsEmpty = () => {
        if (isMyPassword) {
            setPassword({ currentPassword: '', userId: userId, newPassword: '' });
        }
        else {
            setPassword({ userId: userId, newPassword: '' });
        }
    }
    const handleModalShow = () => {
        setModalShow(true);
    }
    return (
        <>
            <LawIconButton onClick={handleModalShow} title="Wachtwoord wijzigen">
                <Key />
            </LawIconButton>

            {
                (modalShow) && (
                    <LawModal state={modalShow} setState={setModalShow}>
                        <div sx={{ maxWidth: '340px' }}>
                            {
                                (isMyPassword) && (<LawInputPassword state={password} setState={setPassword} fieldName={'currentPassword'} label={"Current Password"} />)
                            }
                            <div className="d-block">
                                <LawInputPassword state={password} setState={setPassword} fieldName={'newPassword'} isWithReEnter={true} />
                            </div>
                            <div className="d-flex justify-content-center">
                                <Button variant="contained" onClick={changePassword}>Change Password</Button>
                            </div>
                        </div>
                    </LawModal>
                )
            }
        </>
    )
}
export default ChangePassword;