import { Box, Step, StepButton, StepLabel, Stepper } from "@mui/material";
import { useEffect, useState } from "react";
import SaleTableNews from "./SaleTableNews";
import SaleTableInProgress from "./SaleTableInProgress";
import SaleTableLastCheck from "./SaleTableLastCheck";
import SaleTableCompleted from "./SaleTableCompleted";
const steps = [
    {
        title: 'Nieuw',
        component: <SaleTableNews/>
    },
    {
        title: 'In uitvoering',
        component: <SaleTableInProgress/>
    },
    {
        title: 'Laatste controle',
        component: <SaleTableLastCheck/>
    },
    {
        title: 'Voltooid',
        component: <SaleTableCompleted/>
    }
];

const Sales = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState({});

    const handleStep = (step) => () => {
        setActiveStep(step);
    };
    useEffect(() => {
        // getAll();
    }, [])


    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Stepper nonLinear activeStep={activeStep} alternativeLabel>
                    {steps.map((item, index) => (
                        <Step key={'step-' + index} completed={completed[index]}>
                            <StepButton color="inherit" onClick={handleStep(index)}>
                                <StepLabel>{item.title}</StepLabel>
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>
            </Box>
            {steps[activeStep].component}
        </>
    )
}
export default Sales;