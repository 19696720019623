
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Button } from '@mui/material';
import VisuallyHiddenInput from './VisuallyHiddenInput';

const LawUploadFile = ({ title,state,setState,fieldName }) => {
    const handleChange=(e)=>{
        if(e.target.files && e.target.files.length>0){
            console.log(e.target.files[0])
        }
        
    }
    return (
        <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
        >
            {title ?? 'Upload file'}
            <VisuallyHiddenInput
                type="file"
                multiple={false}
                onChange={handleChange}
            />
        </Button>
    )
}

export default LawUploadFile;