import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import CompanyVehicleEdit from "./CompanyVehicleEdit";
import CompanyVehicleUsageTable from "./CompanyVehicleUsageTable";
import { useState } from "react";

const CompanyVehicleDetails = ({ vehicleId, editCallback }) => {
    const [tabValue, setTabValue] = useState('1');
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    return (
        <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                    <Tab label="Vehicle" value="1" />
                    <Tab label="Usage" value="2" />
                </TabList>
            </Box>
            <TabPanel value="1">
                <CompanyVehicleEdit vehicleId={vehicleId} callback={editCallback} />
            </TabPanel>
            <TabPanel value="2">
                <CompanyVehicleUsageTable vehicleId={vehicleId} />
            </TabPanel>
        </TabContext>
    )
}
export default CompanyVehicleDetails;