import Resizer from "react-image-file-resizer";
export const wgfImageResize = (file,maxWidth,maxHeight) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            maxWidth,
            maxHeight,
            "JPEG",
            80,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
        );
    });