import { useState } from "react";
import LawInput from "../../../ui/LawInput";
import { lawFetchPost } from "../../../functions/lawFetch";
import { wgfIsNotNull } from "../../../functions/lawFunctions";
import LawCheckbox from "../../../ui/LawCheckbox";
import LawDatePickerFromAndTo from "../../../ui/LawDatePickerFromAndTo";
import LawCreateDataModal from "../../../ui/LawCreateDataModal";
import LawInputNumber from "../../../ui/LawInputNumber";
import LawFormGrid from "../../../ui/LawFormGrid";

const CompanyVehicleUsageCreate = ({ vehicleId, callback }) => {
    const [usage, setUsage] = useState({ vehicleId });
    const handleCreate = () => {
        if (wgfIsNotNull([usage['vehicleId']], true) === true) {
            lawFetchPost('CompanyVehicleUsage/create', usage, null, null, null, () => {
                setUsage({ vehicleId });
                if (callback) {
                    callback();
                }
            }, null, true, null);
        }
    }
    return (
        <LawCreateDataModal createEvent={handleCreate}>
            <LawDatePickerFromAndTo fromState={usage} setFromState={setUsage} fromFieldName={'fromDate'} fromLabel={'From Date'} toState={usage} setToState={setUsage} toFieldName={'toDate'} toLabel={'To Date'} />
            <LawFormGrid>
                <LawInput propstate={[usage, setUsage, 'usageKm']} label={'Usage Km'} type={'number'} />
                <LawInputNumber time={usage} setTime={setUsage} fieldName={'usageHour'} label={'Usage Hour'} />
                <LawCheckbox propstate={[usage, setUsage, 'forBusiness']} label={'For Business'} />
            </LawFormGrid>
        </LawCreateDataModal>
    )
}
export default CompanyVehicleUsageCreate;