import { AccountCircle, ArrowBack, KeyboardArrowDown, Power, PowerSettingsNew, SettingsPower } from '@mui/icons-material';
import { Box, Drawer, Typography, MenuList, MenuItem, ListItemIcon, ListItemText, Divider, Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemButton } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import BoxCenter from './BoxCenter';
import { appObjects, ContxAppData } from '../../App';
import ChangePortal from './ChangePortal';
import { wgfAdminRoleControl, wgfGetQueryStringValue, wgfLogout } from '../functions/lawFunctions';
import { portalRouteIsCopyParentParams } from '../../pages/PortalRoutes';
import { lawFetchGet } from '../functions/lawFetch';
import SidebarCustomerCompany from './SidebarCustomerCompany';
import LawIconButton from './LawIconButton';
/*
isDivider: false,
icon: <Home sx={_iconStyles} />,
text: button text 
identity: butona ozel ID, rollere gore butonlari gostermek icin falan
url: url address
*/

export const styles = {
    menuItem: { borderRadius: '50px', color: '#5a5a5a', marginBottom: '3px', padding: '10px 15px', fontSize: '0.85rem' },
    menuItemActive: { borderRadius: '50px', color: '#5a5a5a', backgroundColor: '#ddd', marginBottom: '3px', padding: '10px 15px', fontSize: '0.85rem' },
    accordionMenuItems: { borderRadius: '50px', marginBottom: '3px', padding: '5px 15px', fontSize: '0.85rem' },
    accordionMenuItemsActive: { borderRadius: '50px', marginBottom: '3px', padding: '5px 15px', background: '#ddd', fontSize: '0.85rem' },
    accordionMenuItemsText: { padding: '5px 15px', fontWeight: 600, color: '#5a5a5a', fontSize: '0.85rem' },
    icon: { mr: 2, color: '#5a5a5a' }
}
const _compValues = {
    parent: null
}

const Sidebar = (props) => {
    const { appDatas } = useContext(ContxAppData);
    const [items, setItems] = useState([]);
    const location = useLocation(); // Mevcut sayfanın konumunu alır
    const handleDrawerToggle = () => {
        props.setMobileOpen(!props.mobileOpen);
    };
    const [expanded, setExpanded] = useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const findDoneParent = (url, search) => {
        if (appDatas && appDatas.sidebarItems) {
            url = url ?? window.location.pathname;
            /*
              >> url'i sidebarItems'lar icerisinden ara
            */
            /******************************************************/
            let view = appDatas.sidebarItems.find(f => f.url === url || '/' + f.url === url);
            if (view && view.parentRegisterId) {
                let parent = appDatas.sidebarItems.find(f => f.id === view.parentRegisterId);
                if (parent) {
                    let newUrl = (parent.url.startsWith('/') ? parent.url : '/' + parent.url);
                    if (parent.isShowSidebar === false) {
                        newUrl += window.location.search;
                    }
                    _compValues.parent = newUrl;
                }
            }
            else {
                _compValues.parent = null;
            }
        }
    }

    const getAll = () => {

        /*
        -Url'i al
            >>url kayıtlarda var mı
            >>>(hayır)==> kök dizine git
            >>>(evet)
            >>>>child'i var mı (sidebar'da gösterilecek olanları filtrele)
            >>>>>(hayır)
            >>>>>>parenti varmı
            >>>>>> (hayır) ==> kök dizine git
            >>>>>> (evet)
            >>>>>>>parenti ile aynı seviyede olanları al
            >>>>>(evet)
            >>>>>>childsleri yazdır
        */
        if (appDatas && appDatas.sidebarItems) {
            let items = appDatas.sidebarItems;
            if (appObjects.values.loginInfo && appObjects.values.loginInfo.portal) {
                items = items.filter(f => f.portal === appObjects.values.loginInfo.portal || !f.portal);
            }
            else {
                items = items.filter(f => !f.portal);
            }
            let rootItems = items.filter(f => f.isShowSidebar == true && !f.parentRegisterId);
            if (window.location.pathname !== '/' && window.location.pathname !== '/index') {
                let currentPage = appDatas.sidebarItems.find(f => f.url === window.location.pathname || '/' + f.url === window.location.pathname);
                if (currentPage) {
                    let childs = appDatas.sidebarItems.filter(f => (currentPage && f.parentRegisterId === currentPage.id) && f.isShowSidebar === true);
                    if (childs && childs.length > 0) {
                        items = childs;
                    }
                    else if (currentPage.parentRegisterId) {
                        let parent = appDatas.sidebarItems.find(f => f.id == currentPage.parentRegisterId);
                        do {
                            if (parent) {
                                items = appDatas.sidebarItems.filter(f => f.parentRegisterId === parent.id && f.isShowSidebar === true);
                                if (items.length == 0) {
                                    if (parent.parentRegisterId) {
                                        parent = appDatas.sidebarItems.filter(f => f.id == parent.parentRegisterId);
                                    }
                                    else {
                                        items = rootItems;
                                    }
                                }
                            }
                            else {
                                items = rootItems;
                            }
                        } while (items.length < 1)
                    }
                    else {
                        items = rootItems;
                    }
                }
                else {
                    items = rootItems;
                }
            }
            else {
                items = rootItems;
            }
            items = items.filter(f => f.isShowSidebar === true);

            if (items && items.length > 0) {
                items = items.map(item => {
                    item['newUrl'] = item.url;
                    if (portalRouteIsCopyParentParams(item.viewIdentity, appObjects.values.loginInfo.portal)) {
                        item['newUrl'] = item.url + window.location.search;
                    }
                    return item;
                })
                setItems(items);
            }
        }
    }
    useEffect(() => {
        getAll();
        findDoneParent();
    }, [location, appDatas])
    const handleButtonClick = (url) => {
        findDoneParent(url);
        appObjects.goTo(url);
    }
    const handleDoneParent = () => {
        if (_compValues.parent) {
            let url = _compValues.parent;
            let isGo = true;
            if (url.includes('/customer?')) {
                if (!url.includes('customerId=') && url.includes('companyId=')) {
                    isGo = false;
                    let tempCompanyId = wgfGetQueryStringValue('companyId');
                    if (tempCompanyId) {
                        lawFetchGet('customerCompany/get-customer-id?companyId=' + tempCompanyId, null, null, null, null, null, false, (resp) => {
                            if (resp && resp.data) {
                                url = url.split('?')[0];
                                if (url) {
                                    url = url + '?customerId=' + resp.data;
                                    findDoneParent(url);
                                    appObjects.goTo(url);
                                }
                            }
                        })
                    }
                }
            }
            if (isGo) {
                findDoneParent(url);
                appObjects.goTo(url);
            }
        }
    }

    const drawer = (
        <div style={{ display: 'grid', gridTemplateRows: 'auto 1fr auto', height: '100vh' }}>
            <div className='d-block w-100 py-3' style={{ background: '#00065e' }}>
                <BoxCenter>
                    <Typography variant="h4" component="div" sx={{ color: '#fff' }}>EasyBooky</Typography>
                </BoxCenter>
                {
                    (wgfAdminRoleControl()) && (
                        <ChangePortal />
                    )
                }
            </div>
            <div style={{ overflow: 'auto' }}>
                <SidebarCustomerCompany />
                <MenuList sx={{ p: 1 }}>
                    <div style={{ height: '50px'}}>
                        {
                            _compValues.parent && (<MenuItem onClick={handleDoneParent} style={styles.menuItem} >
                                <ListItemIcon>
                                    <ArrowBack sx={{ color: '#5a5a5a' }} />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography fontWeight={600}>Done</Typography>
                                </ListItemText>
                            </MenuItem>)
                        }
                    </div>

                    {
                        (appObjects.values.loginInfo) && (
                            items.map((item, index) => {
                                return (
                                    (item.isAccordion) ? (
                                        <Accordion
                                            key={index}
                                            expanded={expanded === ('panel-' + index)} onChange={handleChange(('panel-' + index))}
                                            sx={{
                                                boxShadow: 'none',
                                                '&.Mui-expanded': { margin: 0, background: 'transparent !important' },
                                                '&::before': { display: 'none', background: 'transparent !important' },
                                                background: 'transparent'
                                            }}>
                                            <AccordionSummary
                                                expandIcon={<KeyboardArrowDown sx={{ color: '#fff' }} />}
                                                aria-controls="panel1-content"
                                                sx={{
                                                    ...styles.menuItem, background: 'transparent !important',
                                                    '&.Mui-expanded': { margin: 0, background: 'transparent !important' },
                                                    '&::before': { display: 'none', background: 'transparent !important' }
                                                }}
                                            >
                                                {/*item.icon */}
                                                <Typography fontWeight={600} color='#fff'>{item.text}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails sx={{ pr: 0, pt: 0, }}>
                                                <List sx={{ pl: 3 }}>
                                                    {
                                                        appDatas && appDatas.sidebarItems.filter(f => f.parentRegisterId === item.id).map((itemp, indexp) => {
                                                            return (
                                                                <ListItem key={('c' - indexp)}
                                                                    disablePadding
                                                                    onClick={() => (handleButtonClick(item.newUrl))}
                                                                >
                                                                    <ListItemButton style={((window.location.pathname === itemp.url || window.location.pathname === '/' + itemp.url) ? styles.accordionMenuItemsActive : styles.accordionMenuItems)}>
                                                                        <Typography style={styles.accordionMenuItemsText}>{itemp.text}</Typography>
                                                                    </ListItemButton>
                                                                </ListItem>
                                                            )
                                                        })
                                                    }
                                                </List>
                                            </AccordionDetails>
                                        </Accordion>
                                    ) : ((item.isDivider === true ? (
                                        <Divider key={index} sx={{ mb: 2, mt: 3, color: '#fff' }} />
                                    ) : (
                                        <MenuItem key={index}
                                            onClick={() => (handleButtonClick(item.newUrl))}
                                            style={((window.location.pathname === item.url || window.location.pathname === '/' + item.url) ? styles.menuItemActive : styles.menuItem)} >
                                            <ListItemIcon>
                                                {/*item.icon */}
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography fontWeight={600} sx={{ fontSize: '0.85rem' }}>{item.text}</Typography>
                                            </ListItemText>
                                        </MenuItem>
                                    ))
                                    )
                                )
                            })
                        )
                    }
                </MenuList>
            </div>
            <div className='w-100 p-2' style={{ background: '#00065e', display: 'grid', gridTemplateColumns: 'auto 1fr auto' }}>
                <AccountCircle sx={{ width: '40px !important', color: '#fff' }} />
                <Box component={Link} to="/my-account">
                    <span className='text-light m-0 pt-2 ps-2 d-block'>{appObjects.values.loginInfo.name} {appObjects.values.loginInfo.surname}</span>
                </Box>
                <LawIconButton onClick={wgfLogout} icon={<PowerSettingsNew sx={{ color: '#fff' }} />} />
            </div>
        </div>
    );
    return (
        <Box
            component="nav"
            sx={{ width: { lg: props.drawerWidth }, flexShrink: { lg: 0 }, boxShadow: 'none', border: 'none' }}
            aria-label="sidebar folders"
        >
            {/* Mobil Sidebar için Drawer (#0b0b55) */}
            <Drawer
                variant="temporary"
                open={props.mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Mobil performans için
                }}
                sx={{
                    display: { xs: 'block', lg: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: props.drawerWidth, border: 'none', boxShadow: 'none', background: 'rgba(255,255,255,0.95)' },
                }}
            >
                {drawer}
            </Drawer>

            {/* Desktop Sidebar */}
            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: 'none', lg: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: props.drawerWidth, border: 'none', boxShadow: '8px 0px 17px -17px #00065e', background: '#fff' },
                }}
                open={props.mobileOpen}
            >
                {drawer}
            </Drawer>
        </Box>
    )
}
export default Sidebar;
