import Sales from "../../components/data/accountant/sale/Sales"
import Card from "../../components/ui/Card"

const SalesView=()=>{
    return(
        <Card>
            <Sales/>
        </Card>
    )
}
export default SalesView;