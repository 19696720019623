import { getMonthsArray, getYearsArray } from "../../../functions/lawFunctions";
import LawFormGrid from "../../../ui/LawFormGrid";
import LawInputNumber from "../../../ui/LawInputNumber";
import LawSelect from "../../../ui/LawSelect";
import LawTextarea from "../../../ui/LawTextarea";

const EmployeeWorkMontlyFormBase = ({ workState, setWorkState }) => {
    return (
        <>
            <div className="mb-3">
                <LawFormGrid>
                    <LawSelect label={'Month'} items={getMonthsArray()} state={workState} setState={setWorkState} fieldName={"month"} />
                    <LawSelect label={'Year'} items={getYearsArray(-1, 0)} state={workState} setState={setWorkState} fieldName={"year"} />
                </LawFormGrid>
            </div>
            <LawInputNumber label={'Total Hour'} state={workState} setState={setWorkState} fieldName={'totalWorkingHours'} />
            <LawTextarea propstate={[workState, setWorkState, 'description']} rows={2} label={'Description'} />
        </>
    )
}
export default EmployeeWorkMontlyFormBase;