import { MoreVert, PersonAdd, Send } from "@mui/icons-material";
import { Avatar, Button, IconButton, Menu, MenuItem } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import MessageGroupCreateEdit from "./MessageGroupCreateEdit";
import LawModal from "../../../ui/LawModal";
import { lawFetchPost } from "../../../functions/lawFetch";
import { wgfAdminRoleControl, wgfDateTimeFormat, wgfIsNotNull } from "../../../functions/lawFunctions";
import { appObjects } from "../../../../App";
import LawSelectUsersOrRoles from "../share/LawSelectUsersOrRoles";
import { ContxAccountData } from "../../../../pages/layouts/LayoutAccount";
import LawIconButton from "../../../ui/LawIconButton";

const MessageBody = ({ userId, isForMessagePage }) => {
    const { messageSelectedUser, messages, setMessages } = useContext(ContxAccountData);
    const [anchorEl, setAnchorEl] = useState(null);
    const [contactInfoModalShow, setContactInfoModalShow] = useState(false);
    const [selecteUsersModalShow, setSelectUsersModalShow] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState(null);
    const [message, setMessage] = useState('');
    const messageBodyRef = useRef();
    useEffect(() => {
        if (userId) {
            appObjects.messagePage.getContactInfo(userId);
        }
    }, [])
    useEffect(() => {
        setSelectedUsers(null);
        setSelectUsersModalShow(false);
        setContactInfoModalShow(false);
    }, [messageSelectedUser])

    useEffect(() => {
        if (messageBodyRef.current) {
            messageBodyRef.current.scrollTop = messageBodyRef.current.scrollHeight;
        }
        if (isForMessagePage === true) {
            //Sadece Message page de contacts lari cagirsin
            appObjects.messagePage.getContacts();
        }
    }, [messages])

    const handleShowContactInfo = () => {
        setContactInfoModalShow(true);
        setAnchorEl(null);
    }
    const editGroupCallback = () => {
        setContactInfoModalShow(false);
        if (messageSelectedUser) {
            appObjects.messagePage.getContactInfo(messageSelectedUser.contactId);
        }
    }
    const handleUserUnSelect = (id) => {
        setSelectedUsers(selectedUsers.filter(f => f.id !== id));
    }
    const postMessage = () => {
        if (wgfIsNotNull([message], true) === true) {
            if (messageSelectedUser) {
                lawFetchPost('messages/create-message-item', {
                    contactId: messageSelectedUser.contactId,
                    message,
                    bulkMessageUsersId: ((selectedUsers && selectedUsers.length > 0) ? selectedUsers.map(item => item.id).join(',') : '')
                }, null, null, null, null, null, false, (resp) => {
                    setMessage('');
                    setSelectedUsers(null);
                    if (resp && resp.data && resp.success === true) {
                        setMessages([...messages, resp.data]);
                    }
                })
            }
        }
    }

    appObjects.messagePage.signalRMessage = (data) => {
        if (data) {
            data = JSON.parse(data);
            if (appObjects.messagePage.selectedUser && appObjects.messagePage.selectedUser.contactId === data.contactId) {
                //Kendi mesajimi kontrol ettir. LoginInfo status'u null olarak gosuldugu icin localStorage'ten al
                setMessages([...messages, data]);
            }
            else {
                appObjects.messagePage.getContacts(); //Eger o an konsutugu kisiden gelmemis ise Contacts listesini update ettir
            }
        }
    }

    return (
        <>
            <div style={{ borderRadius: '10px', overflow: 'hidden' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center', padding: '10px 20px', background: '#fff' }}>
                    <div style={{ flex: 1 }}>
                        <div style={{ display: 'flex', width: '100%' }}>
                            {
                                (messageSelectedUser) && (
                                    <>
                                        <Avatar alt={messageSelectedUser.contactName} src="#" />
                                        <div style={{ paddingLeft: '20px' }}>
                                            <h5 style={{ margin: '0px', color: '#333' }}>{messageSelectedUser.contactName ?? '....'} </h5>
                                            <small style={{ color: '#333' }}>Status</small>
                                        </div>
                                    </>
                                )
                            }
                        </div>
                    </div>
                    <div style={{ width: '50px' }}>
                        {
                            (messageSelectedUser && wgfAdminRoleControl() === true) && (
                                <div>

                                    <LawIconButton
                                        aria-controls={Boolean(anchorEl) ? 'long-menu' : undefined}
                                        aria-expanded={Boolean(anchorEl) ? 'true' : undefined}
                                        aria-haspopup="true"
                                        onClick={(e) => setAnchorEl(e.currentTarget)}
                                    >
                                        <MoreVert />
                                    </LawIconButton>
                                    <Menu
                                        id="long-menu"
                                        MenuListProps={{
                                            'aria-labelledby': 'long-button',
                                        }}
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={(e) => setAnchorEl(null)}
                                        slotProps={{
                                            paper: {
                                                style: {
                                                    maxHeight: 200,
                                                    width: '20ch',
                                                },
                                            },
                                        }}
                                    >
                                        {
                                            (messageSelectedUser.isGroup === true) ? (
                                                <>
                                                    <MenuItem onClick={handleShowContactInfo}>
                                                        Groepsinfo
                                                    </MenuItem>
                                                </>
                                            ) : (
                                                <>
                                                    <MenuItem onClick={() => appObjects.goTo('/user')}>
                                                        Gebruiker
                                                    </MenuItem>
                                                </>
                                            )
                                        }
                                    </Menu>
                                </div>
                            )
                        }

                    </div>
                </div>
                <div ref={messageBodyRef} className="law-message-body">
                    {
                        messages.map((item) => {
                            return (
                                <div key={item.id} className={("item" + (item.senderId === appObjects.values.loginInfo.id ? " item-my " : ""))}>
                                    <div>
                                        <span className="sender">{(item.senderId === appObjects.values.loginInfo.id ? 'Mij' : item.senderName)}</span>
                                        <p>{item.message}</p>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <span>{wgfDateTimeFormat(item.date, 'dd.MM.yyyy HH:mm')}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div style={{ display: 'flex', width: '100%', position: 'relative' }}>
                    {
                        (selectedUsers && selectedUsers.length > 0) && (
                            <div className="law-message-selected-users">
                                {
                                    selectedUsers.map((item, index) => {
                                        return <Button key={index} onClick={() => handleUserUnSelect(item.id)} variant="outlined" color="error">{item.name}</Button>
                                    })
                                }
                            </div>
                        )
                    }
                    <div style={{ background: '#fff', width: 'calc(100% - 70px)', padding: '3px' }}>
                        <textarea id="law-message-textarea" rows={2} value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Uw bericht"></textarea>
                    </div>
                    <div style={{ width: '70px', background: '#fff', position: 'relative', height: '75px', display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
                        <LawIconButton style={{ position: 'absolute', background: '#fff', top: '-25px', right: '5px' }} aria-label="delete" onClick={() => { setSelectUsersModalShow(true) }}>
                            <PersonAdd />
                        </LawIconButton>
                        {
                            (message) && (
                                <LawIconButton aria-label="delete" size="large" onClick={postMessage}>
                                    <Send />
                                </LawIconButton>
                            )
                        }
                    </div>
                </div>
                {
                    (contactInfoModalShow && messageSelectedUser) && (
                        <LawModal state={contactInfoModalShow} setState={setContactInfoModalShow}>
                            <MessageGroupCreateEdit groupId={messageSelectedUser.contactId} callback={editGroupCallback} />
                        </LawModal>
                    )
                }
                {
                    (selecteUsersModalShow) && (
                        <LawModal state={selecteUsersModalShow} setState={setSelectUsersModalShow} header={"U kunt dit bericht ook naar mensen van uw keuze sturen."}>
                            <LawSelectUsersOrRoles isAddUser={true} selectedIdList={selectedUsers} setResponse={setSelectedUsers} />
                        </LawModal>
                    )
                }
            </div>
        </>
    )
}
export default MessageBody;