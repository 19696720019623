import { ButtonGroup, IconButton } from "@mui/material";
import Card from "../../components/ui/Card";
import { useEffect, useState } from "react";
import { ChevronRight } from "@mui/icons-material";
import { lawFetchGet } from "../../components/functions/lawFetch";
import UserCreate from "../../components/data/admin/users/UserCreate";
import LawDataTable from "../../components/ui/LawDataTable";
import LawStatus from "../../components/ui/LawStatus";
import { appObjects } from "../../App";
import LawIconButton from "../../components/ui/LawIconButton";

const UsersView = () => {
    const [users, setUsers] = useState([]);
    useEffect(() => {
        getUsers();
    }, [])
    const getUsers = () => {
        lawFetchGet('account/all', null, setUsers, [], null, null, false, null);
    }

    let columns = [
        {
            name: 'Name Surname',
            selector: (row) => (row.name + ' ' + row.surname),
            sortable: true,
        },
        {
            name: 'Email',
            selector: (row) => row.email,
            sortable: true,
        },
        {
            name: 'Role',
            selector: (row) => row.roleName,
            sortable: true,
        },
        {
            name: 'Status',
            cell: (row) => (
                <LawStatus status={row.status} statusGroup={'ActivePassiveDeleted'} />
            ),
        },
        {
            name: 'Action',
            width: '100px',
            cell: (row) => (
                <div className="law-dttb-actions">
                    <LawIconButton onClick={() => appObjects.goTo('/user?id=' + row.id)}>
                        <ChevronRight />
                    </LawIconButton>
                </div>
            ),
        }
    ];
    return (
        <Card>
            <LawDataTable
                toolbarItems={
                    <>
                        <UserCreate callback={getUsers} />
                    </>
                }
                dataState={users} setDataState={setUsers} columns={columns} searchFields={['id', 'name', 'surname', 'email', 'phone', 'roleName']} />
        </Card>
    )
}
export default UsersView;