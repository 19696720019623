
import { appObjects } from "../../App";
import { myfGetLoginToken, wgfConvertDatesToISO, wgfLoading } from "./lawFunctions";
import packageJson from '../../../package.json';

export const _appApiUrl = "https://api.leadshare.nl/api"; //"https://localhost:7027/api";  // "http://192.168.17.1/api"; 

export const lawFetchPost = (url, params, callback, setUseState, defaultValue, successCallback = null, errorCallback = null, isShowToast = true, callbackWithResponse = null) => {
    lawFetch(true, url, params, callback, setUseState, defaultValue, successCallback, errorCallback, isShowToast, callbackWithResponse);
}
export const lawFetchDelete = (url, id, callback, setUseState, defaultValue, successCallback = null, errorCallback = null, isShowToast = true, callbackWithResponse = null) => {
    lawFetch(true, url, { Id: id }, callback, setUseState, defaultValue, successCallback, errorCallback, isShowToast, callbackWithResponse);
}
export const lawFetchGet = (url, callback, setUseState, defaultValue, successCallback = null, errorCallback = null, isShowToast = true, callbackWithResponse = null) => {
    lawFetch(false, url, null, callback, setUseState, defaultValue, successCallback, errorCallback, isShowToast, callbackWithResponse);
}
/*
Axios post işleminde bir data birde header kısmı ile verileri backend'e post ederiz. Header kısmında apiKey bilgisi falan bulunur.
*/
/**
 * Axios Request işlemleri için ortak fonksiyon
 * @param {*} isPost 
 * @param {*} url 
 * @param {*} params 
 * @param {*} callback : Not callback parametre alamayacak, eğer geriye değer döndürülecekse bunu state ile yap çünkü parametre olduğunda bu sefer parametresizlerde sorun çıkmakta.
 * @param {*} setUseState 
 * @param {*} defaultValue 
 */
const lawFetch = async (isPost, url, params, callback, setUseState, defaultValue, successCallback = null, errorCallback = null, isShowToast = true, callbackWithResponse) => {
    try {
        myfGetLoginToken((loginToken) => {
            /*
            mode: 'no-cors', >>> bunu ekleme yoksa Fetch API ile post islemi yapilamiyordu
            */
            let _config = {
                method: "GET",
                cache: "no-cache", // default, no-cache, reload, force-cache, only-if-cached
                credentials: "same-origin", // include, *same-origin, omit
                headers: {
                    'accept': '*/*',
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    //"Access-Control-Allow-Credentials" : true
                },
                timeout: 10000
            };
            if (loginToken) {
                _config.headers['Authorization'] = `Bearer ${loginToken}`;
            }
            if (isPost === true) {
                _config.method = 'POST';
                _config['body'] = JSON.stringify(params);
            }
            console.log('>>>>>>>>>> params <<<<<<<<<<<<<<<<<');
            console.log(params);
            url = urlJoinDomain(url);
            console.log('>>>> url <<<<<');
            console.log(url);
            wgfLoading(true);
            fetch(url, _config).then(resp => resp.json()).then(response => {
                wgfLoading(false);
                if (response) {
                    if (response.message && isShowToast) {
                        appObjects.snackbar(response.message, response.success === true ? 'success' : 'warning');
                    }
                    if (callbackWithResponse) {
                        callbackWithResponse(response);
                    }
                    else {
                        lawFetchResponseAction(response, callback, setUseState, defaultValue, successCallback);
                    }
                }
            }).catch(e => {
                appObjects.disconnect();
                console.error('>>>fetch error>>>', e);
                lawFetchError(e, callback, setUseState, defaultValue, errorCallback);
                throw e;
            })
        })

    } catch (e) {
        console.log('Axios Error', e);
    }
}

export const urlJoinDomain = (url) => {
    if (url && url.toString().startsWith('/') === false) {
        url = '/' + url
    }
    let currentDomain=window.location.origin.includes('localhost')?packageJson.domainLocal:packageJson.domain;
    return currentDomain + 'api' + url;
}

export const myfAuthenticationCode = (phone, email, nameSurname, authCodeSetState) => {
    if (phone && email && nameSurname) {
        let _params = {
            nameSurname: nameSurname,
            phone: phone,
            email: email
        }
        lawFetchPost('account/send-authentication-code', _params, null, authCodeSetState, null, null, null, true);
    }
    else {
        //AAA : Not: bura icin snackbar olustuur veya mesaj dondur fSnackbarFunc('İstenilen alanları doldurunuz.', 'error');
    }
}
/**
 * Axios'tan donen response'lar için ortak fonksiyon
 * @param {*} response 
 */
const lawFetchResponseAction = (response, callback, setUseState, defaultValue, successCallback = null) => {
    var _isSetDefault = true;
    if (response) {
        _isSetDefault = false;
        /*Not aşağıda myfIsNotNull fonksiyonu kullanma çünkü array içinde array olunca sorun çıkıyor */
        if (response.data && setUseState) {
            setUseState(response.data);
        }
        if (callback) {
            callback();
        }
        if (response.success && response.success === true && successCallback) {
            successCallback();
        }
    }
    if (_isSetDefault === true) {
        if (defaultValue) {
            if (setUseState) {
                setUseState(defaultValue);
            }
            if (callback) {
                callback();
            }
        }
    }
}
export const lawFetchError = (e, callback, setUseState, defaultValue, errorCallback) => {
    if (e) {
        if (e.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(e.response.data);
            console.log(e.response.status);
            console.log(e.response.headers);
        } else if (e.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(e.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', e.message);
        }
        console.log(e.config);
        let _err = "Axios Error.";
        if (e.message) {
            _err += "   " + e.message;
        }
        if (e.response) {
            _err += "   " + e.response;
        }
        appObjects.snackbar('Bir sorun oluştu !!!' + _err, 'error');
        lawFetchResponseAction(null, callback, setUseState, defaultValue);
        if (errorCallback) {
            errorCallback();
        }
        myfSetError(_err);
        /*
        Aşağıdaki Axios hata tiplerini kullanıcıya göre açıklayarak göster
        >Axios Error Network Error === Servere erişilemedi...
        */
    }

}
export const myfSetError = (error) => {
    try {
        myfGetLoginToken((loginToken) => {
            let _params = {
                value: error
            }

            // Axios.post(_appApiUrl + '/app-data/set-error', _params, _config)
        })

    } catch (e) {

        console.error('ERROR', e.message);
    }
}