import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { useState } from "react";
import ReportDaily from "./ReportDaily";
import ReportYearly from "./ReportYearly";
import ReportInvoiceTypes from "./ReportInvoiceTypes";

const Reports = () => {
    const [tabValue, setTabValue] = useState("1");
    const handleTabChange = (e, value) => {
        setTabValue(value);
    }
    return (
        <>
            <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                        <Tab label="Daily" value="1" />
                        <Tab label="Yearly" value="2" />
                        <Tab label="Invoice Types" value="3" />
                        <Tab label="Sellers" value="4" />
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <h5>Daily</h5>
                    <ReportDaily />
                </TabPanel>
                <TabPanel value="2">
                    <h5>Yearly</h5>
                    <ReportYearly />
                </TabPanel>
                <TabPanel value="3">
                    <h5>Invoice Types</h5>
                    <ReportInvoiceTypes />
                </TabPanel>
                <TabPanel value="4">
                    <h5>Sellers</h5>
                </TabPanel>
            </TabContext>
        </>
    )
}
export default Reports;