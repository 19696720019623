import { useEffect, useState } from "react";
import { lawFetchGet } from "../../../functions/lawFetch";
import LawDataTable from "../../../ui/LawDataTable";
import { wgfDateTimeFormat } from "../../../functions/lawFunctions";
import { IconButton } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import LawStatus from "../../../ui/LawStatus";
import CompanyVehicleUsageCreate from "./CompanyVehicleUsageCreate";
import LawDelete from "../../../ui/LawDelete";
import LawIconButton from "../../../ui/LawIconButton";
const CompanyVehicleUsageTable = ({ vehicleId }) => {
    const [usages, setUsages] = useState([]);
    const getAll = () => {
        if (vehicleId) {
            lawFetchGet('companyVehicleUsage/all?vehicleId=' + vehicleId, null, setUsages, [], null, null, false, null);
        }
    }
    useEffect(()=>{
        getAll();
    }, [])
 
    let columns = [
        {
            name: 'Id',
            selector: (row) => row.id,
            sortable: true,
        },
        {
            name: 'Date',
            cell: (row) => (
                <span>{wgfDateTimeFormat(row.fromDate, 'dd.MM.yyyy')} / {wgfDateTimeFormat(row.toDate, 'dd.MM.yyyy')}</span>
            )
        },
        {
            name: 'Km/Hour',
            cell: (row) => (
                <span>{(row.usageHour ? (row.usageHour + ' hour') : (row.usageKm + ' km'))}</span>
            )
        },
        {
            name: 'Create',
            selector: (row) => wgfDateTimeFormat(row.createDate, 'dd.MM.yyyy HH:mm'),
            sortable: true,
        },
        {
            name: 'Status',
            cell: (row) => <LawStatus status={row.status} statusGroup={"ActivePassiveDeleted"} />,
            sortable: true,
        },
        {
            name: 'Action',
            width: '100px',
            cell: (row) => (
                <div className="law-dttb-actions">
                    <LawDelete deleteCallback={getAll} deleteData={row} tableName={'TblCompanyVehicleUsages'}/>
                    <LawIconButton onClick={() => { }}>
                        <ChevronRight />
                    </LawIconButton>
                </div>
            )
        }
    ];
    return (
        <>

            <LawDataTable columns={columns} dataState={usages} setDataState={setUsages} toolbarItems={
                <>
                    {
                        (vehicleId) && (
                            <CompanyVehicleUsageCreate vehicleId={vehicleId} callback={getAll} />
                        )
                    }
                </>
            } />
        </>
    )
}
export default CompanyVehicleUsageTable;