import { Alert, AlertTitle, Badge, Button, IconButton, Tooltip, Typography } from "@mui/material";
import MailIcon from '@mui/icons-material/Mail';
import { useContext, useEffect, useState } from "react";
import { ForwardToInbox, MailOutline } from "@mui/icons-material";
import { appObjects, ContxAppData } from "../../../../App";
import { wgfDateTimeFormat } from "../../../functions/lawFunctions";
import DataNotFound from "../../../ui/DataNotFound";
import LawDrawer from "../../../ui/LawDrawer";
import { ContxAccountData } from "../../../../pages/layouts/LayoutAccount";
import LawIconButton from "../../../ui/LawIconButton";
const NavbarMessages = () => {
    const { messageContacts } = useContext(ContxAccountData);
    const { appDatas } = useContext(ContxAppData);
    const [showMessages, setShowMessages] = useState(false);
    useEffect(() => {
        if (showMessages) {
            appObjects.messagePage.getContacts();
        }
    }, [showMessages]);
    return (
        <>
            <LawIconButton
                title="Messages"
                onClick={() => setShowMessages(true)}
                sx={{ color: '#fff' }}
            >
                {
                    (appDatas && appDatas.navbarCountMessages > 0) ? (
                        <Badge badgeContent={appDatas.navbarCountMessages} color="secondary">
                            <MailIcon />
                        </Badge>
                    ) : (
                        <MailIcon />
                    )
                }
            </LawIconButton>
            {
                (showMessages) && (


                    <>
                        <LawDrawer showState={showMessages} setShowState={setShowMessages} header={"Berichten"}
                            buttons={
                                <>
                                    <Button size="small" onClick={() => appObjects.goTo('/messages', () => setShowMessages(false))} variant="contained">Alle</Button>
                                </>
                            }
                        >
                            {
                                (messageContacts && messageContacts.length > 0) ? (
                                    messageContacts.map((item, index) => {
                                        return (
                                            <Alert
                                                key={index}
                                                icon={
                                                    <MailOutline sx={{ display: 'none' }} />
                                                }
                                                action={
                                                    <>
                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                            <LawIconButton
                                                                aria-label="close"
                                                                color="inherit"
                                                                onClick={() => appObjects.goTo(('/messages?contact=' + item.contactId), () => setShowMessages(false))}
                                                            >
                                                                <ForwardToInbox fontSize="inherit" />
                                                            </LawIconButton>
                                                        </div>
                                                    </>
                                                }
                                                sx={{ mb: 2 }}
                                                severity={item.type}
                                            >
                                                <AlertTitle sx={{ fontSize: 16, fontWeight: 600 }}>{item.contactName}</AlertTitle>
                                                <Typography sx={{ p: 0, fontSize: 16 }}>{item.lastMessage}</Typography>
                                                <Typography sx={{ fontSize: 12 }}>{wgfDateTimeFormat(item.lastMessageDate, 'dd.MM.yyyy HH:mm')}</Typography>
                                            </Alert>
                                        )
                                    })
                                ) : (<DataNotFound color={"#fff"} />)
                            }
                        </LawDrawer>
                    </>
                )
            }
        </>
    )
}
export default NavbarMessages;