import { Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { lawFetchGet } from "../../../functions/lawFetch";
import { wgfDateTimeFormat, wgfTextCropAndDots } from "../../../functions/lawFunctions";
import TicketCreate from "./TicketCreate";
import LawDataTable from "../../../ui/LawDataTable";
import LawStatus from "../../../ui/LawStatus";
import TicketShow from "./TicketShow";
const TableTickets = () => {
    const [tickets, setTickets] = useState([]);
    const getAll = () => {
        lawFetchGet('ticket/all-for-table', null, setTickets, [], null, null, null, null);
    }
    useEffect(() => {
        getAll();
    }, [])
    let columns = [
        {
            name: 'Id',
            selector: (row) => row.id,
            sortable: true,
        },
        {
            name: 'Name',
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: 'Created By',
            selector: (row) => row.createdByUserName,
            sortable: true,
        },
        {
            name: 'Target',
            cell: (row) => (
                <Tooltip title={row.targetUsers} arrow>
                    <span>{wgfTextCropAndDots(row.targetUsers, 24)}</span>
                </Tooltip>
            ),
        },
        {
            name: 'Status',
            cell: (row) => (
                <LawStatus status={row.status} statusGroup={'TicketStatus'} />
            )
        },
        {
            name: 'Create',
            selector: (row) => wgfDateTimeFormat(row.data, 'dd.MM.yyyy HH:mm'),
            sortable: true,
        },
        {
            name: 'Action',
            width: '100px',
            cell: (row) => (
                <div className="law-dttb-actions">
                    <TicketShow id={row.id} completedCallback={getAll} removeCallback={getAll} />
                </div>
            )
        }
    ];


    return (
        <LawDataTable
            toolbarItems={
                <>
                    <TicketCreate callback={getAll} />
                </>
            }
            columns={columns} dataState={tickets} setDataState={setTickets} searchFields={['id', 'name', 'createdByUserName', 'targetUsers']} />
    )
}
export default TableTickets;

