import './InvoiceTemplateStyle.css'
import { useEffect, useState } from "react";
import { lawFetchGet, lawFetchPost } from "../../functions/lawFetch";
import { wgfDateTimeFormat, wgfFileUrlToSrc } from '../../functions/lawFunctions';
import { Cached, Check, Image, Print, Undo, Warning } from '@mui/icons-material';
import { appObjects } from '../../../App';
import LawModal from '../../ui/LawModal';
import LawStatus from '../../ui/LawStatus';
import CreateInvoice from './customer_post_invoice/CreateInvoice';
import LawLogTimeline from '../../ui/LawLogTimeline';
import LawToolbar from '../../ui/LawToolbar';
import { lawRoles } from '../../lawConstants';
import LawDelete from '../../ui/LawDelete';
import LawIconButton from '../../ui/LawIconButton';
const _compValues = {
    roleIsCustomer: false
}
const InvoiceTemplate = ({ invoiceId, deleteEvent, deleteCallback, editCallback }) => {
    const [invoice, setInvoice] = useState(null);
    const [customerPostInvoiceShow, setCustomerPostInvoiceShow] = useState(false);
    const [review, setReview] = useState({ id: invoiceId, description: '' });
    const [reCreateShow, setReCrateShow] = useState(false);
    const getInvoice = () => {
        lawFetchGet('invoice/get?id=' + invoiceId, null, setInvoice, null, null, null, false, null);
    }
    useEffect(() => {
        getInvoice();
        _compValues.roleIsCustomer = appObjects.values.loginInfo.roleName === lawRoles.customer;
    }, [])
    const getUnitTypeName = (id) => {
        return appObjects.values.appDatas.unitTypes.find(f => f.value === id)?.text ?? '';
    }
    const handleCustomerPostInvoiceShow = () => {
        setCustomerPostInvoiceShow(true);
    }
    const sentToReview = () => {
        appObjects.dialog.yesNoWithDescription('Terugsturen', 'Als het record onjuist is, wordt het teruggestuurd om opnieuw te worden gemaakt.Moeten we doorgaan?', () => {
            lawFetchPost('invoice/sent-to-review', review, null, null, null, () => {
                getInvoice();
            }, null, true, null);
        }, null, review, setReview, 'description');
    }
    const setConfirm = (isConfirm, goToUrl) => {
        lawFetchGet(`invoice/${isConfirm ? 'confirm' : 'non-confirm'}?id=` + invoice.id, null, null, null, () => {
            if (editCallback) {
                editCallback();
            }
            if (goToUrl) {
                appObjects.goTo(goToUrl);
            }
            else {
                getInvoice();
            }
        }, null, false, null);
    }
    const handleConfirm = (isConfirm) => {
        setConfirm(isConfirm === true);
    }
    const handleReCreateShow = () => {
        setReCrateShow(true);
    }
    return (
        (invoice) && (
            <>
                {
                    (reCreateShow) ? (
                        <CreateInvoice postInvoiceId={invoice.postInvoiceId} />
                    ) : (
                        <>
                            <div style={{ padding: '1rem' }}>
                                <LawToolbar>
                                    <LawIconButton title={'print'}>
                                        <Print />
                                    </LawIconButton>
                                    <LawIconButton onClick={handleCustomerPostInvoiceShow} title={'Invoice document'}>
                                        <Image />
                                    </LawIconButton>
                                    {(_compValues.roleIsCustomer === false) && (
                                        <>
                                            <LawIconButton onClick={sentToReview} title={"Verzonden om te herzien"}>
                                                <Undo />
                                            </LawIconButton>
                                            <LawIconButton onClick={handleReCreateShow} title={"Opnieuw creëren"}>
                                                <Cached />
                                            </LawIconButton>
                                            <LawLogTimeline isIconButton={true} tableName={'TblInvoices'} registerId={invoice.id} />
                                            <LawDelete deleteData={invoice} tableName={'TblInvoices'} deleteCallback={deleteCallback} />
                                            <LawIconButton title={'confirm'} onClick={() => handleConfirm((invoice.status !== 100))}>
                                                {
                                                    (invoice.status == 100) ? (
                                                        <Warning color='error' />
                                                    ) : (
                                                        <Check color='success' />
                                                    )
                                                }
                                            </LawIconButton>
                                        </>
                                    )}

                                </LawToolbar>
                                <div className='d-flex mt-3'>
                                    <span className='me-2'>Status:</span> <LawStatus status={invoice.status} statusGroup={'InvoiceStatus'} />
                                    <span className=''></span>
                                </div>
                            </div>
                            <div style={{ background: '#ddd', padding: '30px 0px' }}>
                                <div class="invoice-template-container">
                                    <div class="invoice-header">
                                        <div class="invoice-logo">FACTUUR</div>
                                        <div class="invoice-company-info">
                                            <strong>{invoice.sellerName}</strong><br />
                                            <strong>Email:</strong>{invoice.sellerEmail}<br />
                                            <strong>Phone:</strong>{invoice.sellerPhone}<br />
                                            <strong>Fax:</strong>{invoice.sellerFax}<br />
                                            <strong>Address:</strong>{invoice.sellerAddress}
                                        </div>
                                    </div>

                                    <div class="invoice-section">
                                        <h3>Invoice Details</h3>
                                        <p><strong>Invoice No:{invoice.invoiceNumber}</strong><br />
                                            <strong>Date:</strong>  {wgfDateTimeFormat(invoice.invoiceDate, 'dd.MM.yyyy')}
                                        </p>
                                    </div>

                                    <div class="invoice-section">
                                        <h3>Billing To</h3>
                                        <p>{invoice.clientName}<br />
                                            {invoice.clientPhone}<br />
                                            {invoice.clientEmail}<br />
                                            {invoice.clientAddress}
                                        </p>
                                    </div>

                                    <div class="invoice-section">
                                        <table class="invoice-table">
                                            <thead>
                                                <tr>
                                                    <th>Description</th>
                                                    <th style={{ width: '180px' }}>Quantity</th>
                                                    <th style={{ width: '100px' }}>VAT</th>
                                                    <th style={{ width: '125px' }}>Unit Price</th>
                                                    <th style={{ width: '200px' }}>Discount</th>
                                                    <th style={{ width: '110px' }}>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    invoice.invoiceDetails.map((item, index) => (
                                                        <tr key={'invc-tr-' + index}>
                                                            <td>{item.detailItemName}</td>
                                                            <td>{item.quantity} {getUnitTypeName(item.unitId)}</td>
                                                            <td>%{item.vat}</td>
                                                            <td>€{item.unitPrice}</td>
                                                            <td>{`${item.discountType === 2 ? '€' : ''}${item.discountValue}${item.discountType === 1 ? '%' : ''}`}</td>
                                                            <td>€{item.totalAmount}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colspan="5" class="invoice-totals">Subtotal:</td>
                                                    <td>€{invoice.totalPrice}</td>
                                                </tr>
                                                <tr>
                                                    <td colspan="5" class="invoice-totals">VAT:</td>
                                                    <td>€{invoice.totalVat}</td>
                                                </tr>
                                                <tr>
                                                    <td colspan="5" class="invoice-totals">Total:</td>
                                                    <td>€{invoice.totalWithVat}</td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>

                                    <div class="invoice-footer">
                                        {invoice.id}
                                    </div>
                                </div>

                            </div>
                        </>
                    )
                }
                {
                    (customerPostInvoiceShow) && (
                        <LawModal state={customerPostInvoiceShow} setState={setCustomerPostInvoiceShow}>
                            <strong className='d-block'>Customer:{invoice.customerPostInvoice.customerName} </strong>
                            <strong className='d-block'>Company:{invoice.customerPostInvoice.companyName} </strong>
                            <span className='d-block'>Datum:{wgfDateTimeFormat(invoice.customerPostInvoice.createDate, 'dd.MM.yyyy HH:mm')} </span>
                            <span className='d-block'>Description:{invoice.customerPostInvoice.description} </span>
                            <img className='d-block w-100' src={wgfFileUrlToSrc(invoice.customerPostInvoice.filePath, true)} />
                        </LawModal>
                    )
                }
            </>
        )
    )
}
export default InvoiceTemplate;