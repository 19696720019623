import { Badge, Button } from "@mui/material";
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useContext, useState } from "react";
import NotificationItems from "./NotificationItems";
import { Link } from "react-router-dom";
import { appObjects, ContxAppData } from "../../../../App";
import LawDrawer from "../../../ui/LawDrawer";
import LawIconButton from "../../../ui/LawIconButton";

const NavbarNotifications = () => {
    const {  appDatas } = useContext(ContxAppData);
    const [showDrawer, setShowDrawer] = useState(false);

    return (
        <>
            <LawIconButton
            title="Messages"
                    onClick={() => setShowDrawer(true)}
                    size="small"
                >
                    {
                        (appDatas && appDatas.navbarCountNotifications > 0) ? (
                            <Badge badgeContent={appDatas.navbarCountNotifications} color="secondary">
                                <NotificationsIcon sx={{ color: '#fff' }} />
                            </Badge>
                        ) : (
                            <NotificationsIcon sx={{ color: '#fff' }} />
                        )
                    }
                </LawIconButton>
            {
                (showDrawer) && (
                    <>
                        <LawDrawer showState={showDrawer} setShowState={setShowDrawer} header={"Meldingen"} buttons={
                            <>
                                <Button size="small" onClick={() => appObjects.goTo('/notifications', () => { setShowDrawer(false) })} variant="contained" component={Link} to="/notifications">Alle</Button>
                            </>
                        }>
                            <div className="law-navbar-notifications">
                                {
                                    (showDrawer) && (
                                        <NotificationItems setNotificationDrawer={setShowDrawer} />
                                    )
                                }
                            </div>
                        </LawDrawer>
                    </>
                )
            }
        </>
    )
}
export default NavbarNotifications;