import { lawStatusActivePassive, wgGenders, wgWorkTypes, wgYesNo } from "../../../lawConstants";
import LawDatePicker from "../../../ui/LawDatePicker";
import LawFormGrid from "../../../ui/LawFormGrid";
import LawInput from "../../../ui/LawInput";
import LawInputEmail from "../../../ui/LawInputEmail";
import LawInputPhone from "../../../ui/LawInputPhone";
import LawInputPrice from "../../../ui/LawInputPrice";
import LawSelect from "../../../ui/LawSelect";
import LawTimePicker from "../../../ui/LawTimePicker";

const EmployeeFormBase = ({ employeeState, setEmployeeState }) => {
    return (
        <LawFormGrid>
            <LawInput propstate={[employeeState, setEmployeeState, 'name']} label={"Name"} />
            <LawInput propstate={[employeeState, setEmployeeState, 'sureName']} label={"SureName"} />
            <LawDatePicker state={employeeState} setState={setEmployeeState} fieldName={"birthDate"} isSetAutoDate={true} isBirthDate={true} label={"BirthDate"} />
            <LawSelect items={wgGenders} state={employeeState} setState={setEmployeeState} fieldName={"gender"} />
            <LawInputEmail state={employeeState} setSeller={setEmployeeState} fieldName={'email'} />
            <LawInputPhone state={employeeState} setState={setEmployeeState} fieldName={'phoneNumber'} />
            <LawInput propstate={[employeeState, setEmployeeState, 'address']} label={"Address"} />
            <LawSelect itemsUrl={"app/get-jobs-for-select"} state={employeeState} setState={setEmployeeState} fieldName={"jobId"} />
            <LawInput propstate={[employeeState, setEmployeeState, 'insuranceNo']} label={"InsuranceNo"} />
            <LawInput type={"number"} propstate={[employeeState, setEmployeeState, 'daysCountPeerWeek']} label={"DaysCountPeerWeek"} />
            <LawFormGrid>
                <LawInputPrice type={"number"} state={employeeState} setState={setEmployeeState} fieldName={'salleryHours'} label={"SalleryHours"} />
                <LawInputPrice type={"number"} state={employeeState} setState={setEmployeeState} fieldName={'SalleryMounthly'} label={"SalleryMounthly"} />
            </LawFormGrid>
            <LawFormGrid>
                <LawSelect items={wgWorkTypes} state={employeeState} setState={setEmployeeState} fieldName={'workType'} label={"WorkType"} />
                <LawSelect items={wgYesNo} state={employeeState} setState={setEmployeeState} fieldName={'workAtWeekend'} label={"WorkAtWeekend"} />
            </LawFormGrid>
            <LawInput type={"number"} max={20} propstate={[employeeState, setEmployeeState, 'childCount']} label={"ChildCount"} />
            <LawSelect items={lawStatusActivePassive} state={employeeState} setState={setEmployeeState} fieldName={"status"} label={"status"} />
            <div>
                <LawTimePicker time={employeeState} setTime={setEmployeeState} fieldName={'workStartTime'} label={"WorkStartTime"} />
                <LawTimePicker time={employeeState} setTime={setEmployeeState} fieldName={'workEndTime'} label={'workEndTime'} />
                <LawTimePicker time={employeeState} setTime={setEmployeeState} fieldName={'workShiftTime'} label={'workShiftTime'} />
            </div>
        </LawFormGrid>
    )
}
export default EmployeeFormBase;