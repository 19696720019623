import { Alert, AlertTitle, Button, IconButton, Typography } from "@mui/material";
import { useContext, useEffect } from "react";
import { Close } from "@mui/icons-material";
import { lawFetchGet } from "../../../functions/lawFetch";
import { wgfDateTimeFormat } from "../../../functions/lawFunctions";
import DataNotFound from "../../../ui/DataNotFound";
import { appObjects } from "../../../../App";
import { ContxAccountData } from "../../../../pages/layouts/LayoutAccount";
import LawIconButton from "../../../ui/LawIconButton";
const _notificationTypes = [
    {
        type: 1, name: 'success'
    },
    {
        type: 2, name: 'error'
    },
    {
        type: 3, name: 'info'
    }
]
const getNotificationType = (typeNo) => {
    let _f = _notificationTypes.find(f => f.type === typeNo);
    return _f.name;
}
const NotificationItems = ({ setNotificationDrawer }) => {
    const { notifications, setNotifications } = useContext(ContxAccountData);
    useEffect(() => {
        getAll();
    }, [])
    const getAll = () => {
        lawFetchGet('notifications/all', null, setNotifications, [], null, null, false, null);
    }
    const deleteNotify = (id) => {
        if (id) {
            lawFetchGet('notifications/delete?id=' + id, null, null, null, getAll, null, true, null)
        }
    }
    const goToUrl = (url) => {
        if (setNotificationDrawer) {
            setNotificationDrawer(false);
        }
        if (url) {
            appObjects.goTo(url, null);
        }
    }
    return (
        <>
            {
                (notifications && notifications.length > 0) ? (
                    notifications.map((item, index) => {
                        return (
                            <Alert
                                key={index}
                                action={
                                    <LawIconButton
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => { deleteNotify(item.id) }}
                                >
                                    <Close fontSize="inherit" />
                                </LawIconButton>
                                }
                                sx={{ mb: 2 }}
                                severity={getNotificationType(item.type)}
                            >
                                <AlertTitle>{item.header}</AlertTitle>
                                <Typography sx={{ p: 0 }}>{item.comment}</Typography>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: 12 }}>{wgfDateTimeFormat(item.date, 'dd.MM.yyyy HH:mm')}</Typography>
                                    {
                                        (item.goToUrl) && (
                                            <Button size="small" onClick={() => goToUrl(item.goToUrl)} variant="contained">Details</Button>
                                        )
                                    }
                                </div>
                            </Alert>
                        )
                    })
                ) : (
                    <DataNotFound />
                )
            }
        </>
    )
}
export default NotificationItems;

