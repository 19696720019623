import { useState } from "react";
import { wgfIsNotNull } from "../../../functions/lawFunctions";
import { lawFetchPost } from "../../../functions/lawFetch";
import CompanyFormFormBase from "./CompanyFormFormBase";
import LawCreateDataModal from "../../../ui/LawCreateDataModal";
import { Button } from "@mui/material";
const CompanyCreate = ({ customerId, callback, isWithoutModal }) => {
    const [company, setCompany] = useState({ customerId: customerId });
    const create = () => {
        if (wgfIsNotNull([company['customerId'], company['name'], company['phone'], company['email']], true) === true) {
            lawFetchPost('CustomerCompany/create', company, null, null, null, () => {
                setCompany({ customerId: customerId });
                callback();
            }, null, false, null);
        }
    }
    return (
        <>
            {
                (isWithoutModal === true) ? (
                    <div className="pt-3 pb-5" style={{maxWidth:'600px'}}>
                        <CompanyFormFormBase company={company} setCompany={setCompany} />
                        <div className="jf-center">
                            <Button onClick={create} variant="contained">Opslaan</Button>
                        </div>
                    </div>
                ) : (
                    <LawCreateDataModal createEvent={create}>
                        <CompanyFormFormBase company={company} setCompany={setCompany} />
                    </LawCreateDataModal>
                )
            }

        </>
    )
}
export default CompanyCreate;