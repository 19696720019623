import { IconButton } from "@mui/material";
import { useState } from "react";
import LawModal from "./LawModal";
import { Add, Save } from "@mui/icons-material";
import Card from "./Card";
import LawToolbar from "./LawToolbar";
import LawIconButton from "./LawIconButton";

const LawCreateDataModal = ({ children, style, createEvent, modalShowEvent }) => {
    const [modalShow, setModalShow] = useState(false);
    const handleModalShow = () => {
        setModalShow(true);
        if (modalShowEvent) {
            modalShowEvent();
        }
    }
    const handleCreate = () => {
        if (createEvent) {
            createEvent();
        }
        setModalShow(false);
    }
    return (
        createEvent && (
            <>
                <LawIconButton onClick={handleModalShow}><Add /></LawIconButton>
                {
                    (modalShow) && (
                        <LawModal state={modalShow} style={style} setState={setModalShow}>
                            <LawToolbar>
                                <LawIconButton onClick={handleCreate}>
                                    <Save />
                                </LawIconButton>
                            </LawToolbar>
                            <Card>
                                {children}

                            </Card>
                        </LawModal>
                    )
                }
            </>
        )
    )
}
export default LawCreateDataModal;