import { useState } from "react";
import LawModal from "../../../ui/LawModal";
import CreateInvoice from "./CreateInvoice";
import LawIconButton from "../../../ui/LawIconButton";
import { ChevronRight } from "@mui/icons-material";
import { lawFetchGet } from "../../../functions/lawFetch";

const OpenInvoiceShow = ({ id, createCallback }) => {
    const [modalShow, setModalShow] = useState(false);
    const handleShow = () => {
        setModalShow(true);
    }
    const handleClose = () => {
        //Kayit yapmadan cikis yaparsa ilgili Invoice tekrar baskalarina gosterilecek sekilde duzenle
        if (id) {
            lawFetchGet('customerPostInvoices/close?id=' + id, null, null, null, null, null, false, null);
        }
    }
    return (
        id && (
            <>
                <LawIconButton onClick={handleShow}>
                    <ChevronRight />
                </LawIconButton>
                {
                    modalShow && (
                        <LawModal style={{ maxWidth: 'calc(100% - 10px)', width: 'calc(100% - 10px)', height: '100vh' }} state={modalShow} setState={setModalShow} closeCallback={handleClose}>
                            <CreateInvoice postInvoiceId={id} callback={createCallback} />
                        </LawModal>
                    )
                }
            </>
        )
    )
}
export default OpenInvoiceShow;