import { PieChart } from "@mui/x-charts";
import LawDatePickerFromAndTo from "../../../ui/LawDatePickerFromAndTo";
import { useState } from "react";

const ReportInvoiceTypes = () => {
    const [fromDate, setFromDate] = useState(new Date((new Date()).setMonth(-1)));
    const [toDate, setToDate] = useState(new Date());
    return (
        <>
            <div className="d-flex justify-content-end">
                <LawDatePickerFromAndTo fromState={fromDate} setFromState={setFromDate} toState={toDate} setToState={setToDate} />
            </div>
            <PieChart
                series={[
                    {
                        data: [
                            { id: 0, value: 10, label: 'series A' },
                            { id: 1, value: 15, label: 'series B' },
                            { id: 2, value: 20, label: 'series C' },
                        ],
                    },
                ]}
                width={400}
                height={200}
            />
        </>
    )
}
export default ReportInvoiceTypes;