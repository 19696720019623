import { useEffect, useState } from "react";
import Card from "../../ui/Card";
import { lawFetchGet } from "../../functions/lawFetch";
import CompanyPage from "../accountant_customer/company/CompanyPage";

const CustomerViewForIndex = () => {
    const [companies, setCompanies] = useState([]);
    useEffect(() => {
        lawFetchGet('customerCompany/all', null, setCompanies, [], null, null, false, null);
    }, [])
    return (
        <>
            <Card style={{ padding: 0, overflow: 'hidden' }}>
                {
                    (companies) && (companies.map((item, index) => (
                        <div key={index}>
                            <CompanyPage companyId={item.id} />
                        </div>
                    )))
                }
            </Card>
        </>
    )
}
export default CustomerViewForIndex;