import { useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import { AccountCircle, Beenhere, CalendarMonth, Message, Notifications, Widgets } from "@mui/icons-material";
import LawDivider from "./LawDivider";
import { appObjects } from "../../App";
import BoxCenter from "./BoxCenter";
import styled from "styled-components";

const _buttonGroups = [
    {
        groupName: 'Generic',
        items: [
            {
                title: 'Messages',
                url: '/messages',
                icon: <Message sx={{ width: '35px !important' }}/>
            },
            {
                title: 'Tickets',
                url: '/tickets',
                icon: <Beenhere sx={{ width: '35px !important' }}/>
            },
            {
                title: 'Notifications',
                url: '/notifications',
                icon: <Notifications sx={{ width: '35px !important' }}/>
            },
            {
                title: 'Calendar',
                url: '/calendar',
                icon: <CalendarMonth sx={{ width: '35px !important' }}/>
            }
        ]
    },
    {
        groupName: 'Admin',
        items: [
            {
                title: '',
                url: '',
                icon: ''
            },
            {
                title: '',
                url: '',
                icon: ''
            },
            {
                title: '',
                url: '',
                icon: ''
            }
        ]
    },
    {
        groupName: 'Accountant',
        items: [
            {
                title: '',
                url: '',
                icon: ''
            },
            {
                title: '',
                url: '',
                icon: ''
            },
            {
                title: '',
                url: '',
                icon: ''
            }
        ]
    }

]
const BootstrapButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    width: '50%',
    borderRadius: '13px',
    maxWidth: '110px',
    color: '#999',
    padding: '6px 12px',
    border: 'none',
    lineHeight: 1.5,
    backgroundColor: '#f0f4f9',
    borderColor: '#f0f4f9',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        backgroundColor: '#ddd',
        borderColor: '#ddd',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#ccc',
        borderColor: '#ccc',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(61, 61, 61, 0.5)',
    },
});
const BootstrapButtonFixed = styled(Box)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    width: '45px',
    height:'45px',
    padding:'0px',
    display:'flex',
    alignItems:'center',
    alignContent:'center',
    justifyContent:'center',
    zIndex:'9999',
    cursor:'pointer',
    borderRadius: '45px',
    color: '#fff',
    border: 'none',
    lineHeight: 1.5,
    backgroundColor: '#00065e',
    borderColor: '#00065e',
    position: 'fixed',
     bottom: '30px', 
     right: '20px',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        backgroundColor: '#2631dd',
        borderColor: '#2631dd',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#00065e',
        borderColor: '#00065e',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(2, 2, 2, 0.68)',
    },
});
const LawPortalMenu = () => {
    const [show, setShow] = useState(true);
    const handleShowToggle = () => {
        setShow(!show);
    }
    const handleGo=(url)=>{
        setShow(false);
        appObjects.goTo(url);
    }
    
    return (
        <>
            <BootstrapButtonFixed onClick={handleShowToggle}>
                <Widgets />
            </BootstrapButtonFixed>
            <Modal open={show} onClose={handleShowToggle}>
                <div className="mnbtns-area">
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr auto', padding: '20px 10px', background: 'rgba(0,0,0,0.3)' }}>
                        <div>
                            <h4 className="text-light"><AccountCircle sx={{width:'35px !important'}}/> {appObjects.values.loginInfo.name} {appObjects.values.loginInfo.surname}</h4>
                        </div>
                        <div style={{ width: '70px' }}>
                        </div>
                    </div>
                    {
                        _buttonGroups.map((group, gindex) => {
                            return (
                                <div key={'prtl-grp-' + gindex}>
                                    <LawDivider textAlign={'center'} color={'#fff'} title={group.groupName} />
                                    <div style={{ width: '100%', display: 'flex', gap: '10px', maxWidth: '1000px', margin: '30px auto', flexWrap: 'wrap', alignContent: 'flex-start', justifyContent: 'center', alignItems: 'flex-start' }}>
                                        {
                                            group.items.map((item, index) => {
                                                return (
                                                    <BootstrapButton key={`prtl-grp-item-${gindex}-${index}`} onClick={()=>handleGo(item.url)}>
                                                        <div className="py-3">
                                                            <BoxCenter>
                                                                {item.icon}
                                                            </BoxCenter>
                                                            <div>
                                                                <span>{item.title}</span>
                                                            </div>
                                                        </div>
                                                    </BootstrapButton>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </Modal>
        </>
    )
}
export default LawPortalMenu;