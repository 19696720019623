import LawIconButton from "../../../ui/LawIconButton";
import { Beenhere } from "@mui/icons-material";
import { appObjects } from "../../../../App";
const LawNavbarTickets = () => {
    return (
        <>
            <LawIconButton
                title="Tickets"
                size="small"
                onClick={()=>appObjects.goTo('tickets')}
            >
                <Beenhere sx={{ color: '#fff' }} />
            </LawIconButton>
        </>
    )
}
export default LawNavbarTickets;