import { AccessTime } from "@mui/icons-material";
import { useEffect, useState } from "react";
import LawStatus from "../../../ui/LawStatus";
import LawDataTable from "../../../ui/LawDataTable";
import { lawFetchGet } from "../../../functions/lawFetch";
import EmployeeWorkMontlyCreate from "./EmployeeWorkMontlyCreate";
import { wgfDateTimeFormat, wgfValueToPriceText } from "../../../functions/lawFunctions";
import EmployeeWorkMontlyDetails from "./EmployeeWorkMontlyDetails";
const EmployeeWorkMontlyTable = ({ employeeId }) => {
    const [works, setWorks] = useState([]);
    const getAll = () => {
        if (employeeId) {
            lawFetchGet('EmployeeWorkMontly/all?employeeId='+employeeId, null, setWorks, [], null, null, false, null);
        }
    }
    useEffect(()=>{
        getAll();
    }, [])
    let columns = [
        {
            name: 'Id',
            selector: (row) => row.id,
            sortable: true,
        },
        {
            name: 'Mon/Year',
            width: '100px',
            selector: (row) => row.month+' / '+row.year,
            sortable: true,
        },
        {
            name: 'Hour',
            cell: (row) => <span><AccessTime/> {row.totalWorkingHours}</span>,
            sortable: true,
        },
        {
            name: 'Hourly Salary',
            cell: (row) => <span>{wgfValueToPriceText(row.salleryHours)}</span>,
            sortable: true,
        },
        {
            name: 'Monthly Salary',
            cell: (row) => <span>{wgfValueToPriceText(row.salleryMounthly)}</span>,
            sortable: true,
        },
        {
            name: 'Careta Date',
            cell: (row) => <span>{wgfDateTimeFormat(row.createDate,'dd.MM.yyyy')}</span>
        },
        {
            name: 'Status',
            cell: (row) => <LawStatus status={row.status} statusGroup={'ActivePassiveDeleted'} />
        },
        {
            name: 'Action',
            width: '100px',
            cell: (row) => (
                <div className="law-dttb-actions">
                    <EmployeeWorkMontlyDetails workId={row.id} deleteCallback={getAll}/>
                </div>
            )
        }
    ];
    return (
        <>
            <LawDataTable dataState={works}
                toolbarItems={
                    <>
                        <EmployeeWorkMontlyCreate employeeId={employeeId} createCallback={getAll} />
                    </>
                }
                setDataState={setWorks} columns={columns} />
        </>
    )
}
export default EmployeeWorkMontlyTable;