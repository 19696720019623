import React, { createContext, useContext, useEffect, useState } from 'react';
import { Box, CssBaseline, Toolbar } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';
import { appObjects, ContxAppData } from '../../App';
import { lawFetchGet } from '../../components/functions/lawFetch';
import HideOnScrollNavbar from '../../components/ui/HideOnScrollNavbar'
import Sidebar from '../../components/ui/Sidebar'
import LawSignalR from '../../components/signalr/LawSignalR'
import { wgfLoading, wgfObjectClone } from '../../components/functions/lawFunctions';
import LawPortalMenu from '../../components/ui/LawPortalMenu';
export const ContxAccountData = createContext();
/*
SignalR'daki function icerisnden state cagirilinca null gorunuyordu bu yuzden asagidaki property'leri de ekledim
>>> accountObjects.messagePage.selectedUser
>>> accountObjects.messagePage.messages

*/




const LayoutAccount = () => {
    const { appDatas, setAppDatas } = useContext(ContxAppData);
    const [messageSelectedUser, setMessageSelectedUser] = useState(null);
    const [messageUsers, setMessageUsers] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [messageContacts, setMessageContacts] = useState([]);
    const [messages, setMessages] = useState([]);

    const [mobileOpen, setMobileOpen] = useState(false);
    const navigate = useNavigate();

    appObjects.goTo = (url, callback) => {
        navigate(url);
        if (callback) {
            callback();
        }
        if (mobileOpen === true) {
            setMobileOpen(false);
        }
        /*
        >Eğer function kullanmayacaksan sunlari da kullanabilirsin
        <Button component={Link} to="/messages">Messages</Button>
        <Link to="/..."></Link>
        > ortak function calistirmak icin bu yontemi kullandim
        onClick={() => appObjects.goTo('/messages')}
        */
    }

    appObjects.goToWithViewIdentity = (viewIdentity, search) => {
        /*
          > Aynı ViewIdentity baska url'lerde de kullanilmis olabilir.
            >Bunu icin filter ile on eleme yapilsin
            >Eger birden fazla var ise Current URL'i alip ilk once ona gore Find() yapilsin
              >Bulunamaz ise pathname'ye en yakin olanini sec
        */
        let views = appDatas.sidebarItems.filter(f => f.portal === appObjects.values.loginInfo.portal && f.viewIdentity === viewIdentity);
        if (views.length > 1) {
            let c1 = views.find(f => f.url.includes(window.location.pathname));
            if (c1) {
                navigate(c1.url + search);
            }
            else {
                //
            }
        }
        else if (views.length === 1) {
            navigate(views[0].url + search);
        }
    }
    //useEffect(wgfLoginControl, [])
    wgfLoading(true);
    appObjects.messagePage.getContactInfo = (contactId) => {
        if (contactId) {
            lawFetchGet('messages/get-contact-info?contactId=' + contactId, null, null, null, null, null, false, (resp) => {
                if (resp && resp.data) {
                    setMessageSelectedUser(resp.data);
                    lawFetchGet('messages/get-messages?contactId=' + resp.data.contactId, null, null, null, null, null, false, (respm) => {
                        setMessages(respm ? respm.data ?? [] : []);
                    });
                }
                else {
                    setMessageSelectedUser(null);
                }
            });
        }
    }

    appObjects.messagePage.getContacts = () => {
        lawFetchGet('messages/contacts', null, setMessageContacts, [], null, null, false, null);
    }
    appObjects.messagePage.getNewMessageCount = () => {
        lawFetchGet('messages/new-message-count', null, null, null, null, null, false, (resp) => {
            if (resp && resp.data) {
                let datas = wgfObjectClone(appDatas);
                datas['navbarCountMessages'] = resp.data ?? 0;
                setAppDatas(datas);
            }
        });
    }

    /*
     appObjects.stickyNote.getCount = () => {
         lawFetchGet('stickyNote/count', null, null, null, null, null, false, (resp) => {
             if (resp && resp.data) {
                 let datas = wgfObjectClone(appDatas);
                 datas['navbarCountStickyNotes'] = resp.data ?? 0;
                 setAppDatas(datas);
             }
         });
     }
    */

    appObjects.notifications.getNotificationCount = () => {
        lawFetchGet('notifications/count', null, null, null, null, null, false, (resp) => {
            if (resp && resp.data) {
                let datas = wgfObjectClone(appDatas);
                datas['navbarCountNotifications'] = resp.data ?? 0;
                setAppDatas(datas);
            }
        });
    }

    useEffect(() => {
        appObjects.messagePage.selectedUser = messageSelectedUser;
    }, [messageSelectedUser])


    return (
        <ContxAccountData.Provider value={{
            appDatas, setAppDatas,
            notifications, setNotifications,
            messageContacts, setMessageContacts,
            messageSelectedUser, setMessageSelectedUser,
            messageUsers, setMessageUsers,
            messages, setMessages,
        }}>
            <Box sx={{ display: 'flex', height: '100vh' }}>
                <CssBaseline />
                <HideOnScrollNavbar drawerWidth={appObjects.drawerWidth} mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
                <Sidebar drawerWidth={appObjects.drawerWidth} mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        p: 1,
                        width: { sm: `calc(100vw - ${(appObjects.drawerWidth)}px)` },
                        maxWidth: '100%'
                    }}
                >
                    <Toolbar /> {/* Navbar ile içeriği hizalamak için */}
                    <Box sx={{ width: '100%', maxWidth: '1560px', margin: 'auto', marginTop: '50px', paddingBottom: '50px' }}>
                        <Outlet /> {/*Pages */}
                    </Box>
                </Box>
                {
                    (appObjects.values.loginInfo && appObjects.values.loginInfo.isLogin) && (
                        <LawSignalR />
                    )
                }
             {
                /*   <LawPortalMenu/> */
             }
            </Box>
        </ContxAccountData.Provider>
    );
};
export default LayoutAccount;