import { useContext, useState } from "react";
import Card from "../../ui/Card";
import Grid from "@mui/material/Grid2";
import { Box, Tab, Typography } from "@mui/material";
import { Mail, Notifications, StickyNote2 } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { appObjects, ContxAppData } from "../../../App";
import TableTickets from "../app/ticket/TableTickets";
import { Link } from "react-router-dom";
import TodoList from "../app/todolist/TodoList";
const AdminViewForIndex = () => {
    const { appDatas } = useContext(ContxAppData);
    const [tabValue, setTabValue] = useState('1');
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <>
            <Card style={{ backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', background: 'url(https://img.freepik.com/free-vector/abstract-decorative-wavy-mosaic-banner-design_1055-6517.jpg?t=st=1735376415~exp=1735380015~hmac=7d752e5ddf5c000ddc62db18ac6cc8ea8b4273b439bfb82ab356695858e4f132&w=1380)' }}>
                <Grid container spacing={{ xs: 2, md: 3, lg: 3 }}>
                    <Grid size={{ xs: 12, sm: 12, md: 6, lg: 8 }}>
                        <Grid container spacing={{ xs: 2, md: 3 }}>
                            <Grid size={{ xs: 12, sm: 6, md: 8 }}>
                                <h3 style={{ color: '#b1b1b1' }}>Welkom</h3>
                                <h5 style={{ color: '#b1b1b1' }}>{appObjects.values.loginInfo.name} {appObjects.values.loginInfo.surname}</h5>
                                <span>You have done 72% more sales today. Check your new badge in your profile.</span>
                            </Grid>
                            <Grid size={{ xs: 12, sm: 6, md: 4 }}>

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid size={{ xs: 12, sm: 12, md: 6, lg: 4 }}>
                        {
                            /*
                             (appDatas) && (
                                 <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', maxWidth: '540px', margin: 'auto' }}>
                                     <div style={{ flex: 1 }} onClick={() => appObjects.stickyNote.showDrawer(true)}>
                                         <StickyNote2 sx={{ color: 'green', fontSize: '40px' }} />
                                         <Typography>Notes</Typography>
                                         <Typography fontSize={40} fontWeight={600} color="#b1b1b1">{appDatas.navbarCountStickyNotes}</Typography>
                                     </div>
                                     <Box sx={{ flex: 1 }} component={Link} to="/messages">
                                         <Mail sx={{ color: '#ada9fe', fontSize: '40px' }} />
                                         <Typography>Messages</Typography>
                                         <Typography fontSize={40} fontWeight={600} color="#b1b1b1">{appDatas.navbarCountMessages}</Typography>
                                     </Box>
                                     <Box sx={{ flex: 1 }} component={Link} to="/notifications">
                                         <Notifications sx={{ color: '#fda702', fontSize: '40px' }} />
                                         <Typography>Notifications</Typography>
                                         <Typography fontSize={40} fontWeight={600} color="#b1b1b1">{appDatas.navbarCountNotifications}</Typography>
                                     </Box>
                                 </Box>
                             )
                            */
                        }
                    </Grid>
                </Grid>
            </Card>
            <Card style={{ marginTop: '30px' }}>
                <Grid container spacing={{ xs: 2, md: 3 }} sx={{ mt: 3 }}>
                    <Grid size={{ xs: 12, sm: 12, md: 12, lg: 8 }}>
                        <TabContext value={tabValue}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                                    {
                                        (appObjects.values.roleGroup.accountantLawyer) && (
                                            <Tab label="Tickets" value="1" />
                                        )
                                    }
                                </TabList>
                            </Box>
                            {
                                (appObjects.values.roleGroup.accountantLawyer) && (
                                    <TabPanel value="1">
                                        <TableTickets />
                                    </TabPanel>
                                )
                            }
                        </TabContext>
                    </Grid>
                    <Grid size={{ xs: 12, sm: 12, md: 12, lg: 4 }}>
                        <TodoList />
                    </Grid>
                </Grid>
            </Card>
        </>
    )
}
export default AdminViewForIndex;