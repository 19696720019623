import InputMask from 'react-input-mask';
import { wgfChangeState } from "../functions/lawFunctions";
import { InputAdornment, TextField } from '@mui/material';
import { Phone } from '@mui/icons-material';
/*
https://www.npmjs.com/package/react-input-mask#examples
*/
const LawInputPhone = ({ state, setState, fieldName, label }) => {
    const handleChange = (e) => {
        wgfChangeState(e.target.value, fieldName, state, setState);
    }
    // +31
    //+31999999999
    let tempVal = (fieldName ? state[fieldName] : state);
    tempVal = tempVal ? tempVal.replaceAll('+31', '') : tempVal;
    return (
        <div className='mb-3'>
            <InputMask onChange={handleChange} value={tempVal} mask="+31999999999" maskChar={'_'}>
                {() => (
                    <TextField
                        label={label ?? 'Telefoon'}
                        fullWidth
                        slotProps={{
                            input: {
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Phone color={(tempVal && !tempVal.includes('_') ? 'warning' : 'error')} />
                                    </InputAdornment>
                                ),
                            },
                        }}
                        size='small'
                    />
                )}
            </InputMask>
        </div>
    )
}
export default LawInputPhone;