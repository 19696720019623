import { IconButton } from "@mui/material";
import Card from "../../components/ui/Card";
import { AccountCircle, Logout, Save, TextSnippet } from "@mui/icons-material";
import { lawLanguages, lawStatusActivePassive } from "../../components/lawConstants";
import { useEffect, useState } from "react";
import LawInput from "../../components/ui/LawInput";
import ChangePassword from "../../components/data/admin/users/ChangePassword";
import { lawFetchGet, lawFetchPost } from "../../components/functions/lawFetch";
import LawRoleSelect from "../../components/ui/LawRoleSelect";
import LawSelect from "../../components/ui/LawSelect";
import { wgfAdminRoleControl, wgfGetQueryStringValue, wgfLogout } from "../../components/functions/lawFunctions";
import { appObjects } from "../../App";
import LawInputPhone from "../../components/ui/LawInputPhone";
import LawInputEmail from "../../components/ui/LawInputEmail";
import LawDisableTwoFactor from "../../components/data/app/two-factor/LawDisableTwoFactor";
import LawFormGrid from "../../components/ui/LawFormGrid";
import LawToolbar from "../../components/ui/LawToolbar";
import LawIconButton from "../../components/ui/LawIconButton";

const UserView = () => {
    const [userId] = useState(wgfGetQueryStringValue('id'));
    const [user, setUser] = useState({});
    useEffect(() => {
        if (userId) {
            lawFetchGet('account/get?userId=' + userId, null, setUser, {}, null, null, false, null);
        }
    }, [userId])
  
    const save = () => {
        lawFetchPost('account/edit', user, null, null, null, null, null, true, null);
    }
    const handleUserLogOut = () => {
        if (user && user.userId) {
            lawFetchGet('login/logout-for-user?id=' + user.userId);
        }
    }

    return (
        <Card>
            <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
                <AccountCircle sx={{ width: '60px !important' }} className="text-muted me-3" />
                <div>
                    <div>
                        <h3 style={{ margin: 0 }}>{user.name} {user.surname}</h3>
                        <small>{user.roleName}</small>
                    </div>
                </div>
            </div>
            {
                (user) && (

                    <>
                        <LawToolbar>
                            <LawIconButton onClick={save}><Save /></LawIconButton>
                            <LawDisableTwoFactor userId={user.userId} tooltipTitle={'Reset Two Factor'} />
                            <ChangePassword userId={user.userId} isMyPassword={false} />
                            <LawIconButton>
                                <TextSnippet />
                            </LawIconButton>
                            {
                                (appObjects.values.loginInfo && wgfAdminRoleControl() === true) ? (
                                    <LawIconButton onClick={handleUserLogOut}>
                                        <Logout />
                                    </LawIconButton>
                                ) : (
                                    <LawIconButton onClick={wgfLogout}>
                                        <Logout />
                                    </LawIconButton>
                                )
                            }
                        </LawToolbar>
                        <LawFormGrid>
                            <LawInput propstate={[user, setUser, 'name']} label={"Name"} />
                            <LawInput propstate={[user, setUser, 'surname']} label={"Surname"} />
                            <LawInputEmail state={user} setState={setUser} fieldName={'email'} />
                            <LawInputPhone state={user} setState={setUser} fieldName={'phone'} />
                            <LawSelect label={"Taal"} state={user} setState={setUser} fieldName={'language'} defaultValue={'nl'} items={lawLanguages} />
                            <LawRoleSelect state={user} setState={setUser} fieldName={'roleName'} defaultValue={user['roleName']} />
                            <LawSelect label={"Status"} state={user} setState={setUser} fieldName={'status'} defaultValue={1} items={lawStatusActivePassive} />
                        </LawFormGrid>
                    </>
                )
            }
        </Card>
    )
}
export default UserView;