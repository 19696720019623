import { useEffect, useState } from "react";
import { ChevronRight } from "@mui/icons-material";
import LawModal from "../../../ui/LawModal";
import EmployeeCreate from "./EmployeeCreate";
import LawDataTable from "../../../ui/LawDataTable";
import LawStatus from "../../../ui/LawStatus";
import { lawFetchGet } from "../../../functions/lawFetch";
import { wdfDynamicImportUrlParameter } from "../../../functions/lawFunctions";
import EmployeeDetails from "./EmployeeDetails";
import LawIconButton from "../../../ui/LawIconButton";

const EmployeesTable = ({ companyId, customerId }) => {
    const [employees, setEmployees] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [employeeModalShow, setEmployeeModalShow] = useState(false);
    const getAll = () => {
        let url = wdfDynamicImportUrlParameter(
            'CompanyEmployees/all',
            [
                { default: customerId, key: 'customerId' },
                { default: companyId, key: 'companyId' }
            ]);
        lawFetchGet(url, null, setEmployees, [], null, null, false, null);
    }
    useEffect(getAll, [])
    const handleSelectEmployee = (id) => {
        setSelectedEmployee(id);
        setEmployeeModalShow(true);
    }
    let columns = [
        {
            name: 'Id',
            selector: (row) => row.id,
            sortable: true,
        },
        {
            name: 'Name',
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: 'Surname',
            selector: (row) => row.sureName,
            sortable: true,
        },
        {
            name: 'Status',
            cell: (row) => (
                <LawStatus status={row.status} statusGroup={'ActivePassiveDeleted'} />
            )
        },
        {
            name: 'Action',
            width: '100px',
            cell: (row) => (
                <div className="law-dttb-actions">
                    <LawIconButton onClick={() => handleSelectEmployee(row.id)}>
                        <ChevronRight />
                    </LawIconButton>
                </div>
            ),
        }
    ];

    return (
        <>

            <LawDataTable dataState={employees}
                toolbarItems={
                    <>
                        <EmployeeCreate companyId={companyId} callback={getAll} />
                    </>
                }
                setDataState={setEmployees} columns={columns} searchFields={['id', 'name', 'surname']} />
            {
                (selectedEmployee && employeeModalShow) && (
                    <LawModal style={{ maxWidth: '1100px' }} state={employeeModalShow} setState={setEmployeeModalShow}>
                        <EmployeeDetails employeeId={selectedEmployee} />
                    </LawModal>
                )
            }
        </>
    )
}
export default EmployeesTable;