import { Close } from "@mui/icons-material";
import { Drawer, IconButton } from "@mui/material";
import LawIconButton from "./LawIconButton";

const LawDrawer = (props) => {
    const { showState, setShowState, header, navbarButtons } = props;
    const handleHide = () => {
        setShowState(false);
    }
    return (
        (showState) && (
            <Drawer anchor={'right'} open={showState}
                sx={{
                    '& .MuiDrawer-paper': {
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        width: '320px',
                    }
                }}
            >
                <div style={{ display: 'flex', padding: '10px', background: 'rgba(0,0,0,0.3)', borderBottom: '1px solid #ddd', flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center' }}>
                    <strong style={{ color: '#fff' }}>{header}</strong>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {navbarButtons}
                        <LawIconButton onClick={handleHide} sx={{ marginLeft: '10px' }}>
                            <Close fontSize="small" sx={{ color: 'yellow' }} />
                        </LawIconButton>
                    </div>
                </div>
                <div style={{ width: '100%', height: 'calc(100vh - 50px)', overflow: 'auto', padding: '10px' }}>
                    {props.children}
                </div>
            </Drawer>
        )
    )
}
export default LawDrawer;