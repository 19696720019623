import LawDatePicker from "./LawDatePicker"
import LawFormGrid from "./LawFormGrid";

const LawDatePickerFromAndTo = ({ fromState, setFromState, fromFieldName, fromLabel, toState, setToState, isSetAutoDate, toFieldName, toLabel }) => {
    return (

        <LawFormGrid>
            <LawDatePicker state={fromState} setState={setFromState} isSetAutoDate={isSetAutoDate ?? false} fieldName={fromFieldName} label={fromLabel} />
            <LawDatePicker state={toState} setState={setToState} isSetAutoDate={isSetAutoDate ?? false} fieldName={toFieldName} label={toLabel} />
        </LawFormGrid>
    )
}
export default LawDatePickerFromAndTo;