import { useEffect, useState } from "react";
import { lawFetchGet } from "../../../functions/lawFetch";
import LawListSearchBox from "../../../ui/LawListSearchBox";
import { Avatar, Button, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tab, Toolbar } from "@mui/material";
import { Check, CheckCircleOutline, Close, PanoramaFishEye, Share } from "@mui/icons-material";
import { lawRolesListWithLines } from "../../../lawConstants";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import LawButtonGroup from "../../../ui/LawButtonGroup";
import LawModal from "../../../ui/LawModal";
import BoxCenter from "../../../ui/BoxCenter";
import LawIconButton from "../../../ui/LawIconButton";
import LawToolbar from "../../../ui/LawToolbar";
/*
selectedIdString, selectedIdList >> Users verileri string veya Array olarak gonderilebilecek

*/
const LawSelectUsersOrRoles = ({ isAddUser, isAddRoles, renderState, selectedIdString, selectedIdList, setResponse, responseRunFunction }) => {
    const [modalShow, setModalShow] = useState(false);
    const [tabValue, setTabValue] = useState((isAddUser ? '1' : '2'));
    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState(() => lawRolesListWithLines());
    const getUsers = () => {
        if (isAddUser) {
            lawFetchGet('account/getall-for-message-list', null, setUsers, [], null, null, false, (resp) => {
                if (resp && resp.data) {
                    if (selectedIdString || selectedIdList) {
                        selectedControl(resp.data);
                    }
                    else {
                        setUsers(resp.data);
                    }
                }
            })
        }
    }
    useEffect(() => {
        getUsers();
        selectedControl(null);
    }, []);
    const selectedControl = (userList) => {
        if (isAddUser && userList) {
            setUsers(userList.map(item => {
                item.selected = (selectedIdString ? selectedIdString.includes(item.id) : (selectedIdList ? Boolean(selectedIdList.find(f => f.id === item.id)) : false));
                item['show'] = true;
                return item;
            }));
        }
        if (isAddRoles) {
            setRoles(lawRolesListWithLines().map(item => {
                item.selected = (selectedIdString ? selectedIdString.includes(item.value) : (selectedIdList ? Boolean(selectedIdList.find(f => f.value === item.value)) : false));
                return item;
            }));
        }
    }
    useEffect(() => {
        selectedControl(users)
    }, [renderState]);
    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };
    const handleSelect = (userId, roleName) => {
        let resp = [];
        if (isAddUser) {
            resp = users.map(item => {
                if (item.id === userId) {
                    item.selected = !item.selected;
                }
                return item;
            }).filter(f => f.selected === true)

        }
        if (isAddRoles) {
            let _f = roles.map(item => {
                if (item.value === roleName) {
                    item.selected = !item.selected;
                }
                return item;
            }).filter(f => f.selected === true);
            if (_f && _f.length > 0) {
                resp = resp.concat(_f);
            }
        }
        if (setResponse) {
            setResponse(resp);
        }
        if (responseRunFunction) {
            responseRunFunction(resp);
        }

    }
    const handleUserUnSelectUser = (id) => {
        handleSelect(id, null);
        /*
        setUsers(users.map(item => {
            if (item.id === id) {
                item.selected = false;
            }
            return item;
        }
        ));
        */
    }
    const handleUserUnSelectRole = (value) => {
        handleSelect(null, value);
        /*
        setRoles(roles.map(item => {
            if (item.value === value) {
                item.selected = false;
            }
            return item;
        }
        ));
        */
    }

    let _selectedRoles = roles.filter(f => f.selected);
    let _selectedUsers = users.filter(f => f.selected);
    return (
        <>
            <LawButtonGroup>
                <Button variant="outlined" onClick={() => setModalShow(!modalShow)} size="small"><Share size="small" sx={{ marginRight: '10px' }} /> Share</Button>
            </LawButtonGroup>
            {
                (_selectedRoles && _selectedRoles.length > 0 && isAddRoles) && (
                    <>
                        <span>Roles</span>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                            {
                                _selectedRoles.map((item, index) => {
                                    return <Button size="small" sx={{ margin: '5px' }} key={index} onClick={() => handleUserUnSelectRole(item.value)} variant="outlined" color="error"><Close size="small" /> {item.value}</Button>
                                })
                            }
                        </div>
                    </>
                )
            }
            {
                (_selectedUsers && _selectedUsers.length > 0 && isAddUser) && (
                    <>
                        <span>Users</span>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                            {
                                _selectedUsers.map((item, index) => {
                                    return <Button size="small" sx={{ margin: '5px' }} key={index} onClick={() => handleUserUnSelectUser(item.id)} variant="outlined" color="error"><Close size="small" /> {item.name}</Button>
                                })
                            }
                        </div>
                    </>
                )
            }
            {
                (modalShow) && (
                    <LawModal state={modalShow} setState={setModalShow}>
                        <LawToolbar
                            rightChilds={
                                (tabValue == '1' ? (
                                    <LawListSearchBox listState={users} setListState={setUsers} searchFields={['name']} />
                                ) : (
                                    <LawListSearchBox listState={roles} setListState={setRoles} searchFields={['text']} />
                                ))

                            }
                        >
                            <LawIconButton onClick={() => setModalShow(false)} icon={<Check />} />
                        </LawToolbar>
                        <TabContext value={tabValue}>
                            {
                                (isAddRoles && isAddUser) && (
                                    <div style={{ borderBottom: '1px solid #ccc' }}>
                                        <TabList onChange={handleChangeTab}>
                                            {
                                                (isAddUser) && (
                                                    <Tab label="User" value="1" />
                                                )
                                            }
                                            {
                                                (isAddRoles) && (
                                                    <Tab label="Roles" value="2" />
                                                )
                                            }
                                        </TabList>
                                    </div>
                                )
                            }
                            {
                                (isAddUser) && (
                                    <TabPanel value="1">
                                        <List sx={{ width: '100%' }}>
                                            {
                                                users.filter(f => (f.show === undefined || f.show === true)).map((item, index) => {
                                                    return (
                                                        <ListItem
                                                            key={('select-user-' + index)}
                                                            secondaryAction={
                                                                <LawIconButton onClick={() => handleSelect(item.id, null)} edge="end" aria-label="delete">
                                                                    {
                                                                        (item.selected) ? (
                                                                            <CheckCircleOutline />
                                                                        ) : (
                                                                            <PanoramaFishEye />
                                                                        )
                                                                    }
                                                                </LawIconButton>
                                                            }
                                                            sx={{ alignContent: 'start', alignItems: 'start' }}
                                                        >
                                                            <ListItemAvatar component={IconButton} sx={{ cursor: 'pointer', alignContent: 'start', alignItems: 'start' }}>
                                                                <Avatar alt={item.name} src="#" />
                                                            </ListItemAvatar>
                                                            <ListItemText primary={item.name} />
                                                        </ListItem>
                                                    )
                                                })
                                            }
                                        </List >
                                    </TabPanel>
                                )
                            }
                            {
                                (isAddRoles) && (
                                    <TabPanel value="2">
                                        <List sx={{ width: '100%' }}>
                                            {
                                                roles.filter(f => (f.show === undefined || f.show === true)).map((item, index) => {
                                                    return (
                                                        <ListItem
                                                            key={('select-role-' + index)}
                                                            secondaryAction={
                                                                <LawIconButton onClick={() => handleSelect(null, item.value)} edge="end" aria-label="delete">
                                                                    {
                                                                        (item.selected) ? (
                                                                            <CheckCircleOutline />
                                                                        ) : (
                                                                            <PanoramaFishEye />
                                                                        )
                                                                    }
                                                                </LawIconButton>
                                                            }
                                                            sx={{ alignContent: 'start', alignItems: 'start' }}
                                                        >
                                                            <ListItemAvatar component={IconButton} sx={{ cursor: 'pointer', alignContent: 'start', alignItems: 'start' }}>
                                                                <Avatar alt={item.text} src="#" />
                                                            </ListItemAvatar>
                                                            <ListItemText primary={item.text} />
                                                        </ListItem>
                                                    )
                                                })
                                            }
                                        </List >
                                    </TabPanel>
                                )
                            }
                        </TabContext>
                        <BoxCenter>

                        </BoxCenter>
                    </LawModal >
                )
            }

        </>
    )
}
export default LawSelectUsersOrRoles;
