import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { wgfChangeState } from '../functions/lawFunctions';
import { useEffect } from 'react';
const LawDatePicker = ({ state, setState, fieldName, label, isSetAutoDate, isBirthDate }) => {
    useEffect(() => {
        if (isSetAutoDate && !(fieldName ? state[fieldName] : state)) {
           // wgfChangeState((isBirthDate ? new Date(1980, 1, 1) : new Date()), fieldName, state, setState);
        }
    }, [state])
    const handleChange = (e) => {
        wgfChangeState(new Date(e), fieldName, state, setState);
    }
    return (
        <div className='mb-3'>
            <LocalizationProvider dateAdapter={AdapterDayjs} size="small">
                <DatePicker label={label} sx={{width:'100%'}} value={dayjs(fieldName?state[fieldName]:state)} onChange={handleChange} slotProps={{ textField: { size: 'small' } }} for format='DD-MM-YYYY' />
            </LocalizationProvider>
        </div>
    )
}
export default LawDatePicker;