import { Button, Tooltip, Typography } from "@mui/material"
import { wgPortalTypes } from "../lawConstants"
import LawModal from "./LawModal"
import { appObjects } from "../../App"
import { wgfSetLocalStorage } from "../functions/lawFunctions"
import LawIconButton from "./LawIconButton"

const ChangePortal = ({ showState, setShowState, closeCallback }) => {
    const handleChangePortal = (name) => {
        appObjects.values.loginInfo.portal = name;
        wgfSetLocalStorage("login-info", JSON.stringify(appObjects.values.loginInfo));
        appObjects.getAppDatas();
        window.location.href = `/index`;
    }
    let activePortal = appObjects.values.loginInfo ? wgPortalTypes.find(f => f.value == appObjects.values.loginInfo.portal)?.text : '';
    return (
        (showState === null || showState === undefined) ? (
            <>
                <h6 className="d-block text-center text-light mt-5">{activePortal}</h6>
                <div style={{ display: 'flex', flexDirection: 'row', overflow: 'auto', maxWidth: '100%', justifyContent: 'center', marginBottom: '10px', gap: '7px', overflow: 'auto' }}>
                    {
                        wgPortalTypes.filter(f => f.showChangePortal).map((item, index) => {
                            return (
                                <LawIconButton title={item.text} onClick={() => handleChangePortal(item.value)} key={'chc-prtl-' + index} sx={{ border: `2px solid ${appObjects.values.loginInfo.portal === item.value ? 'yellow' : '#fff'}`, color: (appObjects.values.loginInfo.portal === item.value ? 'yellow' : '#fff') }}>
                                    {item.icon}
                                </LawIconButton>
                            )
                        })
                    }
                </div>

            </>
        ) : (
            (showState === true) && (
                <LawModal state={showState} header={'Selecteer een portaal'} setState={setShowState} style={{ maxWidth: '600px' }} closeCallback={closeCallback}>
                    <div style={{ display: 'flex', overflow: 'auto', margin: 'auto', width: 'fit-content', flexWrap: 'wrap', alignContent: 'flex-start', justifyContent: 'center', alignItems: 'flex-start' }}>
                        {
                            wgPortalTypes.filter(f => f.showChangePortal).map((item, index) => {
                                return (

                                      <Button onClick={() => handleChangePortal(item.value)} key={'chc-prtl-' + index} variant={"outlined"} sx={{ width: '150px', minWidth: '150px', height: 110, margin: '7px' }}>
                                      <div>
                                          <div className='jf-center'>
                                              {item.icon}
                                          </div>
                                          <Typography sx={{ display: 'block', width: '100%', textAlign: 'center' }}>{item.text}</Typography>
                                      </div>
                                  </Button>
                                )
                            })
                        }
                    </div>
                </LawModal>
            )
        )

    )
}
export default ChangePortal;