import { AppBar, Toolbar, Slide, useScrollTrigger } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Tooltip from '@mui/material/Tooltip';
import { ArrowBack, Home, Refresh } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { appObjects } from '../../App';
import { wgfRoleControl } from '../functions/lawFunctions';
import { lawRoles } from '../lawConstants';
import NavbarCreateInvoice from '../data/customer/NavbarCreateInvoice';
import NavbarMessages from '../data/app/messages/NavbarMessages';
import NavbarNotifications from '../data/app/notifications/NavbarNotifications';
import LawNavbarCalendar from '../data/app/calendar/LawNavbarCalendar';
import LawNavbarStickyNotes from '../data/app/sticky/LawNavbarStickyNotes';
import LawIconButton from './LawIconButton';
import LawNavbarTickets from '../data/app/ticket/LawNavbarTickets';
function HideOnScroll(props) {
  const { children, window } = props;
  // Scroll tetikleyici; sayfa yukarı çıktığında göster, aşağı indiğinde gizle
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    threshold: 30,  // 30 piksel kayınca devreye girer
  });
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const HideOnScrollNavbar = (props) => {
  const navigate = useNavigate();
  const goToBack = () => {
    navigate(-1)
  }
  return (
    <>
      <HideOnScroll {...props}>
        <AppBar
          position="fixed"
          sx={{
            padding: '0px 0px',
            boxShadow: 'none',
            m: 0,
            background: 'transparent',
            width: { lg: `calc(100% - ${props.drawerWidth}px)` },
            ml: { lg: `${props.drawerWidth}px` },
          }}
        >
          <Toolbar sx={{ display: 'block', pt: '10px', width: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 10px', width: '98%', maxWidth: '1500px', borderRadius: '50px', margin: 'auto', backgroundColor: '#00065e' }}>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '7px', alignContent: 'center', alignItems: 'center' }}>
                <Tooltip title="Ga terug" arrow>
                  <LawIconButton
                    color="primary"
                    onClick={goToBack}
                    sx={{ display: { sm: 'none', xs: 'none', md: 'flex' } }}
                  >
                    <ArrowBack sx={{ color: '#fff', width: '24px !important' }} />
                  </LawIconButton>
                </Tooltip>
                <Tooltip title="Opnieuw laden" arrow>
                  <LawIconButton
                    color="primary"
                    onClick={() => window.location.href = window.location.href}
                    sx={{ display: { sm: 'none', xs: 'none', md: 'flex' } }}
                  >
                    <Refresh sx={{ color: '#fff' }} />
                  </LawIconButton>
                </Tooltip>
                <Tooltip title="Menu" arrow>
                  <LawIconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => { props.setMobileOpen(!props.mobileOpen) }}
                    sx={{ display: { lg: 'none' }, ml: '5px' }}
                  >
                    <MenuIcon sx={{ color: '#fff' }} />
                  </LawIconButton>
                </Tooltip>
                <Tooltip title="Startpagina" arrow>
                  <LawIconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => appObjects.goTo('/index')}
                  >
                    <Home sx={{ color: '#fff' }} />
                  </LawIconButton>
                </Tooltip>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '7px', alignContent: 'center', alignItems: 'center' }}>
                <LawNavbarStickyNotes />
                <LawNavbarCalendar />
                <NavbarMessages />
                <LawNavbarTickets />
                <NavbarNotifications />
              </div>
            </div>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      {
        (wgfRoleControl([lawRoles.customer])) && (

          <NavbarCreateInvoice />
        )
      }
    </>
  );
};

export default HideOnScrollNavbar;
