import { QrCode } from "@mui/icons-material";
import { appObjects } from "../../../../App";
import { lawFetchPost } from "../../../functions/lawFetch";
import { useState } from "react";
import LawIconButton from "../../../ui/LawIconButton";

const LawDisableTwoFactor = ({ userId, title }) => {
    const [buttonShow, setButtonShow] = useState(true);
    const handleDisable = () => {
        appObjects.dialog.yesNo('Two factor sıfırlanacaktır.', 'Kullanıcının two factor kodu sıfırlanacakatır.', () => {
            let params = { id: userId };
            console.log(params);
            lawFetchPost('TwoFactor/reset-two-factor', params, null, null, null, () => {
                setButtonShow(false);
            }, null, true, null);
        }, null)
    }
    return (
        buttonShow && (
            <LawIconButton color="error" onClick={handleDisable} title={title}>
                <QrCode />
            </LawIconButton>

        )
    )
}
export default LawDisableTwoFactor;