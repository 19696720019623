import { lawGenders, lawLanguages } from "../../../lawConstants";
import LawFormGrid from "../../../ui/LawFormGrid";
import LawInputEmail from "../../../ui/LawInputEmail";
import LawInputNameSurname from "../../../ui/LawInputNameSurname";
import LawInputPhone from "../../../ui/LawInputPhone";
import LawSelect from "../../../ui/LawSelect";

const UserBaseElements = ({ user, setUser }) => {
    return (
    <LawFormGrid>
         <LawInputNameSurname label={"Naam*"} state={user} setState={setUser} fieldName={'name'} />
            <LawInputNameSurname label={"Achternaam*"} state={user} setState={setUser} fieldName={'surname'} />
            <LawInputEmail state={user} setState={setUser} fieldName={'email'} label={'E-mail*'} />
            <LawInputPhone label={"Telefoon*"} state={user} setState={setUser} fieldName={'phone'} />
            <LawSelect label={"Geslacht"} state={user} setState={setUser} fieldName={"gender"} defaultValue={1} items={lawGenders} />
            <LawSelect label={"Taal"} state={user} setState={setUser} fieldName={"language"} defaultValue={'nl'} items={lawLanguages} />
    </LawFormGrid>
    )
}
export default UserBaseElements;