import { ChevronRight } from "@mui/icons-material";
import LawIconButton from "../../../ui/LawIconButton";
import { useState } from "react";
import Ticket from "./Ticket";
import LawModal from "../../../ui/LawModal";

const TicketShow = ({ id, removeCallback, completedCallback }) => {
    const [modalShow, setModalShow] = useState(false);
    const handleShow=()=>{
        setModalShow(true);
    }
    const handleRemove = () => {
        if (removeCallback) {
            removeCallback();
        }
        setModalShow(false);
    }
    
    return (
        id && (
            <>
                <LawIconButton onClick={handleShow}>
                    <ChevronRight />
                </LawIconButton>
                {
                    modalShow && (
                        <LawModal header={'Ticket'} style={{ maxWidth: '1000px',width:'96%' }} bodyStyle={{padding:'0px'}} state={modalShow} setState={setModalShow} >
                            <Ticket id={id} removeCallback={handleRemove} completedCallback={completedCallback} />
                        </LawModal>
                    )
                }
            </>
        )
    )
}
export default TicketShow;