import { useEffect, useState } from "react";
import { lawFetchGet, lawFetchPost } from "../../../functions/lawFetch";
import { wgfIsNotNull } from "../../../functions/lawFunctions";
import CompanyVehicleFormBase from "./CompanyVehicleFormBase";
import { IconButton } from "@mui/material";
import LawSelect from "../../../ui/LawSelect";
import { lawStatusActivePassive } from "../../../lawConstants";
import LawToolbar from "../../../ui/LawToolbar";
import { Save } from "@mui/icons-material";
import LawDelete from "../../../ui/LawDelete";
import LawFormGrid from "../../../ui/LawFormGrid";
import LawIconButton from "../../../ui/LawIconButton";
const CompanyVehicleEdit = ({ vehicleId, callback }) => {
    const [vehicle, setVehicle] = useState(null);
    useEffect(() => {
        if (vehicleId) {
            lawFetchGet('companyVehicle/get?id=' + vehicleId, null, setVehicle, null, null, null, false, null);
        }
    }, [])
    const handleSave = () => {
        if (vehicle && wgfIsNotNull([vehicle['id'], vehicle['code']], true) === true) {
            lawFetchPost('companyVehicle/edit', vehicle, null, null, null, () => {
                setVehicle(null);
                if (callback) {
                    callback();
                }
            }, null, true, null)
        }
    }
    const deleteCallback = () => {

    }
    return (
        (vehicle) && (
            <>
                <LawToolbar>
                    <LawIconButton onClick={handleSave}><Save color="primary" /> </LawIconButton>
                    <LawDelete deleteData={vehicle} tableName={'TblCompanyVehicles'} deleteCallback={deleteCallback} />
                </LawToolbar>
                <CompanyVehicleFormBase vehicleState={vehicle} setVehicleState={setVehicle} />
                <LawFormGrid>
                    <LawSelect items={lawStatusActivePassive} state={vehicle} setState={setVehicle} fieldName={'status'} label={'Status'}></LawSelect>
                </LawFormGrid>
            </>
        )
    )
}
export default CompanyVehicleEdit;