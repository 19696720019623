import { useState } from "react";
import { appObjects } from "../../../App";
import LawSelect from "../../ui/LawSelect";
import Button from '@mui/material/Button';
import { CloudUpload } from "@mui/icons-material";
import { wgfDateTimeFormat, wgfGetQueryStringValue, wgfIsNotNull, wgfObjectClone } from "../../functions/lawFunctions";
import { lawFetchPost } from "../../functions/lawFetch";
import LawTextarea from "../../ui/LawTextarea";
import VisuallyHiddenInput from "../../ui/VisuallyHiddenInput";
import { wgfImageResize } from "../../functions/imageResize";
import BoxCenter from "../../ui/BoxCenter";


const CreateInvoice = ({ callback }) => {
    const [invoice, setInvoice] = useState({ companyId: wgfGetQueryStringValue('companyId') });
    const handlePost = () => {
        if (wgfIsNotNull([invoice['companyId'], invoice['fileBase64']], true) === true) {
            lawFetchPost('CustomerPostInvoices/create', invoice, null, null, null, () => {
                setInvoice({});
                if (callback) {
                    callback();
                }

            }, null, true, null)
        }
    }
    const imageControl = (filePath) => {
        if (filePath) {
            return filePath.includes('.png') || filePath.includes('.jpg') || filePath.includes('.bmp')
        }
        return false;
    }
    const handleSelectFile = async (e) => {
        let file = e.target.files ? e.target.files[0] : null;
        if (file) {
            let _invoice = wgfObjectClone(invoice);
            _invoice['filePath'] = file.name;
            _invoice['fileDate'] = file.lastModifiedDate ? wgfDateTimeFormat(file.lastModifiedDate, 'yyyy-MM-dd HH:mm') : '';
            if (imageControl(file.name)) {
                const image = await wgfImageResize(file, 2000, 2000);
                _invoice['fileBase64'] = image;
            }
            else {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => _invoice['fileBase64'] = reader.result;
                reader.onerror = (error) => console.error('Base64 dönüşüm hatası:', error);
            }
            setInvoice(_invoice);
        }
    }
    return (
        <>
            <div className="m-auto" style={{maxWidth:'400px'}}>
                <LawSelect label={"Company"} itemsUrl={'customerCompany/get-for-select?customerId=' + appObjects.values.loginInfo.id} fieldName={'companyId'} state={invoice} setState={setInvoice} />
                <BoxCenter>
                    <Button
                        component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                        startIcon={<CloudUpload />}
                    >
                        Factuur uploaden
                        <VisuallyHiddenInput
                            type="file"
                            onChange={handleSelectFile}
                        />
                    </Button>
                </BoxCenter>
            </div>
            {
                (invoice && invoice['fileBase64']) && (
                    <>
                        {
                            (imageControl(invoice['fileName'])) && (
                                <img src={invoice['fileBase64']} className="d-bloc w-100" />
                            )
                        }
                        <LawTextarea label={'Description'} propstate={[invoice, setInvoice, 'description']} />
                        <div className="jf-center mt-5 mb-5">
                            <Button onClick={handlePost} variant="contained">Opslaan</Button>
                        </div>
                    </>

                )
            }
        </>
    )
}
export default CreateInvoice;