import { CalendarMonth } from "@mui/icons-material";
import { useState } from "react";
import LawModal from "../../../ui/LawModal";
import LawCalendar from "./LawCalendar";
import LawIconButton from "../../../ui/LawIconButton";

const LawNavbarCalendar = () => {
    const [show, setShow] = useState(false)
    return (
        <>
            <LawIconButton
                title="Calendar"
                onClick={() => setShow(true)}
                sx={{ color: '#fff' }}
            >
                <CalendarMonth />
            </LawIconButton>
            {
                (show) && (
                    <LawModal state={show} setState={setShow} style={{ maxWidth: '1100px', width:'96%' }}>
                        <LawCalendar />
                    </LawModal>
                )
            }
        </>
    )
}
export default LawNavbarCalendar;
