import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import LawInput from "../../../ui/LawInput";
import BoxCenter from "../../../ui/BoxCenter";
import { lawStatusActivePassive } from "../../../lawConstants";
import { wgfIsNotNull, wgfObjectClone } from "../../../functions/lawFunctions";
import { lawFetchGet, lawFetchPost } from "../../../functions/lawFetch";
import LawSelect from "../../../ui/LawSelect";
import LawSelectUsersOrRoles from "../share/LawSelectUsersOrRoles";

const MessageGroupCreateEdit = ({ groupId, callback }) => {
    const [group, setGroup] = useState({});
    const [users, setUsers] = useState([]);
    const getGroup = () => {
        if (groupId) {
            lawFetchGet('messages/get-group?id=' + groupId, null, setGroup, {}, null, null, false, null);
        }
    }
    useEffect(getGroup, [groupId]);
    const save = () => {
        let _group = wgfObjectClone(group);
        if (wgfIsNotNull([_group['name']], true) === true) {
            _group['usersId'] = users.filter(f => f.selected === true).map(item => item.id).join(',');
            lawFetchPost('/messages/create-edit-group', _group, null, null, null, () => {
                setGroup({});
                if (callback) {
                    callback();
                }
            }, null, true, null);
        }
    }

    return (
        <>
            <LawInput label={"Group Name"} propstate={[group, setGroup, 'name']} />
            <LawSelect label={'Status'} items={lawStatusActivePassive} state={group} setState={setGroup} fieldName={'state'} />
            <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                {
                    (group.usersId) ? (
                        <>
                            {/*<MessageSelectUsers renderState={group} selectedUsersIdString={group.usersId} setResponseUsers={setUsers} /> */}
                            <LawSelectUsersOrRoles isAddUser={true} renderState={group} selectedIdString={group.usersId} setResponse={setUsers} />
                        </>
                    ) : (
                        <>
                            {/*<MessageSelectUsers setResponseUsers={setUsers} /> */}
                            <LawSelectUsersOrRoles isAddUser={true} setResponse={setUsers} />
                        </>
                    )
                }
            </div>
            <BoxCenter>
                <Button onClick={save} variant="contained">Save</Button>
            </BoxCenter>
        </>
    )
}
export default MessageGroupCreateEdit;