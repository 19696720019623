import { useEffect, useState } from "react";
import { lawFetchGet } from "../../../functions/lawFetch";
import { wgfDateTimeFormat, wgfValueToPriceText } from "../../../functions/lawFunctions";
import LawToolbar from "../../../ui/LawToolbar";
import { IconButton } from "@mui/material";
import { ChevronRight} from "@mui/icons-material";
import LawModal from "../../../ui/LawModal";
import LawDelete from "../../../ui/LawDelete";
import LawIconButton from "../../../ui/LawIconButton";
const EmployeeWorkMontlyDetails = ({ workId, deleteCallback }) => {
    const [modalShow, setModalShow] = useState(false);
    const [work, setWork] = useState(null);
    useEffect(() => {
        if (workId && modalShow) {
            lawFetchGet('EmployeeWorkMontly/get?id=' + workId, null, setWork, null, null, null, false, null);
        }
    }, [modalShow])
    const handleShow = () => {
        setModalShow(true);
    }
    const deleteCallback2 = () => {
        setModalShow(false);
        if (deleteCallback) {
            deleteCallback();
        }
    }
    return (
        <>
            <LawIconButton onClick={handleShow}>
                <ChevronRight />
            </LawIconButton>
            {
                (work && modalShow) && (
                    <LawModal state={modalShow} setState={setModalShow}>
                        <LawToolbar>
                            <LawDelete deleteData={work} tableName={'TblCompanyEmployeeWorkMontly'} deleteCallback={deleteCallback2} />
                        </LawToolbar>
                        <span className="d-block">Create Date : {wgfDateTimeFormat(work.createDate)}</span>
                        <span className="d-block">Month / Year : {work.month}/{work.year}</span>
                        <span className="d-block">Total hour : {work.totalWorkingHours} uur</span>
                        <span className="d-block">Salary hour : {wgfValueToPriceText(work.salleryHours)}</span>
                        <span className="d-block">Salary monthly : {wgfValueToPriceText(work.salleryMounthly)}</span>
                        <span className="d-block">Description:</span>
                        <span className="d-block">{work.descripton}</span>
                    </LawModal>
                )
            }
        </>
    )
}
export default EmployeeWorkMontlyDetails;