const LawToolbar = ({ children, rightChilds, searchBox }) => {

    return (
        <div style={{ width: '100%', maxWidth: '100%', overflow: 'auto' }} className="mb-4">
            <div className="law-toolbar">
                <div>
                    {children}
                </div>
                {
                    rightChilds && (
                        <div>
                            {rightChilds}
                        </div>
                    )
                }
                {
                    searchBox && (
                        <div>
                            {searchBox}
                        </div>
                    )
                }
            </div>
        </div>
    )
}
export default LawToolbar;