import { Delete } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import Slide from '@mui/material/Slide';
import { forwardRef, useState } from "react";
import { appObjects } from "../../App";
import { wgfChangeState } from "../functions/lawFunctions";
import LawTextarea from "./LawTextarea";
import { lawFetchPost } from "../functions/lawFetch";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const LawDialogYesNo = ({ title, description, yesCallback, noCallback }) => {
    return (
        <Dialog
            open={true}
            fullWidth={true}
            maxWidth={'sm'}
            TransitionComponent={Transition}
            keepMounted
            onClose={noCallback}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle><Delete /> {title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {description}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={yesCallback} color="primary">Ja</Button>
                <Button onClick={noCallback} color="error">Nee</Button>
            </DialogActions>
        </Dialog>
    )
}
export const LawDialogYesNoWithDescription = ({ title, description, yesCallback, noCallback, descriptionState, setDescriptionState, descriptionFieldName }) => {
    const handleChangeDescription = (e) => {
        wgfChangeState(e.target.value, descriptionFieldName, descriptionState, setDescriptionState);
    }
    return (
        <Dialog
            open={true}
            fullWidth={true}
            maxWidth={'sm'}
            TransitionComponent={Transition}
            keepMounted
            onClose={noCallback}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle><Delete /> {title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {description}
                </DialogContentText>
                <textarea className="form-control mt-3 mb-5" style={{ resize: 'none' }} rows={5} placeholder="Geef ook de reden op!" onChange={handleChangeDescription}></textarea>
            </DialogContent>
            <DialogActions>
                <Button onClick={yesCallback} color="primary">Ja</Button>
                <Button onClick={noCallback} color="error">Nee</Button>
            </DialogActions>
        </Dialog>
    )
}
export const LawDialogDelete = ({ yesCallback, noCallback }) => {
    return (
        <Dialog
            open={true}
            fullWidth={true}
            maxWidth={'sm'}
            TransitionComponent={Transition}
            keepMounted
            onClose={noCallback}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle><Delete />De registratie zal worden verwijderd.</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    De door u gekozen registratie zal worden verwijderd. Wilt u doorgaan?
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button onClick={yesCallback} color="primary">Ja</Button>
                <Button onClick={noCallback} color="error">Nee</Button>
            </DialogActions>
        </Dialog>
    )
}
const LawDialogChangeStatus = ({ yesCallback, noCallback, tableName, id, title, description }) => {
    const [data, setData] = useState({ tableName, id });
    const handleUpdateStatus = () => {
        lawFetchPost('app/update-status', data, null, null, null, () => {

        }, null, true, null)
        if (yesCallback) {
            yesCallback();
        }
    }
    return (
        <Dialog
            open={true}
            fullWidth={true}
            maxWidth={'sm'}
            TransitionComponent={Transition}
            keepMounted
            onClose={noCallback}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle><Delete />{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {description}
                </DialogContentText>
                <div className="d-block mt-3 mb-5">
                    <LawTextarea label={'Description'} propstate={[data, setData, 'description']} />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleUpdateStatus} color="primary">Ja</Button>
                <Button onClick={noCallback} color="error">Nee</Button>
            </DialogActions>
        </Dialog>
    )
}
export const LawDialogDelete2 = ({ yesCallback, noCallback, tableName, id }) => {
    return <LawDialogChangeStatus yesCallback={yesCallback} noCallback={noCallback} tableName={tableName} id={id} title="" description="" />
}
export const LawDialog = ({ dialogState, setDialogState }) => {
    appObjects.dialog.yesNo = (title, description, yesCallback, noCallback) => {
        setDialogState({
            title: title,
            description: description,
            yesCallback: () => {
                if (yesCallback) {
                    yesCallback();
                }
                setDialogState(null);
            },
            noCallback: () => {
                if (noCallback) {
                    noCallback();
                }
                setDialogState(null);
            },
            type: 1
        })
    }
    appObjects.dialog.yesNoWithDescription = (title, description, yesCallback, noCallback, descriptionState, setDescriptionState, descriptionFieldName) => {
        setDialogState({
            title: title,
            description: description,
            yesCallback: () => {
                if (yesCallback) {
                    yesCallback();
                }
                setDialogState(null);
            },
            noCallback: () => {
                if (noCallback) {
                    noCallback();
                }
                setDialogState(null);
            },
            type: 2,
            descriptionState,
            setDescriptionState,
            descriptionFieldName
        })
    }
    appObjects.dialog.delete = (yesCallback, noCallback) => {
        setDialogState({
            yesCallback: () => {
                if (yesCallback) {
                    yesCallback();
                }
                setDialogState(null);
            },
            noCallback: () => {
                if (noCallback) {
                    noCallback();
                }
                setDialogState(null);
            },
            type: 3
        })
    }
    let dialog = null;
    if (dialogState) {
        if (dialogState.type == 1) {
            dialog = <LawDialogYesNo description={dialogState.description} noCallback={dialogState.noCallback} yesCallback={dialogState.yesCallback} title={dialogState.title} />;
        }
        else if (dialogState.type == 2) {
            dialog = <LawDialogYesNoWithDescription description={dialogState.description} noCallback={dialogState.noCallback} yesCallback={dialogState.yesCallback} title={dialogState.title} descriptionState={dialogState.descriptionState} setDescriptionState={dialogState.setDescriptionState} descriptionFieldName={dialogState.descriptionFieldName} />;
        }
        else if (dialogState.type == 3) {
            dialog = <LawDialogDelete noCallback={dialogState.noCallback} yesCallback={dialogState.yesCallback} />;
        }
    }
    return dialog;
}
export default LawDialog;