import { Info } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import { useState } from "react";
import LawModal from "./LawModal";
import LawIconButton from "./LawIconButton";

const LawLogTimeline = ({ tableName, registerId, isIconButton }) => {
    const [modalShow, setModalShow] = useState(false);
    const hadleShow = () => {
        setModalShow(true);
        //belirtilen kayitlari cek
    }
    return (
        <>
            {
                (isIconButton === true) ? (
                    <LawIconButton onClick={hadleShow}>
                        <Info />
                    </LawIconButton>
                ) : (
                    <Button onClick={hadleShow}>
                        <Info />
                    </Button>
                )
            }
            {
                (modalShow) && (
                    <LawModal state={modalShow} setState={setModalShow}>
                        <div class="tmln-3-container">
                            <div class="tmln-3-block tmln-3-block-right">
                                <div class="tmln-3-marker"></div>
                                <div class="tmln-3-content">
                                    <h6>Action name</h6>
                                    <span>01.01.2001 01:00 - Create User Name</span>
                                    <p>Description</p>
                                </div>
                            </div>
                        </div>
                    </LawModal>
                )
            }
        </>
    )
}
export default LawLogTimeline;