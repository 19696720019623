import { Box, IconButton, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Card from "../../components/ui/Card";
import { Forum, People } from "@mui/icons-material";
import MessageBody from "../../components/data/app/messages/MessageBody";
import LawModal from "../../components/ui/LawModal";
import { useContext, useEffect, useState } from "react";
import { appObjects } from "../../App";
import { useSearchParams } from "react-router-dom";
import MessagesSidebar from "../../components/data/app/messages/MessagesSidebar";
import BoxCenter from "../../components/ui/BoxCenter";
import { ContxAccountData } from "../layouts/LayoutAccount";
import LawIconButton from "../../components/ui/LawIconButton";

const MessagesView = () => {
    const { messageSelectedUser } = useContext(ContxAccountData);
    const [showModalUsers, setShowModalUsers] = useState(false);
    const [searchParams] = useSearchParams();
    useEffect(() => {
        appObjects.messagePage.getContactInfo(searchParams.get("contact"));
        setShowModalUsers(false);
    }, [searchParams])
    
    return (
        <>
            <Grid container spacing={2}>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 4 }} sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' } }}>
                    <Card style={{ height: 'calc(100vh - 175px)', maxHeight: { sm: '500px' }, overflow: 'auto', background: '#fff' }}>
                        <MessagesSidebar />
                    </Card>
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 8 }}>
                    <Box sx={{ position: 'relative' }}>
                        <Box sx={{ display: { lg: 'none', xs: 'block', sm: 'block', md: 'block' }, position: 'absolute', width: '40px', height: '40px', background: '#fff', right: '20px', top: '-20px', borderRadius: '30px' }}>
                            <LawIconButton variant="primary" aria-label="delete" onClick={() => setShowModalUsers(true)}>
                                <People />
                            </LawIconButton>
                        </Box>
                    </Box>
                    {
                        (messageSelectedUser) ? (
                            <MessageBody isForMessagePage={true} />
                        ) : (
                            <Box sx={{ display: 'flex', justifyContent: 'center', background:'#fff', alignItems:'center', alignContent:'center', width: '100%', borderRadius:'10px', height: 'calc(100vh - 175px)' }}>
                                <Box>
                                    <BoxCenter>
                                        <Forum sx={{width:'70px !important', color:'#ddd'}} />
                                    </BoxCenter>
                                    <Typography sx={{fontSize:'18px'}}>Selecteer de gebruiker aan wie u een bericht stuurt.</Typography>
                                </Box>
                            </Box>
                        )
                    }

                </Grid>
            </Grid>
            {
                (showModalUsers) && (
                    <LawModal state={showModalUsers} setState={setShowModalUsers} style={{ width: '100%', maxWidth: '480px' }}>
                        <MessagesSidebar />
                    </LawModal>
                )
            }
        </>
    )
}
export default MessagesView;

