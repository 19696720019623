import { ZoomIn, ZoomOut, ZoomOutMap } from "@mui/icons-material";
import {
    TransformWrapper,
    TransformComponent,
    useControls,
} from "react-zoom-pan-pinch";
import LawToolbar from "./LawToolbar";
import LawIconButton from "./LawIconButton";

const Controls = () => {
    const { zoomIn, zoomOut, resetTransform } = useControls();
    return (
        <div style={{ width: 'calc(100% - 30px)', margin:'auto' }}>
            <LawToolbar>
                <LawIconButton sx={{ background: 'transparent !important' }} onClick={() => zoomIn()}>
                    <ZoomIn sx={{ color: '#fff' }} />
                </LawIconButton>
                <LawIconButton sx={{ background: 'transparent !important' }} onClick={() => zoomOut()}>
                    <ZoomOut sx={{ color: '#fff' }} />
                </LawIconButton>
                <LawIconButton sx={{ background: 'transparent !important' }} onClick={() => resetTransform()}>
                    <ZoomOutMap sx={{ color: '#fff' }} />
                </LawIconButton>
            </LawToolbar>
        </div>
    );
};

const ImageZoom = ({ src, height }) => {
    return (
        <TransformWrapper
            initialScale={1}
            initialPositionX={200}
            initialPositionY={100}
        >
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <>
                    <Controls />
                    <TransformComponent wrapperStyle={{ height: height ?? '800px', width: '100%' }}>
                        <img src={src} alt="test" style={{ maxWidth: '100%', width: '100%' }} />
                    </TransformComponent>
                </>
            )}
        </TransformWrapper>
    )
}
export default ImageZoom;