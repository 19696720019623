import { useState } from "react";
import EmployeeWorkMontlyFormBase from "./EmployeeWorkMontlyFormBase";
import { lawFetchPost } from "../../../functions/lawFetch";
import LawSelect from "../../../ui/LawSelect";
import { wdfDynamicImportUrlParameter } from "../../../functions/lawFunctions";
import LawCreateDataModal from "../../../ui/LawCreateDataModal";
const _compValues = {
    year: (new Date()).getFullYear(),
    month: (new Date()).getMonth() + 1
}
const EmployeeWorkMontlyCreate = ({ companyId, createCallback, employeeId }) => {
    const [work, setWork] = useState({ employeeId, month: _compValues.month, year: _compValues.year });
    const handleCreate = () => {
        lawFetchPost('EmployeeWorkMontly/create', work, null, null, null, createCallback, null, true, null);
    }
    return (
        (companyId || employeeId) && (
            <LawCreateDataModal createEvent={handleCreate}>
                {!employeeId && (
                        <div className="mb-3">
                            <LawSelect label={'Employee'} itemsUrl={wdfDynamicImportUrlParameter('companyEmployees/all-for-select', [{ default: companyId, key: 'companyId' }])} state={work} setState={setWork} fieldName={'employeeId'} />
                        </div>
                    )}
                    <EmployeeWorkMontlyFormBase workState={work} setWorkState={setWork} />
            </LawCreateDataModal>
        )
    )
}
export default EmployeeWorkMontlyCreate;