import CompanyPage from "../../components/data/accountant_customer/company/CompanyPage";
import Card from "../../components/ui/Card";

const CustomerCompanyView = () => {
    return (
        <Card style={{padding:0, overflow:'hidden'}}>
            <CompanyPage isAddReports={true} />
        </Card>
    )
}
export default CustomerCompanyView;