import { useContext } from "react";
import { ContxAppData } from "../../App";
import { Circle } from "@mui/icons-material";

const LawStatus = ({ status, statusGroup }) => {
    const { appDatas } = useContext(ContxAppData);
    let findStatus = null;
    if (appDatas && appDatas.statuses) {
        let sttsGrp = statusGroup ?? 'ActivePassiveDeleted';
        let fStatus = appDatas.statuses.find(f => f.name === sttsGrp);
        if (fStatus) {
            findStatus = fStatus.items.find(f => f.value === status);
        }
    }
    return (
        (findStatus) && (
            <span className="d-flex align-items-center" style={{ wordBreak: 'initial', wordWrap: 'initial' }}>
                <Circle sx={{ color: findStatus.color, width:'15px !important', marginRight:'3px' }}  />
                {findStatus.text}
            </span>
        )
    )
}
export default LawStatus;