import { Check, Password, PriorityHigh, Visibility, VisibilityOff } from "@mui/icons-material";
import { Chip, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack } from "@mui/material";
import { wgfChangeState, wgfGuid } from "../functions/lawFunctions";
import { useState } from "react";
import LawIconButton from "./LawIconButton";


const _compValue = {
    regex: {
        alphabetik: /[A-Za-z]/,
        number: /[0-9]/,
        char: /[^A-Za-z0-9 ]/
    },
    valid: {
        alphabetik: false,
        number: false,
        char: false,
        length: false,
        all: false
    }
}

const LawInputPassword = ({ state, setState, fieldName, label, isWithReEnter }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [reEnter, setReEnter] = useState('');
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleMouseUpPassword = (event) => {
        event.preventDefault();
    };
    const handleChange = (e) => {
        let val = e.target.value ?? '';
        if (val.length > 15 === false) {
            wgfChangeState(e.target.value, fieldName, state, setState);
        }
    }
    const handleChangeReEnter = (e) => {
        setReEnter(e.target.value);
    }
    let tempVal = (fieldName ? state[fieldName] : state);
    _compValue.valid.alphabetik = false;
    _compValue.valid.number = false;
    _compValue.valid.char = false;
    _compValue.valid.length = false;
    if (tempVal) {
        _compValue.valid.alphabetik = _compValue.regex.alphabetik.test(tempVal);
        _compValue.valid.number = _compValue.regex.number.test(tempVal);
        _compValue.valid.char = _compValue.regex.char.test(tempVal);
        _compValue.valid.length = tempVal.length > 7;
    }
    _compValue.valid.all = (_compValue.valid.alphabetik && _compValue.valid.number && _compValue.valid.char && _compValue.valid.length);

    /*
      Yanına da ReEnter Input'u ekle, uyuşmaz ise kırmızı X goster uyuşur ise yeşil Check ekle
    */
    let eId = wgfGuid();
    let eId2 = eId + '-2';
    return (
        <>
            <div className="mb-3 mt-2">
                <div className="d-flex">
                    <div className={((isWithReEnter ? 'w-50 pe-2' : 'w-100'))}>
                        <FormControl size="small" fullWidth>
                            <InputLabel htmlFor={eId}>{label ?? 'Wachtwoord'}</InputLabel>
                            <OutlinedInput
                                id={eId}
                                type={showPassword ? 'text' : 'password'}
                                placeholder="***********"
                                onChange={handleChange}
                                value={tempVal}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Password color={(_compValue.valid.all ? 'warning' : 'error')} />
                                    </InputAdornment>
                                }
                                endAdornment={
                                    <InputAdornment position="end">
                                        <LawIconButton
                                            aria-label={
                                                showPassword ? 'hide the password' : 'display the password'
                                            }
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            onMouseUp={handleMouseUpPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </LawIconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                            />
                        </FormControl>
                    </div>
                    {
                        (isWithReEnter) && (
                            <div className="w-50 ps-2">
                                <div className="position-relative">
                                    <FormControl size="small" fullWidth className="me-2">
                                        <InputLabel htmlFor={eId2}>Re enter</InputLabel>
                                        <OutlinedInput
                                            id={eId2}
                                            type={showPassword ? 'text' : 'password'}
                                            placeholder="***********"
                                            onChange={handleChangeReEnter}
                                            value={reEnter}
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <Password color={(reEnter === tempVal && reEnter ? 'warning' : 'error')} />
                                                </InputAdornment>
                                            }
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <LawIconButton
                                                        aria-label={
                                                            showPassword ? 'hide the password' : 'display the password'
                                                        }
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        onMouseUp={handleMouseUpPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </LawIconButton>
                                                </InputAdornment>
                                            }
                                            label="Password"
                                        />
                                    </FormControl>
                                    <div style={{ position: 'absolute', top: '-13px', width: '22px', height: '22px', display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', boxShadow: 'rgb(0, 0, 0) 0px 0px 13px -6px', background: '#fff', borderRadius: '20px', right: '-5px' }}>
                                        {
                                            ((reEnter === tempVal && reEnter)) ? (
                                                <Check color="warning" style={{ width: '18px' }} />
                                            ) : (
                                                <PriorityHigh color="error" style={{ width: '18px' }} />
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
                {
                    (tempVal) && (
                        <div className="mt-1">
                            <Stack direction="row" spacing={1}>
                                {
                                    (!_compValue.valid.alphabetik) && (
                                        <Chip
                                            size="small"
                                            label={'Letter'}
                                            variant="outlined"
                                        />
                                    )
                                }
                                {
                                    (!_compValue.valid.number) && (
                                        <Chip
                                            size="small"
                                            label={'Cijfer'}
                                            variant="outlined"
                                        />
                                    )
                                }
                                {
                                    (!_compValue.valid.char) && (
                                        <Chip
                                            size="small"
                                            label={'Niet-cijfer en niet-letter'}
                                            variant="outlined"
                                        />
                                    )
                                }
                                {
                                    ((_compValue.valid.alphabetik && _compValue.valid.number) && !_compValue.valid.length) && (
                                        <Chip
                                            size="small"
                                            label={'Minstens 8 karakters'}
                                            variant="outlined"
                                        />
                                    )
                                }
                            </Stack>
                        </div>
                    )
                }
            </div>
        </>
    )
}
export default LawInputPassword;