import { ButtonGroup } from "@mui/material";
const LawButtonGroup = (props) => {
    return (
        <div sx={{ marginBottom: '20px' }}>
            <ButtonGroup variant="text">
                {props.children}
            </ButtonGroup>
        </div>
    )

}
export default LawButtonGroup;