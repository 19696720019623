import { getYearsArray } from "../../../functions/lawFunctions";
import LawFormGrid from "../../../ui/LawFormGrid";
import LawInput from "../../../ui/LawInput";
import LawInputNumber from "../../../ui/LawInputNumber";
import LawInputPrice from "../../../ui/LawInputPrice";
import LawSelect from "../../../ui/LawSelect";
import LawUploadImage from "../../../ui/LawUploadImage";

const CompanyVehicleFormBase = ({ vehicleState, setVehicleState }) => {
    return (
        <>
            <LawFormGrid>
                <div className="mt-5 mb-5">
                    <LawUploadImage fieldName={'image'} state={vehicleState} setState={setVehicleState} maxWidth={720} maxHeight={720} isCircle={false} aspect={4 / 4} />
                </div>
            </LawFormGrid>
            <LawFormGrid>
                <LawInput propstate={[vehicleState, setVehicleState, 'brandName']} label={'BrandName'} />
                <LawInput propstate={[vehicleState, setVehicleState, 'modelName']} label={'ModelName'} />
                <LawSelect state={vehicleState} setState={setVehicleState} fieldName={'modelYear'} items={getYearsArray(-50, 0)} label={'Model Year'} />
                <LawInput propstate={[vehicleState, setVehicleState, 'code']} label={'Code*'} />
                <LawFormGrid>
                    <LawInputNumber state={vehicleState} setState={setVehicleState} fieldName={'firstHour'} label={'First Hour'} />
                    <LawInputNumber state={vehicleState} setState={setVehicleState} fieldName={'firstKm'} label={'FirstKm'} type={'number'} />
                </LawFormGrid>
                <LawFormGrid>
                    <LawInputPrice state={vehicleState} setState={setVehicleState} fieldName={'priceHour'} label={'Price Hour'} />
                    <LawInputPrice state={vehicleState} setState={setVehicleState} fieldName={'priceKm'} label={'Price KM'} />
                </LawFormGrid>
            </LawFormGrid>
        </>
    )
}
export default CompanyVehicleFormBase;