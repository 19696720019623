import { Add, CalendarMonth, Delete, StickyNote2 } from "@mui/icons-material";
import { Badge, Tooltip } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { lawFetchGet, lawFetchPost } from "../../../functions/lawFetch";
import { wgfChangeState, wgfDateTimeFormat } from "../../../functions/lawFunctions";
import LawDrawer from "../../../ui/LawDrawer";
import { appObjects, ContxAppData } from "../../../../App";
import LawIconButton from "../../../ui/LawIconButton";
const _compValues = {
    noteInitial: {
        id: '',
        note: '',
        date: ''
    }
}
const LawNavbarStickyNotes = () => {
    const { appDatas } = useContext(ContxAppData);
    const [count, setCount] = useState(0);
    const [show, setShow] = useState(false);
    const [notes, setNotes] = useState([]);
    const [note, setNote] = useState(null)
    const [selectedNote, setSelectedNote] = useState(_compValues.noteInitial)
    const getAll = () => {
        lawFetchGet('stickynote/all', null, setNotes, [], null, null, false, (resp) => {
            if (resp && resp.data) {
                setNotes(resp.data ?? []);
                setCount(resp.data.length);
            }
        });
    }
    useEffect(() => {
        setCount(appDatas ? (appDatas.navbarCountStickyNotes ?? 0) : 0);
    }, [appDatas])
    useEffect(() => {
        if (show) {
            getAll();
        }
        else {
            setNotes([]);
        }
    }, [show])

    const handleTextareaBlur = () => {
        if (selectedNote && selectedNote.id) {
            edit(selectedNote);
        }
    }
    const handleTextareaBlurForNew = () => {
        if (note === '') {
            setNote(null);
        }
        else {
            edit({ id: '', note });
        }
    }
    const handleDelete = (id) => {
        if (id) {
            lawFetchGet('stickynote/delete?id=' + id, null, null, null, () => {
                getAll();
            }, null, true, null);
        }
    }
    const edit = (noteParams) => {
        lawFetchPost('stickynote/edit', noteParams, null, null, null, () => {
            getAll();
            setNote(null);
            setCount(count + 1);
        }, null, true, null);
    }
    appObjects.stickyNote.showDrawer = (isShow) => {
        setShow(isShow ?? false);
    }
    return (
        <>
            <Tooltip title="Calendar" arrow>
                <LawIconButton
                    onClick={() => setShow(true)}
                    sx={{ color: '#fff' }}
                >
                    {
                        (count > 0) ? (
                            <Badge badgeContent={count} color="secondary">
                                <StickyNote2 sx={{ color: '#fff' }} />
                            </Badge>
                        ) : (
                            <StickyNote2 />
                        )
                    }

                </LawIconButton>
            </Tooltip>
            {
                (show) && (
                    <>
                        <LawDrawer showState={show} setShowState={setShow} header="Plaknotities">
                            <div style={{ width: '100%', padding: '40px 5px' }}>
                                {
                                    (note !== null) ? (
                                        <div style={{ width: '96%', borderRadius: '0px 0px 20px 5px', margin: 'auto', marginBottom: '20px', background: '#ffffd3', position: 'relative', height: 'auto', padding: '5px' }}>
                                            <textarea onBlur={handleTextareaBlurForNew} value={note} onChange={(e) => setNote(e.target.value)} style={{ width: '100%', minHeight: '150px', height: 'auto', margin: 0, background: '#ffffd3', border: 'none' }}>
                                            </textarea>
                                        </div>
                                    ) : (
                                        <button onClick={() => setNote('')} style={{ width: '200px', height: '120px', background: 'transparent', display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', borderRadius: '10px', margin: 'auto', marginBottom: '20px', border: '7px solid rgba(255,255,255,0.3)' }}>
                                            <Add sx={{ fontSize: '70px', color: 'rgba(255,255,255,0.3)' }} />
                                        </button>
                                    )
                                }
                                {
                                    notes.map((item, index) => {
                                        return (
                                            <div key={('note-' + index)} style={{ width: '96%', borderRadius: '0px 0px 20px 5px', margin: 'auto', marginBottom: '20px', background: '#ffffd3', position: 'relative', height: 'auto', padding: '5px' }}>
                                                <LawIconButton
                                                    color="error"
                                                    onClick={() => handleDelete(item.id)}
                                                    style={{ position: 'absolute', top: '-10px', right: '-10px', width: '30px', height: '30px', borderRadius: '30px', background: '#ffffd3', color: '#fff' }}
                                                >
                                                    <Delete color="error" />
                                                </LawIconButton>
                                                <textarea value={((selectedNote && selectedNote.id === item.id) ? selectedNote.note : item.note)} onChange={(e) => wgfChangeState(e.target.value, 'note', selectedNote, setSelectedNote)} onFocus={() => setSelectedNote(item)} onBlur={(e) => handleTextareaBlur(e, item)} style={{ width: '100%', minHeight: '150px', height: 'auto', margin: 0, background: '#ffffd3', border: 'none' }}>
                                                </textarea>
                                                <small className="text-muted" style={{ margin: 0, display: 'block', width: '100%', background: '#ffffd3' }}><CalendarMonth sx={{ fontSize: '18px' }} /> {wgfDateTimeFormat(item.date, 'dd.MM.yyyy HH:mm')}</small>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </LawDrawer>
                    </>
                )
            }
        </>
    )
}
export default LawNavbarStickyNotes;