import Card from "../../components/ui/Card";
import { useEffect, useState } from "react";
import { lawFetchGet } from "../../components/functions/lawFetch";
import { wgfAdminRoleControl, wgfFileUrlToSrc, wgfGetQueryStringValue } from "../../components/functions/lawFunctions";
import { Add, AlternateEmail, AssignmentInd, ChevronRight, Edit, MailOutline, Person, Phone } from "@mui/icons-material";
import LawStatus from "../../components/ui/LawStatus";
import { Avatar, IconButton, Stack, Tab, Tabs } from "@mui/material";
import LawModal from "../../components/ui/LawModal";
import MessageBody from "../../components/data/app/messages/MessageBody";
import { appObjects } from "../../App";
import { Link } from "react-router-dom";
import CustomerEdit from "../../components/data/accountant/CustomerEdit";
import CompanyPage from "../../components/data/accountant_customer/company/CompanyPage";
import { TabContext, TabPanel } from "@mui/lab";
import CompanyCreate from "../../components/data/accountant_customer/company/CompanyCreate";
import LawIconButton from "../../components/ui/LawIconButton";
const CustomerView = () => {
    const [customerId] = useState(wgfGetQueryStringValue("customerId"));
    const [companies, setCompanies] = useState([]);
    const [customer, setCustomer] = useState({});
    const [showModalEditCustomer, setShowModalEditCustomer] = useState(false);
    const [showModalMessage, setShowModalMessage] = useState(false);
    const [showModalCustomerInfoes, setShowModalCustomerInfoes] = useState(false);
    const [createModalShow, setCreateModalShow] = useState(false);
    const [tabValue, setTabValue] = useState('0');
    const getCustomer = () => {
        setShowModalEditCustomer(false);
        if (customerId) {
            lawFetchGet('customer/get?customerId=' + customerId, () => {
                lawFetchGet('customerCompany/all?customerId=' + customerId, null, setCompanies, [], null, null, false, null);
            }, setCustomer, {}, null, null, false, null);
        }
    }
    useEffect(() => {
        getCustomer();
    }, [customerId])
    const handleTabChange = (e, value) => {
        if(value===companies.length.toString()){
            setCreateModalShow(true);
        }
        else{
            setTabValue(value)
        }
    };
    return (
        <Card style={{ padding: '0', overflow: 'hidden' }}>
            {
                (customer && customer.id) && (
                    <>
                        <div class="usr-bnnr-container">
                            <div class="usr-bnnr-user">
                                <div class="usr-bnnr-profile">
                                    <Person sx={{ width: '70px !important', color: '#999' }} />
                                </div>
                                <span class="usr-bnnr-name">{customer.name} {customer.surname}</span>
                                <div className="jf-center">
                                    <LawStatus status={customer.status} />
                                </div>
                            </div>
                            <div class="usr-bnnr-buttons">
                                {
                                    (appObjects.values.roleGroup.accountantLawyer) && (
                                        <>
                                            <div class="item">
                                                <LawIconButton component={Link} to="#" onClick={(e) => { window.location.href = ("tel:" + customer.phone); e.preventDefault(); }}>
                                                    <Phone color="warning" />
                                                </LawIconButton>
                                            </div>
                                            <div class="item">
                                                <LawIconButton component={Link} to="#" onClick={(e) => { window.location.href = ('mailto:' + customer.email); e.preventDefault(); }}>
                                                    <AlternateEmail color="success" />
                                                </LawIconButton>
                                            </div>
                                            <div class="item">
                                                <LawIconButton onClick={() => setShowModalMessage(true)}>
                                                    <MailOutline color="success" />
                                                </LawIconButton>
                                            </div>
                                            <div class="item">
                                                <LawIconButton onClick={() => setShowModalCustomerInfoes(true)}>
                                                    <AssignmentInd color="primary" />
                                                </LawIconButton>
                                            </div>
                                            {
                                                (wgfAdminRoleControl() === true) && (
                                                    <div class="item">
                                                        <LawIconButton onClick={() => setShowModalEditCustomer(true)}>
                                                            <Edit color="primary" />
                                                        </LawIconButton>
                                                    </div>
                                                )
                                            }
                                        </>
                                    )
                                }
                            </div>
                        </div>
                        {
                            (showModalEditCustomer) && (
                                <LawModal style={{ maxWidth: '1100px' }} state={showModalEditCustomer} setState={setShowModalEditCustomer} >
                                    <CustomerEdit id={customer.id} callback={getCustomer} />
                                </LawModal>
                            )
                        }
                        {
                            (showModalMessage && customer) && (
                                <LawModal state={showModalMessage} setState={setShowModalMessage} style={{ width: '90%', maxWidth: '800px', padding: '0px' }}>
                                    <MessageBody isForMessagePage={false} userId={customer.id} />
                                </LawModal>
                            )
                        }
                        {
                            (showModalCustomerInfoes) && (
                                <LawModal state={showModalCustomerInfoes} setState={setShowModalCustomerInfoes}>
                                    <div>
                                        <Stack direction="row" spacing={2}>
                                            <div>
                                                <ChevronRight />
                                            </div>
                                            <div>
                                                <span className="d-block">{('Name:' + customer.name)}</span>
                                            </div>
                                        </Stack>
                                        <Stack direction="row" spacing={2}>
                                            <div>
                                                <ChevronRight />
                                            </div>
                                            <div>
                                                <span className="d-block">{('Surname:' + customer.surname)}</span>
                                            </div>
                                        </Stack>
                                        <Stack direction="row" spacing={2}>
                                            <div>
                                                <ChevronRight />
                                            </div>
                                            <div>
                                                <span className="d-block">{('E-mail:' + customer.email)}</span>
                                            </div>
                                        </Stack>
                                        <Stack direction="row" spacing={2}>
                                            <div>
                                                <ChevronRight />
                                            </div>
                                            <div>
                                                <span className="d-block">{('Telefoon:' + customer.phone)}</span>
                                            </div>
                                        </Stack>
                                    </div>
                                </LawModal>
                            )
                        }
                    </>
                )
            }
            {
                companies && (
                    <>

                        <TabContext value={tabValue}>
                            <div style={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={tabValue} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
                                    {
                                        companies.map((item, index) => <Tab key={index} value={index.toString()} icon={<Avatar alt={item.name} src={wgfFileUrlToSrc(item.logo, true)} />} label={item.name} />)
                                    }
                                    <Tab value={(companies.length).toString()} icon={<Add sx={{ width: '40px !important' }} />} label="Nieuw bedrijf" />
                                </Tabs>
                            </div>
                            {
                                companies.map((item, index) => <TabPanel key={index} value={index.toString()} sx={{ p: 0 }}>
                                    <CompanyPage companyId={item.id} />
                                </TabPanel>)
                            }
                        </TabContext>
                    </>
                )
            }
            {
                (createModalShow) && (
                    <LawModal state={createModalShow} setState={setCreateModalShow}>
                        <CompanyCreate isWithoutModal={true} customerId={customerId} callback={getCustomer} />
                    </LawModal>
                )
            }
        </Card>
    )
}
export default CustomerView;