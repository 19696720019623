import { Box, IconButton, Modal, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import LawIconButton from "./LawIconButton";

const LawModal = ({ children, state, setState, style, bodyStyle, header, closeCallback }) => {
    const modalHide = () => {
        if (closeCallback) {
            closeCallback();
        }
        if (setState) {
            setState(false);
        }

    }
    return (
        <>
            <Modal
                keepMounted
                open={state}
                onClose={() => setState(false)}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box className="law-modal" style={{ ...style }}>
                    <div className="law-modal-header">
                        <div>
                            {
                                (header) && (
                                    <h6 className="m-0">{header}</h6>
                                )
                            }
                        </div>
                        <div>
                            <LawIconButton onClick={modalHide}>
                                <Close />
                            </LawIconButton>
                        </div>
                    </div>
                    <div className="law-modal-body" style={bodyStyle}>
                        {children}
                    </div>
                </Box>
            </Modal >
        </>
    )
}
export default LawModal;