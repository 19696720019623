import * as signalR from "@microsoft/signalr"
import { useEffect } from "react";
import { appObjects } from "../../App";
import packageJson from '../../../package.json';
import { _appApiUrl } from "../functions/lawFetch";
import { wgfLogout } from "../functions/lawFunctions";
/*
 * SiganlR ile response edilen data callback ile ilgili componenet'e geri gonderilecek ve veriler ona gore islenir
 */
const signalRObjects = {
    connection: null
}

const LawSignalR = () => {
    useEffect(() => {
        let currentDomain=window.location.origin.includes('localhost')?packageJson.domainLocal:packageJson.domain;
        signalRObjects.connection = new signalR.HubConnectionBuilder()
            .withUrl((currentDomain + "signal?user=") + appObjects.values.loginInfo.id,
                {
                    skipNegotiation: true,  // skipNegotiation as we specify WebSockets
                    transport: signalR.HttpTransportType.WebSockets  // force WebSocket transport
                })
            .withAutomaticReconnect()
            .build();
        signalRObjects.connection.start().catch(err => {
            //Servere hata log'u gonder
            console.error("connection failed: ", err)
        });
        signalRObjects.connection.on("SignalRMessage", (content) => {
            appObjects.messagePage.signalRMessage(content);
            //Eger mesaj sayfasinda degil ise navbar'i guncelle
            if (window.location.href.includes('/messages') === false) {
                appObjects.messagePage.getNewMessageCount();
            }
        })
        signalRObjects.connection.on("SignalRNotification", () => {
            appObjects.notifications.getNotificationCount();
        })
        signalRObjects.connection.on("SignalRLogout", (content) => {
            if (content && JSON.parse(content) === appObjects.values.loginInfo.id) {
                wgfLogout();
            }
        })
        return () => {
            if (signalRObjects.connection) {
                signalRObjects.connection.stop();
            }
        }
    }, [])
    return (
        <>
        </>
    )
}
export default LawSignalR;