import { IconButton } from "@mui/material";
import Card from "../../components/ui/Card";
import { AccountCircle, Logout, Save, TextSnippet } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { lawFetchGet, lawFetchPost } from "../../components/functions/lawFetch";
import ChangePassword from "../../components/data/admin/users/ChangePassword";
import UserBaseElements from "../../components/data/admin/users/UserBaseElements";
import { wgfLogout } from "../../components/functions/lawFunctions";
import DataNotFound from "../../components/ui/DataNotFound";
import LawToolbar from "../../components/ui/LawToolbar";
import LawDisableTwoFactor from "../../components/data/app/two-factor/LawDisableTwoFactor";
import { appObjects } from "../../App";
import LawIconButton from "../../components/ui/LawIconButton";

const MyAccountView = () => {
    const [myData, setMyData] = useState({});
    useEffect(() => {
        lawFetchGet('/account/my-data', null, setMyData, {}, null, null, false, null);
    }, [])

    const save = () => {
        lawFetchPost('/account/my-data', myData, null, null, null, null, null, true, null);
    }

    return (
        myData ? (
            <>
                <Card>
                    <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
                        <AccountCircle sx={{ width: '60px !important' }} className="text-muted me-3" />
                        <div>
                            <div>
                                <h3 style={{ margin: 0 }}>{myData.name} {myData.surname}</h3>
                                <small>{myData.roleName}</small>
                            </div>
                        </div>
                    </div>
                    <LawToolbar>
                        <LawIconButton onClick={save}><Save /></LawIconButton>
                        <LawDisableTwoFactor userId={appObjects.values.loginInfo.id} title={'Reset Two Factor'} />
                        <ChangePassword userId={appObjects.values.loginInfo.id} isMyPassword={false} />
                        <LawIconButton>
                            <TextSnippet />
                        </LawIconButton>
                        <LawIconButton onClick={wgfLogout}>
                            <Logout />
                        </LawIconButton>
                    </LawToolbar>
                    <div sx={{ maxWidth: '440px' }}>
                        <UserBaseElements user={myData} setUser={setMyData} />
                    </div>
                </Card>
            </>
        ) : (
            <DataNotFound />
        )
    )
}
export default MyAccountView;