import { useEffect, useState } from "react"
import LawInput from "../../../ui/LawInput"
import "../InvoiceTemplateStyle.css"
import BoxCenter from "../../../ui/BoxCenter"
import { Alert, AlertTitle, Button, Grid2, MenuItem, Select, TextField } from "@mui/material"
import { Add, Close, Save } from "@mui/icons-material"
import { wgfDateTimeFormat, wgfFileUrlToSrc, wgfObjectClone, wgfSumArrayItemsInObject, wgfValueToPriceText } from "../../../functions/lawFunctions"
import { wgfDiscountTypes, wgInvoiceTypes, wgVatTypes } from "../../../lawConstants"
import { NumericFormat } from "react-number-format"
import LawSelect from "../../../ui/LawSelect"
import Seller from "../Seller"
import LawDatePicker from "../../../ui/LawDatePicker"
import { lawFetchGet, lawFetchPost } from "../../../functions/lawFetch"
import LawInputEmail from "../../../ui/LawInputEmail"
import LawInputPhone from "../../../ui/LawInputPhone"
import { appObjects } from "../../../../App"
import PdfViewer from "../../../ui/PdfViewer"
import ImageZoom from "../../../ui/ImageZoom"
import LawToolbar from "../../../ui/LawToolbar"
import LawIconButton from "../../../ui/LawIconButton"
/*
    Discount: İskonto/İndirim (DiscountPrice= indirim uygulanacak ücret / DiscountRate=indirim uygulanacak oran %)
    discountType = indirim türü Rate mi Amount mu?
*/

const _compValues = {
    invoiceItemInitial: {
        detailItemName: '',
        unitId: 1, //Birim ölçüsü (kg,litre,adet)
        quantity: 0, //adet
        discountType: 1, // 1  = % | 2 = €
        discountValue: 0, //iskonto değeri (rate or amount)
        discountTotal: 0,
        unitPrice: 0, // Birim fiyati
        vat: 0, //KDV oran 
        totalAmountWithoutVat: 0,
        vatAmount: 0,
        totalAmount: 0
    },
    viewerType: 'image',
    tempInvoice: null,
    dataControlForClose: false
}
/*
Eger postInvoiceId ile daha onceden eklenen kayit var ise onun verileri ornek model icin getir
*/

const CreateInvoice = ({ postInvoiceId, callback }) => {
    const [customer, setCustomer] = useState(null);
    const [companies, setCompanies] = useState([]);
    const [company, setCompany] = useState(null);
    const [seller, setSeller] = useState(null);
    const [postInvoice, setPostInvoice] = useState(null);
    const [invoice, setInvoice] = useState({});
    const [invoiceDetails, setInvoiceDetails] = useState([]);
    const [width, setWidth] = useState(0);
    useEffect(() => {
        //Secilen fatura baska kullancilardan hemen silinmeli
        lawFetchGet('invoice/get-for-create?postInvoiceId=' + postInvoiceId, null, null, null, null, null, false, (resp) => {
            if (resp && resp.data) {
                _compValues.dataControlForClose = true;
                if (resp.data.postInvoice.filePath) {
                    if (resp.data.postInvoice.filePath.includes('.pdf')) {
                        _compValues.viewerType = 'pdf';
                    } else if (resp.data.postInvoice.filePath.includes('.docx')) {
                        _compValues.viewerType = 'word';
                    }
                    else {
                        _compValues.viewerType = 'image';
                    }
                }
                _compValues.postInvoiceId = resp.data.postInvoice.id;
                setPostInvoice(resp.data.postInvoice);
                setInvoice(resp.data.invoice);
                setInvoiceDetails(resp.data.details);
                setCustomer(resp.data.customerId);
            }
        })
        const handleResize = () => {
            setWidth(window.innerWidth);
        };
        const handleInvoiceClose = () => {
            lawFetchGet('customerPostInvoices/close?id=' + postInvoiceId, null, null, null, null, null, false, null);
        }
        window.addEventListener('resize', handleResize);
        window.addEventListener('close', handleInvoiceClose);
        window.addEventListener('beforeunload', (event) => {
            handleInvoiceClose();
            event.preventDefault();
        });
        handleResize(); // Initialize width
        return () => {
            if (_compValues.dataControlForClose) {
                //Component kapatilirken faturanin kullaniciya ozel blokesini kaldirmalisin
                handleInvoiceClose();
            }
            _compValues.dataControlForClose = false;
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    useEffect(() => {
        if (customer) {
            lawFetchGet('customerCompany/get-for-select?customerId=' + customer, null, setCompanies, [], null, null, false, null);
        }
    }, [customer])
    const refreshSellerAndClientData = () => {
        if (invoice && invoice.invoiceType && invoice.customerCompanyId) {
            if (!company || (invoice.customerCompanyId && company.id != invoice.customerCompanyId)) {
                lawFetchGet('customerCompany/get?id=' + invoice.customerCompanyId, null, setCompany, null, null, null, false, null)
            }
            else if (company && invoice && ((invoice.invoiceType === 1 && invoice['sellerName'] !== company.name) || (invoice.invoiceType === 2 && invoice['clientName'] !== company.name))) {
                let tempInvoice = wgfObjectClone(invoice);
                if (invoice.invoiceType === 1) {
                    tempInvoice['sellerName'] = company?.name;
                    tempInvoice['sellerEmail'] = company?.email;
                    tempInvoice['sellerPhone'] = company?.phone;
                    tempInvoice['sellerFax'] = company?.fax;
                    tempInvoice['sellerAddress'] = company?.address;
                    tempInvoice['clientAddress'] = '';
                    tempInvoice['clientName'] = '';
                    tempInvoice['clientEmail'] = '';
                    tempInvoice['clientPhone'] = '';
                }
                else {
                    tempInvoice['sellerName'] = seller?.name;
                    tempInvoice['sellerEmail'] = seller?.email;
                    tempInvoice['sellerPhone'] = seller?.phone;
                    tempInvoice['sellerFax'] = seller?.fax;
                    tempInvoice['sellerAddress'] = seller?.address;
                    tempInvoice['clientName'] = company?.name;
                    tempInvoice['clientAddress'] = company?.address ?? '';
                    tempInvoice['clientEmail'] = company?.email;
                    tempInvoice['clientPhone'] = company?.phone;
                }
                setInvoice(tempInvoice);
            }
        }
    }
    useEffect(() => {
        refreshSellerAndClientData();
    }, [invoice, company])



    useEffect(() => {
        if (seller) {
            let _invoice = wgfObjectClone(invoice);
            _invoice['sellerId'] = seller.id;
            _invoice['sellerAddress'] = seller.address ?? '';
            _invoice['sellerEmail'] = seller.email ?? '';
            _invoice['sellerFax'] = seller.fax ?? '';
            _invoice['sellerName'] = seller.companyName ?? '';
            _invoice['sellerPhone'] = seller.phone ?? '';
            setInvoice(_invoice);
        }
    }, [seller])

    const handleDeleteItem = (index) => {
        let _list = wgfObjectClone(invoiceDetails);
        _list.splice(index, 1);
        setInvoiceDetails(_list);
    }

    const handleChange = (e, index, fieldName, isForPrice, isForRate, isNumber) => {
        let _invoice = wgfObjectClone(invoice);
        let _list = wgfObjectClone(invoiceDetails);
        let _value = e.target.value;
        if (isNumber) {
            _value = Number(_value ?? 0);
        }
        if (isForPrice) {
            _list[index][fieldName] = _value ? parseFloat(_value.replace(/[€$,]/g, "")) : 0; //NumericFormat nesnesinde €123,50 formatında değer alınıyordu
        }
        else if (isForRate) {
            _list[index][fieldName] = _value ? parseFloat(_value.replace(/[%]/g, "")) : 0; //NumericFormat nesnesinde %12 formatında değer alınıyordu
        }
        else {
            _list[index][fieldName] = _value;
        }
        if (fieldName === 'discountType') {
            _list[index]['discountValue'] = 0;
        }
        _list[index]['totalAmount'] = (_list[index].quantity * _list[index].unitPrice);
        _list[index]['vatAmount'] = (_list[index].vat > 0 ? ((_list[index].quantity * _list[index].unitPrice * _list[index].vat) / 100) : 0)
        let _total = _list[index]['totalAmount'] + _list[index]['vatAmount'];
        if (_list[index]['discountValue'] > 0) {
            if (_list[index]['discountType'] === 1) {
                _list[index]['discountTotal'] = (_total * _list[index]['discountValue']) / 100;
            }
            else {
                _list[index]['discountTotal'] = _list[index]['discountValue'];
            }
        }
        _list[index]['totalAmountWithoutVat'] = _total - _list[index]['discountTotal'];
        _invoice['totalPrice'] = wgfSumArrayItemsInObject(_list, 'totalAmount');
        _invoice['totalVat'] = wgfSumArrayItemsInObject(_list, 'vatAmount');
        _invoice['totalWithVat'] = wgfSumArrayItemsInObject(_list, 'totalAmountWithoutVat');
        _invoice['totalDiscount'] = wgfSumArrayItemsInObject(_list, 'discountTotal');
        setInvoice(_invoice);
        setInvoiceDetails(_list);
    }

    const handleCreate = () => {
        let _invoice = wgfObjectClone(invoice);
        if (invoiceDetails && invoiceDetails.length > 0 && _invoice['customerCompanyId'] && _invoice['sellerName']) {
            _invoice['invoiceDetails'] = invoiceDetails;
            _invoice['customerPostInvoiceId'] = postInvoiceId;

            lawFetchPost('invoice/create-invoice', _invoice, null, null, null, () => {
                setCustomer(null);
                setInvoiceDetails([]);
                setInvoice({ invoiceDate: wgfDateTimeFormat(new Date(), 'yyyy-MM-dd') });
                if (callback) {
                    callback();
                }
            }, null, true, null)
        }
    }
    const returnText = (obj, field) => {
        return (obj ? obj[field] ?? '---' : '---');
    }

    return (
        <>
            <div className="grd2-container">
                <div className="item-1">
                    {
                        (postInvoice) && (
                            <>
                                {
                                    (postInvoice.description) && (
                                        <div style={{ display: 'block', width: '90%', margin: '30px auto 0px auto' }}>
                                            <Alert severity="warning">
                                                <AlertTitle>
                                                    {postInvoice.description}
                                                </AlertTitle>
                                                {wgfDateTimeFormat(postInvoice.createDate, 'dd.MM.yyyy HH:mm')}
                                            </Alert>
                                        </div>
                                    )
                                }
                                {
                                    (_compValues.viewerType === 'pdf' ? (
                                        <PdfViewer height={(width > appObjects.values.createInvoiceBreakWidth ? 'calc(100vh - 120px)' : '30vh')} url={wgfFileUrlToSrc(postInvoice.filePath, false)} />
                                    ) : (_compValues.viewerType === 'word' ? (
                                        <div>Word Viewer</div>
                                    ) : (
                                        <ImageZoom height={(width > appObjects.values.createInvoiceBreakWidth ? 'calc(100vh - 120px)' : '30vh')} src={wgfFileUrlToSrc(postInvoice.filePath, true)} />
                                    )))
                                }
                            </>
                        )
                    }
                </div>
                <div className="item-2">

                    <div className="invoice-template-container">
                        <LawToolbar>
                            <LawIconButton onClick={handleCreate} title={'Opslaan'}>
                                <Save />
                            </LawIconButton>
                        </LawToolbar>
                        <div className="m-auto mb-5">
                            <Grid2 container={true} spacing={2}>
                                <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 4 }}>
                                    <LawSelect itemsUrl={'customer/all-for-select'} label={"Customer"} state={customer} setState={setCustomer} />
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 4 }}>
                                    <LawSelect items={companies} disabled={(!customer)} label={"Customer Company"} state={invoice} setState={setInvoice} fieldName={'customerCompanyId'} />
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 2 }}>
                                    <LawSelect items={wgInvoiceTypes} label={"Invoice Type"} state={invoice} setState={setInvoice} fieldName={'invoiceType'} />
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 2 }}>
                                    <LawSelect items={appObjects.values.appDatas.invoiceGroupTypes} label={"Invoice Group"} state={invoice} setState={setInvoice} fieldName={'invoiceGroupId'} />
                                </Grid2>
                            </Grid2>
                        </div>
                        <div className="invoice-header">
                            <div className="invoice-logo">
                                <h2>FACTUUR</h2>
                            </div>
                            <div className="invoice-company-info">
                                {
                                    (invoice && invoice.invoiceType === 2) && (
                                        <Seller selectedSetState={setSeller} />
                                    )
                                }
                                <h4>{returnText(invoice, 'sellerName')}</h4>
                                <span className="d-block">Email:{returnText(invoice, 'sellerEmail')}</span>
                                <span className="d-block">Phone:{returnText(invoice, 'sellerPhone')}</span>
                                <span className="d-block">Fax:{returnText(invoice, 'sellerFax')}</span>
                                <span className="d-block">Address:{returnText(invoice, 'sellerAddress')}</span>
                            </div>
                        </div>
                        <div className="invoice-section">
                            <h3>Invoice Details</h3>
                            <div style={{ width: '200px' }}>
                                <LawInput propstate={[invoice, setInvoice, 'invoiceNumber']} label={"Invoice No:"} style={{ display: 'initial' }} />
                                <LawDatePicker state={invoice} setState={setInvoice} fieldName={'invoiceDate'} isSetAutoDate={true} />
                            </div>
                        </div>
                        <div className="invoice-section">
                            <h3>Billing To</h3>
                            <div style={{ width: '320px' }}>
                                <LawInput propstate={[invoice, setInvoice, 'clientName']} label={"Client Name:"} style={{ display: 'initial' }} />
                                <LawInputPhone state={invoice} setState={setInvoice} fieldName={'clientPhone'} />
                                <LawInputEmail state={invoice} setState={setInvoice} fieldName={'clientEmail'} />
                                <LawInput propstate={[invoice, setInvoice, 'clientAddress']} label={"Address:"} style={{ display: 'initial' }} />
                            </div>
                        </div>
                        <div className="invoice-section">
                            <table className="invoice-table">
                                <thead>
                                    <tr>
                                        <th>Description</th>
                                        <th style={{ width: '180px' }}>Quantity</th>
                                        <th style={{ width: '80px' }}>VAT</th>
                                        <th style={{ width: '110px' }}>Unit Price</th>
                                        <th style={{ width: '160px' }}>Discount</th>
                                        <th style={{ width: '110px' }}>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        invoiceDetails.map((item, index) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        <TextField value={item.detailItemName} onChange={(e) => handleChange(e, index, 'detailItemName')} size="small" variant="outlined" style={{ width: '100%' }} />
                                                    </td>
                                                    <td>
                                                        <div className="d-flex">
                                                            <TextField style={{ width: '90px' }} value={item.quantity} min="0" type="number" onChange={(e) => handleChange(e, index, 'quantity', false, false, true)} size="small" variant="outlined" />
                                                            <Select
                                                                style={{ width: '90px' }}
                                                                size="small"
                                                                defaultValue="piece"
                                                                value={item.unitId}
                                                                onChange={(e) => handleChange(e, index, 'unitId')}
                                                            >
                                                                {
                                                                    appObjects.values.appDatas.unitTypes.map((itemV, indexV) => {
                                                                        return <MenuItem key={`unitm-${index}-${indexV}`} value={itemV.value}>{itemV.text}</MenuItem>
                                                                    })
                                                                }
                                                            </Select>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <Select
                                                            size="small"
                                                            defaultValue={21}
                                                            value={item.vat}
                                                            style={{ width: '80px' }}
                                                            onChange={(e) => handleChange(e, index, 'vat')}
                                                        >
                                                            {
                                                                wgVatTypes.map((itemV, indexV) => {
                                                                    return <MenuItem key={`vat-${index}-${indexV}`} value={itemV.value}>{itemV.text}</MenuItem>
                                                                })
                                                            }
                                                        </Select>
                                                    </td>
                                                    <td>
                                                        <NumericFormat value={item.unitPrice} onChange={(e) => handleChange(e, index, 'unitPrice', true, false)} customInput={TextField} thousandSeparator valueIsNumericString prefix="€" size="small" />
                                                    </td>
                                                    <td>
                                                        <div className="d-flex">
                                                            <Select
                                                                size="small"
                                                                defaultValue={0}
                                                                value={item.discountType}
                                                                style={{ width: '65px' }}
                                                                onChange={(e) => handleChange(e, index, 'discountType')}
                                                            >
                                                                {
                                                                    wgfDiscountTypes.map((itemV, indexV) => {
                                                                        return <MenuItem key={`is-rate-${index}-${indexV}`} value={itemV.value}>{itemV.text}</MenuItem>
                                                                    })
                                                                }
                                                            </Select>
                                                            <div style={{ width: '95px' }}>
                                                                {
                                                                    (item.discountType === 1) ? (
                                                                        <NumericFormat value={item.discountValue} onChange={(e) => handleChange(e, index, 'discountValue', false, true)} max={100} min={0} customInput={TextField} thousandSeparator valueIsNumericString prefix="%" size="small" />
                                                                    ) : (
                                                                        <NumericFormat value={item.discountValue} onChange={(e) => handleChange(e, index, 'discountValue', true, false)} min={0} max={999999} customInput={TextField} thousandSeparator valueIsNumericString prefix="€" size="small" />
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td style={{ position: 'relative' }}>
                                                        <strong>€{item.totalAmount}</strong>
                                                        <LawIconButton title={'Delete'} onClick={() => handleDeleteItem(index)} style={{ position: 'absolute', display: 'block', top: '4px', right: '0px' }}>
                                                            <Close />
                                                        </LawIconButton>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    <tr>
                                        <td colSpan="6">
                                            <BoxCenter>
                                                <Button variant="outlined" onClick={() => setInvoiceDetails([...invoiceDetails, _compValues.invoiceItemInitial])} ><Add /></Button>
                                            </BoxCenter>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colspan="5" className="invoice-totals"><strong>Subtotal</strong></td>
                                        <td>
                                            <strong>{wgfValueToPriceText(invoice['totalPrice'])}</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="5" className="invoice-totals">
                                            <strong>VAT</strong>
                                        </td>
                                        <td>
                                            <strong>{wgfValueToPriceText(invoice['totalVat'])}</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="5" className="invoice-totals">
                                            <strong>Discount</strong>
                                        </td>
                                        <td>
                                            <strong>{wgfValueToPriceText(invoice['totalDiscount'])}</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="5" className="invoice-totals">
                                            <strong>Total</strong>
                                        </td>
                                        <td>
                                            <strong>{wgfValueToPriceText(invoice['totalWithVat'])}</strong>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div className="invoice-footer">

                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}
export default CreateInvoice;