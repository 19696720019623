import { useState } from "react";
import LawDataTable from "../../../ui/LawDataTable";
import { wgfDateTimeFormat, wgfValueToPriceText } from "../../../functions/lawFunctions";
import LawIconButton from "../../../ui/LawIconButton";
import { ChevronRight } from "@mui/icons-material";
import LawStatus from "../../../ui/LawStatus";
import { appObjects } from "../../../../App";
import Sale from "./Sale";
const _tempList = [];
for (let i = 0; i < 100; i++) {
    _tempList.push({
        id: i,
        saleId: 123456 + i,
        customerName: 'Customer Name Surname',
        phone: `0612345678${i}`,
        postcode: `999${i} AB`,
        price: 1230 + i,
        campaignName: 'fgh dgf hdg ' + i,
        portal: 'Salesdock',
        saleDate: new Date(),
        status: 1
    })
}
const SaleTableCompleted = () => {
    const [sales, setSales] = useState(_tempList);
    let columns = [
        {
            name: 'Sale Id',
            selector: (row) => row.saleId,
            sortable: true,
        },
        {
            name: 'Company Name',
            cell: (row) => (
                <div>
                    <span className="d-block">{row.campaignName}</span>
                    <small className="text-muted d-block">{row.portal}</small>
                </div>
            ),
            sortable: true,
        },
        {
            name: 'SellerName',
            selector: (row) => row.customerName,
            sortable: true,
        },
        {
            name: 'SellerName',
            selector: (row) => row.phone,
            sortable: true,
        },
        {
            name: 'SellerName',
            selector: (row) => row.postcode,
            sortable: true,
        },
        {
            name: 'Create',
            selector: (row) => wgfDateTimeFormat(row.saleDate, 'dd.MM.yyyy HH:mm'),
            sortable: true,
        },
        {
            name: 'Total',
            cell: (row, index) => (
                <strong>{wgfValueToPriceText(row.price)}</strong>
            ),
        },
        {
            name: 'Status',
            cell: (row) => (
                <LawStatus status={row.status} statusGroup={'InvoiceStatus'} />
            )
        },
        {
            name: 'Action',
            width: '90px',
            cell: (row) => (
                <div className="law-dttb-actions">
                    <Sale id={row.id}/>
                </div>
            ),
        }
    ];
    return (
        <LawDataTable dataState={sales} setDataState={setSales} columns={columns} searchFields={['id', 'customerName', 'phone', 'email']} />
    )
}
export default SaleTableCompleted;