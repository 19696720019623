import { useState } from "react";
import { wgfGetQueryStringValue, wgfIsNotNull } from "../../../functions/lawFunctions";
import { lawFetchPost } from "../../../functions/lawFetch";
import EmployeeFormBase from "./EmployeeFormBase";
import LawSelect from "../../../ui/LawSelect";
import LawCreateDataModal from "../../../ui/LawCreateDataModal";
import LawFormGrid from "../../../ui/LawFormGrid";
const EmployeeCreate = ({ companyId, callback }) => {
    const [companyIdNo] = useState(companyId ?? wgfGetQueryStringValue('companyId'))
    const [employee, setEmployee] = useState({ companyId: companyIdNo });
    const handleCreate = () => {
        if (wgfIsNotNull([employee['name'], employee['sureName'], employee['companyId']], true) === true) {
            lawFetchPost('CompanyEmployees/create', employee, null, null, null, () => {
                setEmployee({ companyId: companyIdNo })
                if (callback) {
                    callback();
                }
            }, null, false, null);
        }
    }
    return (
        <>
            <LawCreateDataModal createEvent={handleCreate} style={{ maxWidth: '1000px' }}>
                {
                    (!companyIdNo) && (
                        <LawFormGrid>
                            <LawSelect itemsUrl={'customerCompany/get-for-select'} state={employee} setState={setEmployee} fieldName={'companyId'} label={'Company'} />
                        </LawFormGrid>
                    )
                }
                <EmployeeFormBase employeeState={employee} setEmployeeState={setEmployee} />
            </LawCreateDataModal>
        </>
    )
}
export default EmployeeCreate;