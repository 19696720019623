import { Add, Calculate, CalendarMonth, ChevronRight, Edit, History, Percent, Remove, Save, TrendingDown, TrendingUp } from "@mui/icons-material";
import {  Button, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { wgfAdminRoleControl, wgfFileUrlToSrc, wgfGetCityName, wgfGetQueryStringValue, wgfIsNotNull, wgfObjectClone, wgfValueToPriceText } from "../../../functions/lawFunctions";
import { lawFetchGet, lawFetchPost } from "../../../functions/lawFetch";
import DataNotFound from "../../../ui/DataNotFound";
import LawModal from "../../../ui/LawModal";
import CompanyFormFormBase from "./CompanyFormFormBase";
import LawSelect from "../../../ui/LawSelect";
import { lawStatusActivePassive } from "../../../lawConstants";
import { appObjects } from "../../../../App";
import Reports from "../report/Reports";
import LawFormGrid from "../../../ui/LawFormGrid";
import LawToolbar from "../../../ui/LawToolbar";
import LawIconButton from "../../../ui/LawIconButton";

const CompanyPage = ({ companyId, isAddReports }) => {
    const [companyIdNo] = useState(companyId ?? wgfGetQueryStringValue('companyId'));
    const [company, setCompany] = useState(null);
    const [editModalShow, setEditModalShow] = useState(false);
    const [sector, setSector] = useState(null);
    const getComp = () => {
        if (companyId) {
            lawFetchGet('CustomerCompany/get?id=' + companyId, null, null, null, null, null, false, (resp) => {
                if (resp && resp.data) {
                    if (resp.data.sectorId) {
                        lawFetchGet('app/get-sector?id=' + resp.data.sectorId, null, setSector, null, null, null, false, null);
                    }
                    setCompany(resp.data);
                }
            });
        }
    }
    useEffect(() => {
        getComp();
    }, [companyIdNo]);
    const handleSave = () => {
        let _cmp = wgfObjectClone(company);
        _cmp['companyId'] = companyId;
        if (wgfIsNotNull([_cmp['companyId'], _cmp['name'], _cmp['phone'], _cmp['email']], true) === true) {
            lawFetchPost('CustomerCompany/edit', _cmp, null, null, null, () => {
                getComp();
                setEditModalShow(false);
            }, null, true, null);
        }
    }
    useEffect(() => {
        if (companyIdNo) {
            lawFetchGet('customerCompany/get?id=' + companyIdNo, null, setCompany, null, null, null, false, null);
        }
    }, [companyIdNo])
    const handleEditShow = () => {
        setEditModalShow(true);
    }

    return (
        (company) ? (
            <>

                <div class="cmpny-container">
                    <div class="cmpny-header" >
                        {
                            (wgfAdminRoleControl()) && (
                                <div class="cmpny-btn-edit">
                                    <LawIconButton onClick={handleEditShow}>
                                        <Edit />
                                    </LawIconButton>
                                </div>
                            )
                        }
                        <div class="cmpny-logo">
                            <img src={wgfFileUrlToSrc(company.logo, true)} />
                        </div>
                        <span>Tax Nu:{company.taxNumber}</span>
                        <h3>{company.name}</h3>
                        <span>{sector && sector.name}</span>
                        <span>{company.postcode} {company.housenumber} {company.buildingNumber} {company.buildingSuffix} {company.address} {wgfGetCityName(company.cityId)}</span>

                        <div class="cmpny-props">
                            <div class="item">
                                <div>
                                    <CalendarMonth />
                                </div>
                                <span className="title">This Month</span>
                                <span className="in"><Add /> {wgfValueToPriceText(1230)}</span>
                                <span className="out"><Remove /> {wgfValueToPriceText(1763)}</span>
                                <span className="total negative"><TrendingDown /> {wgfValueToPriceText(763)}</span>
                            </div>
                            <div class="item">
                                <div>
                                    <History />
                                </div>
                                <span className="title">Last Month</span>
                                <span className="in"><Add /> {wgfValueToPriceText(1230)}</span>
                                <span className="out"><Remove /> {wgfValueToPriceText(763)}</span>
                                <span className="total"><TrendingUp /> {wgfValueToPriceText(763)}</span>
                            </div>
                            <div class="item">
                                <div>
                                    <Percent />
                                </div>
                                <span className="title">Average</span>
                                <span className="in"><Add /> {wgfValueToPriceText(1230)}</span>
                                <span className="out"><Remove /> {wgfValueToPriceText(1763)}</span>
                                <span className="total"><TrendingDown /> {wgfValueToPriceText(763)}</span>
                            </div>
                            <div class="item">
                                <div>
                                    <Calculate />
                                </div>
                                <span className="title">Total</span>
                                <span className="in"><Add /> {wgfValueToPriceText(1230)}</span>
                                <span className="out"><Remove /> {wgfValueToPriceText(1763)}</span>
                                <span className="total"><TrendingDown /> {wgfValueToPriceText(763)}</span>
                            </div>
                        </div>
                        {
                            (window.location.href.includes('company?companyId') === false) && (
                                <div className="mt-3 mb-5 jf-center">
                                    <Button variant="contained" onClick={() => appObjects.goToWithViewIdentity('CompanyView', '?companyId=' + company.id)}>Go to <ChevronRight /></Button>
                                </div>
                            )
                        }

                    </div>
                </div>
                {
                    (isAddReports) && (
                        <Reports />
                    )
                }
                {
                    (editModalShow && company) && (
                        <LawModal state={editModalShow} setState={setEditModalShow}>
                            <LawToolbar>
                                <LawIconButton onClick={handleSave}><Save/></LawIconButton>
                            </LawToolbar>
                            <CompanyFormFormBase company={company} setCompany={setCompany} />
                            <LawFormGrid>
                                <LawSelect items={lawStatusActivePassive} label={"Status"} state={company} setState={setCompany} fieldName={'status'} />
                            </LawFormGrid>
                        </LawModal>
                    )
                }
            </>
        ) : (
            <DataNotFound />
        )
    )
}
export default CompanyPage;