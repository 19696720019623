import { useContext, useEffect, useState } from "react";
import { appObjects } from "../../../../App";
import { Avatar, Badge, Box, Button, IconButton, List, ListItemAvatar, ListItemButton, ListItemText, Typography } from "@mui/material";
import LawListSearchBox from "../../../ui/LawListSearchBox";
import { wgfAdminRoleControl, wgfDateTimeFormat } from "../../../functions/lawFunctions";
import { lawFetchGet } from "../../../functions/lawFetch";
import { useSearchParams } from "react-router-dom";
import MessageGroupCreateEdit from "./MessageGroupCreateEdit";
import { CheckCircle, People } from "@mui/icons-material";
import { ContxAccountData } from "../../../../pages/layouts/LayoutAccount";
import LawIconButton from "../../../ui/LawIconButton";
import LawToolbar from "../../../ui/LawToolbar";

const MessagesSidebar = () => {
    const { messageContacts, setMessageContacts, setMessageSelectedUser, messageSelectedUser } = useContext(ContxAccountData);
    const [users, setUsers] = useState([]);
    const [isShowUser, setIsShowUsers] = useState(false);
    const [isCreateGroup, setIsCreateGroup] = useState(false);
    const selectUser = (contactId) => {
        appObjects.goTo('/messages?contact=' + contactId);
    }
    const [searchParams] = useSearchParams();

    useEffect(appObjects.messagePage.getContacts, []);
    useEffect(() => {
        let _contactId = searchParams.get("contact");
        if (_contactId) {
            lawFetchGet('messages/get-contact-info?contactId=' + _contactId, null, setMessageSelectedUser, null, null, null, false, null);
        }
    }, [searchParams])
    useEffect(() => {
        if (isShowUser) {
            lawFetchGet('account/getall-for-message-list', null, setUsers, [], null, null, false, null);
        }
    }, [isShowUser])
    const createGroupCallback = () => {
        appObjects.messagePage.getContacts();
        setIsCreateGroup(false);
        setIsShowUsers(false);
    }
    const handleShowUsers = () => {
        setIsShowUsers(!isShowUser);
        setIsCreateGroup(false);
    }

    return (
        <>
            <LawToolbar
                rightChilds={
                    (isCreateGroup === false) && ((isShowUser) ? (
                        <LawListSearchBox listState={users} setListState={setUsers} searchFields={['name', 'message']} />
                    ) : (
                        <LawListSearchBox listState={messageContacts} setListState={setMessageContacts} searchFields={['contactName', 'lastMessage']} />
                    ))
                }
            >
                <LawIconButton sx={{ background: (isShowUser ? '#00f' : 'transparent'), color: (isShowUser ? '#fff' : '#333') }} aria-label="delete" onClick={handleShowUsers}>
                    <People />
                </LawIconButton>
            </LawToolbar>
            <List sx={{ width: '100%' }}>
                {
                    (isShowUser) ? (
                        (isCreateGroup) ? (
                            <MessageGroupCreateEdit callback={createGroupCallback} />
                        ) : (
                            <>
                                {
                                    (wgfAdminRoleControl()) && (
                                        <Button variant="contained" onClick={() => setIsCreateGroup(!isCreateGroup)}>Create Group</Button>
                                    )
                                }
                                <List sx={{ width: '100%' }}>
                                    {
                                        users.filter(f => (f.show === true || f.show === undefined)).map((item, index) => {
                                            return (
                                                <ListItemButton

                                                    key={index}
                                                    onClick={() => { selectUser(item.id) }}
                                                    secondaryAction={
                                                        (isCreateGroup) ? (
                                                            <LawIconButton onClick={() => { }} edge="end" aria-label="delete">
                                                                <CheckCircle />
                                                            </LawIconButton>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                    sx={{ alignContent: 'start', alignItems: 'start' }}
                                                >
                                                    <ListItemAvatar component={IconButton} sx={{ cursor: 'pointer', alignContent: 'start', alignItems: 'start' }}>
                                                        <Avatar alt={item.name} src="#" />
                                                    </ListItemAvatar>
                                                    <ListItemText primary={item.name} />
                                                </ListItemButton>
                                            )
                                        })
                                    }
                                </List >
                            </>
                        )
                    ) : (
                        messageContacts.filter(f => (f.show === true || f.show === undefined)).map((item, index) => {
                            return (
                                <ListItemButton
                                    key={('message-sidebar-contacts' + index)}
                                    alignItems="flex-start"
                                    onClick={() => { selectUser(item.contactId) }}
                                    sx={{ backgroundColor: ((messageSelectedUser ? (messageSelectedUser.contactId === item.contactId) : false) ? 'rgba(0, 0, 0, 0.04)' : 'transparent') }}
                                >
                                    <ListItemAvatar>
                                        {
                                            (item.status === 1 && item.lastMessageIsMy === false && (messageSelectedUser ? (messageSelectedUser.contactId !== item.contactId) : false)) ? (
                                                <Badge color="secondary" badgeContent=" " variant="dot">
                                                    <Avatar alt={item.contactName} src="/static/images/avatar/1.jpg" />
                                                </Badge>
                                            ) : (
                                                <Avatar alt={item.contactName} src="/static/images/avatar/1.jpg" />
                                            )
                                        }
                                    </ListItemAvatar>
                                    <ListItemText
                                        sx={{ color: '#333' }}
                                        primary={item.contactName}

                                        secondary={
                                            <>
                                                <Typography
                                                    component="span"
                                                    variant="body4"
                                                    sx={{ color: '#333', display: 'block' }}
                                                >
                                                    {item.lastMessage}
                                                </Typography>
                                                <Typography
                                                    component="small"
                                                    variant="body4"
                                                    sx={{ color: '#333', display: 'block' }}
                                                >
                                                    {wgfDateTimeFormat(item.lastMessageDate, 'dd.MM.yyyy HH:mm')}
                                                </Typography>
                                            </>
                                        }
                                    />
                                </ListItemButton>
                            )
                        })
                    )
                }
            </List>
        </>
    )
}
export default MessagesSidebar;