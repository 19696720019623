import './SidebarItemsStyle.css'
import { useEffect, useState } from "react";
import { lawFetchGet, lawFetchPost } from "../../../functions/lawFetch";
import { Button, IconButton } from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import LawModal from '../../../ui/LawModal';
import LawInput from '../../../ui/LawInput';
import LawCheckbox from '../../../ui/LawCheckbox';
import LawSelect from '../../../ui/LawSelect';
import { wgfChangeState, wgfIsNotNull, wgfObjectClone } from '../../../functions/lawFunctions';
import LawSelectUsersOrRoles from '../share/LawSelectUsersOrRoles';
import { lawTableNames, wgPortalTypes } from '../../../lawConstants';
import { getPortalRoutes, getPortalRoutesForSelect } from '../../../../pages/PortalRoutes';
import LawDelete from '../../../ui/LawDelete';
import LawIconButton from '../../../ui/LawIconButton';

const SidebarItems = () => {
    const [items, setItems] = useState([]);
    const [sidebarItemsForSelect, setSidebarItemsForSelect] = useState([]);
    const [item, setItem] = useState();
    const [sidebarItemShow, setSidebarItemShow] = useState(false);
    const [portalRoutes, setPortalRoutes] = useState([]);
    const getAll = () => {
        window.location.href = window.location.href;
    }
    useEffect(() => {
        lawFetchGet('app/sidebar-items-for-edit', null, null, null, null, null, false, (resp) => {
            if (resp) {
                setItems(resp.data);

            }
        });
    }, [])
    useEffect(() => {
        if (item && item['portal']) {
            setPortalRoutes(getPortalRoutesForSelect(item['portal']));
            setSidebarItemsForSelect(items.filter(f => f.portal === item['portal']).map((item) => ({ value: item.id, text: item.text })));
        }
    }, [item])
    const handleSelectItem = (item) => {
        setItem(item);
        setSidebarItemShow(true);
    }
    const handleSidebarItemModalClose = () => {
        setSidebarItemShow(false);
        setItem(null);
    }
    const handleNewSidebarItem = (parentRegisterId, portal) => {
        //let item = parentRegisterId ? items.find(f => f.id === parentRegisterId) : null;
        // url: (item ? item.url + '/' : '')
        setItem({
            id: '',
            parentRegisterId,
            portal,
            isShowSidebar: false,
            url: '',
            viewIdentity: ''
        });
        setSidebarItemShow(true);
    }
    const handleDelete = (id) => {
        if (id) {
            lawFetchGet('app/delete-sidebar-item?id=' + id, null, null, null, () => {
                //setItem(null);
                //setSidebarItemShow(false);
                getAll();
            }, null, true, null);
        }
    }
    const handleSaveSidebarItem = () => {
        if (wgfIsNotNull([item['text'], item['portal']], true) === true && (item['viewIdentity'] || item['isAccordion'] === true)) {
            let params = wgfObjectClone(item);
            let parent = items.find(f => f.id === item.parentRegisterId);
            let urlItems = [];
            if (parent) {
                urlItems.push(parent['url']);
            }
            let routeView = getPortalRoutes(params.portal).find(f => f.identity === params.viewIdentity);
            if (routeView) {
                urlItems.push(routeView.path.replaceAll('/', ''));
                params['url'] = urlItems.join('/');
            }
            lawFetchPost('app/edit-sidebar-item', params, null, null, null, () => {
                //setItem(null);
                //setSidebarItemShow(false);
                getAll();
            }, null, true, null);
        }
    }
    const getItemChilds = (id) => {
        let _itemChilds = items.filter(f => f.parentRegisterId === id);
        return (
            (_itemChilds && _itemChilds.length > 0) ? (
                <ul>
                    {
                        _itemChilds.map((item) => {
                            return (
                                (items.find(f => f.parentRegisterId === item.id) ? (
                                    <li>
                                        <details open>
                                            <summary>
                                                <LawIconButton onClick={() => handleSelectItem(item)}><Edit fontSize='small' /></LawIconButton>
                                                <LawIconButton onClick={() => handleNewSidebarItem(item.id, item.portal)}><Add fontSize='small' /></LawIconButton>
                                                <LawDelete deleteData={item} tableName={lawTableNames.sidebarItems} deleteCallback={getAll} />
                                                ({item.index}) {item.text}
                                            </summary>
                                            {getItemChilds(item.id)}
                                        </details>
                                    </li>
                                ) : (
                                    <li>
                                        <LawIconButton onClick={() => handleSelectItem(item)}><Edit fontSize='small' /></LawIconButton>
                                        <LawIconButton onClick={() => handleNewSidebarItem(item.id, item.portal)}><Add fontSize='small' /></LawIconButton>
                                        <LawDelete deleteData={item} tableName={lawTableNames.sidebarItems} deleteCallback={getAll} />
                                        ({item.index}) {item.text}
                                    </li>
                                ))
                            )
                        })
                    }
                </ul>
            ) : (
                <></>
            )
        )
    }
    const handleSelectRoles = (resp) => {
        if (resp) {
            let selecteds = resp.filter(f => f.selected).map(item => item.value).join(',');
            wgfChangeState(selecteds, 'roles', item, setItem);
        }
    }
    return (
        <>
            <div>
                {
                    wgPortalTypes.map((item, index) => {
                        return (
                            <div key={'portal-' + index}>
                                <strong>{item.text}<LawIconButton onClick={() => handleNewSidebarItem(null, item.value)}><Add fontSize='small' /></LawIconButton></strong>
                                <ul class="treelist">
                                    {
                                        (items) && (items.filter(f => !f.parentRegisterId && f.portal === item.value).map((item, index) => {
                                            return (
                                                (items.find(f => f.parentRegisterId === item.id)) ? (
                                                    <li key={item.id}>
                                                        <details>
                                                            <summary>
                                                                <LawIconButton onClick={() => handleSelectItem(item)}><Edit fontSize='small' /></LawIconButton>
                                                                <LawIconButton onClick={() => handleNewSidebarItem(item.id, item.value)}><Add fontSize='small' /></LawIconButton>
                                                                <LawDelete deleteData={item} tableName={lawTableNames.sidebarItems} deleteCallback={getAll} />
                                                                ({item.index}) {item.text}</summary>
                                                            {getItemChilds(item.id)}
                                                        </details>
                                                    </li>
                                                ) : (
                                                    <li key={item.id}>
                                                        <LawIconButton onClick={() => handleSelectItem(item)}><Edit fontSize='small' /></LawIconButton>
                                                        <LawIconButton onClick={() => handleNewSidebarItem(item.id, item.value)}><Add fontSize='small' /></LawIconButton>
                                                        <LawDelete deleteData={item} tableName={lawTableNames.sidebarItems} deleteCallback={getAll} />
                                                        ({item.index}) {item.text}</li>
                                                )
                                            )
                                        }))
                                    }
                                </ul>
                            </div>
                        )
                    })
                }
                <div>
                    <strong>Generic</strong>
                    <ul class="treelist">
                        {
                            (items) && (items.filter(f => !f.parentRegisterId && !f.portal).map((item, index) => {
                                return <li key={item.id}>
                                    {item.text}
                                </li>
                            }))
                        }
                    </ul>
                </div>
            </div >
            {
                (sidebarItemShow && item) && (
                    <LawModal state={sidebarItemShow} setState={setSidebarItemShow} closeCallback={handleSidebarItemModalClose}>
                        <LawSelect label={"Portal"} items={wgPortalTypes} state={item} setState={setItem} fieldName={'portal'} />
                        <LawInput propstate={[item, setItem, 'text']} label={'Text'} />
                        <LawSelect label={"Page"} items={portalRoutes} state={item} setState={setItem} fieldName={'viewIdentity'} />
                        <LawSelectUsersOrRoles isAddUser={false} isAddRoles={true} selectedIdString={item.roles} responseRunFunction={handleSelectRoles} />
                        <LawSelect label={"Parent Page"} items={sidebarItemsForSelect} state={item} setState={setItem} fieldName={'parentRegisterId'} />
                        <LawInput propstate={[item, setItem, 'index']} label={'Index'} type={'number'} />
                        <LawCheckbox propstate={[item, setItem, 'isAccordion']} label={'Accordion'} />
                        <LawCheckbox propstate={[item, setItem, 'isShowSidebar']} label={'Show in Sidebar'} />

                        <div className='jf-center mt-3 mb-5'>
                            <Button onClick={handleSaveSidebarItem} variant="contained">Opslaan</Button>
                        </div>
                    </LawModal>
                )
            }
        </>
    )
}
export default SidebarItems;