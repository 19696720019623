import {  useState } from "react";
import {  lawFetchPost } from "../../../functions/lawFetch";
import LawInputPassword from "../../../ui/LawInputPassword";
import LawInputEmail from "../../../ui/LawInputEmail";
import { Button } from "@mui/material";
import { wgfObjectClone } from "../../../functions/lawFunctions";
import QRCode from "react-qr-code";
import BoxCenter from "../../../ui/BoxCenter";
import { QrCode } from "@mui/icons-material";
import LawModal from "../../../ui/LawModal";
import Google2FaImage from '../../../../img/google-2fa.jpg'
import LawInput from "../../../ui/LawInput";
const LawEnableTwoFactor = () => {
    const [modalShow, setModalShow] = useState(false);
    const [data, setData] = useState({});
    const [qrCode, setQrCode] = useState(null);
    const [code, setCode] = useState(null);
    const handleResetDatas = () => {
        setData({});
        setQrCode(null);
        setCode(null);
    }
    const handleCreate = () => {
        handleResetDatas();
        setModalShow(true);
    }
    const handleCreateQrCode = () => {
        lawFetchPost('TwoFactor/create-two-factor-qrcode', data, null, setQrCode, null, null, null, false, null);
    }
    const handleEnable = () => {
        if (code && qrCode) {
            const params = wgfObjectClone(data);
            params['code'] = code;
            lawFetchPost('TwoFactor/enable-two-factor', params, null, null, null, () => {
                handleResetDatas();
                setModalShow(false);
            }, null, true, null);
        }
    }
    return (
        <>
            <Button onClick={handleCreate} size="small">
                <QrCode /> Genereren
            </Button>
            {
                modalShow && (
                    <LawModal state={modalShow} setState={setModalShow} style={{ backgroundImage: `url(${Google2FaImage})`, backgroundRepeat: 'no-repeat', backgroundSize: '100% auto' }}>
                        <div style={{ background: 'rgba(255,255,255,0.95)', marginTop: '130px', minHeight: '300px', padding: '60px 10px 40px 10px', borderRadius: '15px' }}>
                            <div style={{ maxWidth: '300px', margin: 'auto' }}>
                                {
                                    (!qrCode) && (
                                        <>
                                            <LawInputEmail state={data} setState={setData} fieldName={'email'} />
                                            <LawInputPassword state={data} setState={setData} fieldName={'password'} />
                                        </>
                                    )
                                }
                                <BoxCenter>
                                    <Button onClick={handleCreateQrCode}>Create QR Code</Button>
                                </BoxCenter>
                                {
                                    qrCode && (
                                        <>
                                            <BoxCenter>
                                                <QRCode value={qrCode} style={{ maxWidth: '190px' }} />
                                            </BoxCenter>
                                            <LawInput propstate={[code,setCode]} label={'Code'} />
                                            <Button size="small" onClick={handleEnable}>Opslaan</Button>
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </LawModal>
                )
            }

        </>
    )
}
export default LawEnableTwoFactor;