import { Delete } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Tooltip } from "@mui/material";
import { forwardRef, useState } from "react";
import { lawFetchPost } from "../functions/lawFetch";
import Slide from '@mui/material/Slide';
import LawTextarea from "./LawTextarea";
import { appObjects } from "../../App";
import LawIconButton from "./LawIconButton";
const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const LawDelete = ({ tableName, deleteData, deleteCallback }) => {
    const [dialogShow, setDialogShow] = useState(false);
    const [description, setDescription] = useState('');
    const handleYes = () => {
        if (description) {
            if (deleteData && deleteData.id && tableName) {
                let params = {
                    tableName,
                    id: deleteData.id,
                    description
                }
                setDialogShow(false);
                lawFetchPost((Number(params.id) ? 'app/delete-id' : 'app/delete'), params, null, null, null, () => {
                    if (deleteCallback) {
                        deleteCallback();
                    }
                }, null, true, null)
            }
        }
        else {
            appObjects.snackbar("Gelieve een uitleg te schrijven.", 'warning');
        }
    }
    const handleNo = () => {
        setDialogShow(false);
    }
    const handleShowDialog = () => {
        setDialogShow(true);
    }
    return (
        (deleteData && deleteData.status && deleteData.id && deleteData.status != -1) && (
            <>
                <LawIconButton title="Verwijderen" onClick={handleShowDialog}>
                    <Delete color="error" />
                </LawIconButton>
                {
                    dialogShow && (
                        <Dialog
                            open={dialogShow}
                            fullWidth={true}
                            maxWidth={'sm'}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={handleNo}
                            aria-describedby="alert-delete-dialog"
                        >
                            <DialogTitle><Delete />De registratie zal worden verwijderd.</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-delete-dialog">
                                    De door u gekozen registratie zal worden verwijderd. Wilt u doorgaan?
                                </DialogContentText>
                                <div className="d-block mt-3 mb-5">
                                    <LawTextarea label={'Description*'} propstate={[description, setDescription]} />
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleYes} color="primary">Ja</Button>
                                <Button onClick={handleNo} color="error">Nee</Button>
                            </DialogActions>
                        </Dialog>
                    )
                }
            </>
        )
    )
}
export default LawDelete;