import LawCitiesSelect from "../../../ui/LawCitiesSelect";
import LawInput from "../../../ui/LawInput";
import LawSelect from "../../../ui/LawSelect";
import { lawCountries } from "../../../lawConstants";
import { getMonthsArray } from "../../../functions/lawFunctions";
import LawInputEmail from "../../../ui/LawInputEmail";
import { appObjects } from "../../../../App";
import LawInputPhone from "../../../ui/LawInputPhone";
import LawUploadImage from "../../../ui/LawUploadImage";
import LawFormGrid from "../../../ui/LawFormGrid";

const CompanyFormFormBase = ({ company, setCompany }) => {
    return (
        <>
            <div className="jf-center mb-5">
                <LawUploadImage state={company} setState={setCompany} fieldName={'logo'} maxWidth={240} maxHeight={240} />
            </div>
            <LawFormGrid>
                <LawInput label="Name*" propstate={[company, setCompany, 'name']} />
                <LawInputPhone label="Phone*" state={company} setState={setCompany} fieldName={'phone'} />
                <LawInputEmail label="E-mail*" state={company} setSeller={setCompany} fieldName={'email'} />
                <LawInput label="FAX" propstate={[company, setCompany, 'fax']} />
                <LawInput label="Tax Number" propstate={[company, setCompany, 'taxNumber']} />
                <LawInput label={"Postcode"} propstate={[company, setCompany, 'postcode']} />
                <LawInput label={"BuildingNumber"} propstate={[company, setCompany, 'buildingNumber']} />
                <LawInput label={"BuildingSuffix"} propstate={[company, setCompany, 'buildingSuffix']} />
                <LawInput label={"Address"} propstate={[company, setCompany, 'address']} />
                <LawSelect items={appObjects.values.appDatas.sectors} label={"Sector"} state={company} setState={setCompany} fieldName={'sectorId'} />
                <LawSelect items={lawCountries} label={"Country"} state={company} setState={setCompany} fieldName={'country'} />
                <LawSelect items={getMonthsArray()} label={"Tax Payment Periot"} state={company} setState={setCompany} fieldName={'taxPaymentPeriot'} />
                <LawCitiesSelect state={company} setState={setCompany} fieldName={'cityId'} />
            </LawFormGrid>
        </>
    )
}
export default CompanyFormFormBase;