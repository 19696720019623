import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { wgfChangeState } from '../functions/lawFunctions';

const LawHtmlEditor = ({ state, style, setState, fieldName }) => {
    const handleChange = (value) => {
        wgfChangeState(value, fieldName, state, setState);
    }
    return (
        <ReactQuill style={style} theme="snow" value={(fieldName ? state[fieldName] : state)} onChange={handleChange} />
    )
}
export default LawHtmlEditor;